import React, { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from '../pages/ErrorPage'

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null,
  errorInfo: ErrorInfo | null
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render (): ReactNode {
    if (this.state.errorInfo) {
      return <ErrorPage/>
    }
    // Normally, just render children
    return this.props.children
  }
}
