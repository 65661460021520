import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { useMsal } from '@azure/msal-react'
import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core'
import {
  AccountCircle,
  ExitToApp,
  Menu as MenuIcon,
  PersonAdd
} from '@material-ui/icons'
import { graphScopes } from '../api/GraphService'
import { homeAccountIdStorageKey } from '../api/MsalService'

interface StyleProps {
  drawerWidth: number
}

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: (props: StyleProps) => ({
    width: `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: props.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  hide: {
    display: 'none'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flexGrow: 1
  },
  title: {
    marginLeft: theme.spacing(2)
  }
}))

interface Props {
  drawerOpen: boolean,
  drawerWidth: number,
  onMenuClick: () => void
}

const NavBar: FunctionComponent<Props> = (props: Props) => {
  const {
    drawerOpen,
    drawerWidth,
    onMenuClick
  } = props
  const classes = useStyles({ drawerWidth })
  const { instance } = useMsal()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const menuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const menuHandleClose = () => {
    setAnchorEl(null)
  }

  const homeAccountId = sessionStorage.getItem(homeAccountIdStorageKey)

  return (
    <>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onMenuClick}
            className={clsx(drawerOpen && classes.hide)}
          >
            <MenuIcon/>
          </IconButton>
          <Link
            className={classes.link}
            to='/'
          >
            <Typography
              className={classes.title}
              variant="h6"
            >
              <FormattedMessage
                id="navbar.rcFactory"
                description="NavBar application title, Ricoh Connected Factory"
                defaultMessage="RC Factory"
              />
            </Typography>
          </Link>
          <IconButton
            color="inherit"
            onClick={menuHandleClick}>
            <AccountCircle/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuHandleClose}>
        {instance.getAllAccounts().map((account, index) => {
          const isSelected = account.homeAccountId === homeAccountId
          return (
            <MenuItem
              key={index}
              onClick={isSelected
                ? undefined
                : () => instance.loginRedirect({
                  account: account,
                  scopes: graphScopes
                })}
              selected={isSelected}>
              <ListItemIcon>
                <Avatar/>
              </ListItemIcon>
              {account.username}
            </MenuItem>
          )
        })}
        <Divider/>
        <MenuItem
          onClick={() => instance.logout()}
        >
          <ListItemIcon>
            <ExitToApp/>
          </ListItemIcon>
          <FormattedMessage
            id="navbar.signOut"
            description="NavBar user sign out menu item"
            defaultMessage="Sign Out"
          />
        </MenuItem>
        <Divider/>
        <MenuItem
          onClick={() => instance.loginRedirect({
            prompt: 'select_account',
            scopes: graphScopes
          })}
        >
          <ListItemIcon>
            <PersonAdd/>
          </ListItemIcon>
          <FormattedMessage
            id="navbar.changeAccount"
            description="NavBr user sign in with different account menu item"
            defaultMessage="Sign in with a different account"
          />
        </MenuItem>
      </Menu>
    </>
  )
}

export default NavBar
