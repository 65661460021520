import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMsal } from '@azure/msal-react'
import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import {
  ExitToApp,
  Home
} from '@material-ui/icons'
import { Routes } from '../pages/Home'
import { FactoryManagerSideMenu } from '../pages/FactoryManager/FactoryManager'
import { FactoryViewerSideMenu } from '../pages/FactoryViewer/FactoryViewer'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  collapse: {
    paddingLeft: theme.spacing(2)
  }
}))

const SideMenu: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const { instance } = useMsal()

  return (
    <Box overflow="none">
      <Link
        className={classes.link}
        to={Routes.Home}
      >
        <ListItem button>
          <ListItemIcon>
            <Home/>
          </ListItemIcon>
          <ListItemText primary={
            intl.formatMessage({
              id: 'sideMenu.rcFactory',
              description: 'Application title (Ricoh Connected Factory)',
              defaultMessage: 'RC Factory'
            })}
          />
        </ListItem>
      </Link>
      <Divider/>
      <FactoryManagerSideMenu classes={classes}/>
      <FactoryViewerSideMenu classes={classes}/>
      <Divider/>
      <ListItem button onClick={() => instance.logout()}>
        <ListItemIcon>
          <ExitToApp/>
        </ListItemIcon>
        <ListItemText primary={
          intl.formatMessage({
            id: 'sideMenu.signOut',
            description: 'User sign out menu item',
            defaultMessage: 'Sign Out'
          })}
        />
      </ListItem>
    </Box>
  )
}

export default SideMenu
