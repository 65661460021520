import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'
import balloon from '../images/balloon_w.png'
import background from '../images/page_not_found_background.jpg'
import { Routes } from './Home'

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100vh'
  },
  overlay: {
    display: 'flex',
    flexGrow: 1
  },
  grid: {
    padding: theme.spacing(8, 8, 4, 8)
  },
  paper: {
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  logo: {
    marginTop: theme.spacing(2)
  },
  text: {
    color: 'white'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const PageNotFound: FunctionComponent = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleBackClick = () => {
    history.goBack()
  }

  const handleHomeClick = () => {
    history.push(Routes.Home)
  }

  return (
    <div className={classes.background}>
      <div className={classes.overlay}>
        <Box m="auto">
          <Container maxWidth="xs">
            <Paper className={classes.paper}>
              <Grid
                container
                className={classes.grid}
              >
                <Grid item xs={4}
                  className={classes.logo}
                >
                  <img src={balloon} alt="balloon"/>
                </Grid>
                <Grid
                  container
                  item
                  xs={8}
                  justify='flex-end'
                >
                  <Typography
                    variant="h1"
                    paragraph
                    className={classes.text}
                  >
                    <FormattedNumber value={404}/>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="h4"
                    paragraph
                    className={classes.text}
                  >
                    <FormattedMessage
                      id="pageNotFound.pageNotFound"
                      description="404 page, page not found message"
                      defaultMessage="Page Not Found"
                    />
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justify='center'
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleBackClick}
                  >
                    <FormattedMessage
                      id="pageNotFound.goBack"
                      description="404 page, go back button text"
                      defaultMessage="Go Back"
                    />
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justify='center'
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleHomeClick}
                  >
                    <FormattedMessage
                      id="pageNotFound.goHome"
                      description="404 page, go home button text"
                      defaultMessage="Go Home"
                    />
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </div>
    </div>
  )
}

export default PageNotFound
