import React, { FunctionComponent } from 'react'
import { find } from 'lodash'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import DialogueTitle from './DialogueTitle'
import DraggablePaper from './DraggablePaper'
import { camelCaseToTitle } from '../api/Utils'
import { Expression, Operator, PropertyLookup, Schema } from '../api/FunctionApp'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      position: 'relative',
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  }
}))

const dateOperatorToString = (operator: Operator): string => {
  switch (operator) {
    case Operator.NotEqual: return 'is not'
    case Operator.GreaterThan: return 'is after'
    case Operator.GreaterThanEqual: return 'is on or after'
    case Operator.LessThan: return 'is before'
    case Operator.LessThanEqual: return 'is on or before'
    default: return 'is'
  }
}

const numberOperatorToString = (operator: Operator): string => {
  switch (operator) {
    case Operator.Equal: return '='
    default: return operator
  }
}

const parseDateOperator = (text: string): Operator => {
  switch (text) {
    case 'is not': return Operator.NotEqual
    case 'is after': return Operator.GreaterThan
    case 'is on or after': return Operator.GreaterThanEqual
    case 'is before': return Operator.LessThan
    case 'is on or before': return Operator.LessThanEqual
    default: return Operator.Equal
  }
}

const parseNumberOperator = (text: string): Operator => {
  switch (text) {
    case '=': return Operator.Equal
    default: return text as Operator
  }
}

const parseStringOperator = (text: string): Operator => {
  switch (text) {
    case 'equals': return Operator.Equal
    case 'starts with': return Operator.StartsWith
    case 'ends width': return Operator.EndsWith
    default: return text as Operator
  }
}

const stringOperatorToString = (operator: Operator): string => {
  switch (operator) {
    case Operator.Equal: return 'equals'
    case Operator.StartsWith: return 'starts with'
    case Operator.EndsWith: return 'ends width'
    default: return operator
  }
}

interface Props {
  filter: Expression[],
  lookupProperties?: PropertyLookup[],
  onCancel: () => void,
  onExpressionChange: (
    property: string,
    operator: Operator,
    value: unknown
  ) => void,
  onReset: () => void,
  onSubmit: () => void,
  schema: Schema,
  title: string
}

const FilterDialogue: FunctionComponent<Props> = (props: Props) => {
  const {
    filter,
    lookupProperties,
    onCancel,
    onExpressionChange,
    onReset,
    onSubmit,
    schema,
    title
  } = props
  const classes = useStyles()

  const getLookupProperty = (
    expression: Expression,
    lookupProperty: PropertyLookup
  ) => {
    const lookupMatch = lookupProperty.data?.find(
      p => expression.Val === p[lookupProperty.remoteProperty]
    )
    return lookupMatch || undefined
  }

  const setLookupProperty = (
    lookupProperty: PropertyLookup,
    expression: Expression,
    operator: Operator,
    value: unknown
  ) => {
    const valueProperty = lookupProperty.nameProperty
      ? lookupProperty.nameProperty
      : lookupProperty.remoteProperty
    const lookupMatch = lookupProperty.data?.find(
      p => p[valueProperty] === value
    )
    onExpressionChange(
      expression.Prop,
      operator,
      lookupMatch ? lookupMatch[lookupProperty.remoteProperty] : undefined
    )
  }

  const handleCheckboxClick = (
    expression: Expression,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const checkBox = event.target as HTMLInputElement
    if (checkBox.readOnly) {
      checkBox.checked = checkBox.readOnly = false
    } else if (!checkBox.checked) {
      checkBox.readOnly = checkBox.indeterminate = true
    }
    onExpressionChange(
      expression.Prop,
      expression.Op,
      checkBox.indeterminate ? undefined : checkBox.checked
    )
  }

  const renderExpression = (expression: Expression) => {
    const property = find(schema.Properties, ['PropertyName', expression.Prop])
    if (!property) {
      throw Error('Cannot renderExpression if Prop is not found as PropertyName in schema!')
    }
    if (lookupProperties) {
      const lookupProperty = lookupProperties.find(
        p => p.localProperty === property.PropertyName
      )
      if (lookupProperty) {
        const valueProperty =
          lookupProperty.nameProperty || lookupProperty.remoteProperty
        return (
          <Box
            key={property.PropertyName}
            width="100%"
          >
            <Autocomplete
              options={lookupProperty.data || []}
              getOptionLabel={
                (option: Record<string, unknown>) =>
                  option[valueProperty] as string
              }
              onInputChange={(_event, newInputValue) => {
                setLookupProperty(
                  lookupProperty,
                  expression,
                  expression.Op,
                  newInputValue
                )
              }}
              renderInput={params =>
                <TextField
                  {...params}
                  label={lookupProperty.label ||
                    camelCaseToTitle(valueProperty)}
                  required={property.Required}
                  variant="outlined"
                />
              }
              value={getLookupProperty(expression, lookupProperty)}
            />
          </Box>
        )
      }
    }
    switch (property.JsPropertyType) {
      case 'boolean': return (
        <FormControlLabel
          key={property.PropertyName}
          control={
            <Checkbox
              checked={Boolean(expression.Val)}
              indeterminate={expression.Val === undefined}
              onClick={(event) =>
                handleCheckboxClick(expression, event)
              }
              readOnly={expression.Val === undefined}
            />
          }
          label={camelCaseToTitle(property.PropertyName)}
        />
      )
      case 'Date': return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <KeyboardDateTimePicker
              ampm={false}
              format="Ppp"
              label={camelCaseToTitle(expression.Prop)}
              onChange={(date: MaterialUiPickersDate) => onExpressionChange(
                expression.Prop,
                expression.Op,
                date
              )}
              showTodayButton
              value={expression.Val
                ? expression.Val as Date
                : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              getOptionLabel={(option: Operator) => dateOperatorToString(option)}
              options={[
                Operator.Equal,
                Operator.NotEqual,
                Operator.GreaterThan,
                Operator.GreaterThanEqual,
                Operator.LessThan,
                Operator.LessThanEqual
              ]}
              onInputChange={(_event, newInputValue) => {
                onExpressionChange(
                  expression.Prop,
                  parseDateOperator(newInputValue),
                  expression.Val
                )
              }}
              renderInput={params =>
                <TextField
                  label="Operator"
                  {...params}
                />
              }
              value={expression.Op}
            />
          </Grid>
        </Grid>
      )
      case 'number': return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <TextField
              key={expression.Prop}
              label={camelCaseToTitle(expression.Prop)}
              onChange={(event) => onExpressionChange(
                expression.Prop,
                expression.Op,
                event.target.value
              )}
              type="number"
              value={expression.Val ?? ''}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              getOptionLabel={(option: Operator) => numberOperatorToString(option)}
              options={[
                Operator.Equal,
                Operator.NotEqual,
                Operator.GreaterThan,
                Operator.GreaterThanEqual,
                Operator.LessThan,
                Operator.LessThanEqual
              ]}
              onInputChange={(_event, newInputValue) => {
                onExpressionChange(
                  expression.Prop,
                  parseNumberOperator(newInputValue),
                  expression.Val
                )
              }}
              renderInput={params =>
                <TextField
                  label="Operator"
                  {...params}
                />
              }
              value={expression.Op}
            />
          </Grid>
        </Grid>
      )
      default: return (
        <Grid
          container
          key={expression.Prop}
          spacing={1}
        >
          <Grid item xs={8}>
            <TextField
              label={camelCaseToTitle(expression.Prop)}
              onChange={(event) => onExpressionChange(
                expression.Prop,
                expression.Op,
                event.target.value
              )}
              value={expression.Val ?? ''}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disableClearable={true}
              getOptionLabel={(option: Operator) => stringOperatorToString(option)}
              options={[
                Operator.Contains,
                Operator.Equal,
                Operator.StartsWith,
                Operator.EndsWith
              ]}
              onInputChange={(_event, newInputValue) => {
                onExpressionChange(
                  expression.Prop,
                  parseStringOperator(newInputValue),
                  expression.Val
                )
              }}
              renderInput={params =>
                <TextField
                  label="Operator"
                  {...params}
                />
              }
              value={expression.Op}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      PaperComponent={DraggablePaper}
    >
      {title &&
        <DialogueTitle draggable onClose={onCancel}>
          {title}
        </DialogueTitle>
      }
      <DialogContent>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
        >
          <FormGroup>
            {
              filter.map((expression) => renderExpression(expression))
            }
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>
          Submit
        </Button>
        <Button onClick={onReset}>
          Clear
        </Button>
        <Button onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FilterDialogue
