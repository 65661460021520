import React, { FunctionComponent } from 'react'
import { DragSourceMonitor } from 'react-dnd'
import { concat, indexOf } from 'lodash'
import { TableBody } from '@material-ui/core'
import { CustomRender } from './EnhancedTable'
import EnhancedTableRow from './EnhancedTableRow'
import { Schema } from '../../api/FunctionApp'

interface Props {
  customRenders?: CustomRender[],
  data: Record<string, unknown>[],
  editableProperties?: string[],
  idProperty?: string,
  keys: string[],
  multiSelect?: boolean,
  onPropertyChange?: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  onRowDrag?: (
    item: { id: number } | undefined,
    monitor: DragSourceMonitor,
    data: Record<string, unknown>
  ) => void,
  onSelect?: (ids: (number | string)[]) => void,
  rowDragType?: string,
  schema?: Schema,
  selected?: (number | string)[]
}

export const updateSelected = (
  id: number | string,
  selected: (number | string)[],
  multiSelect?: boolean
): (number | string)[] => {
  let newSelected: (number | string)[] = []
  const selectedIndex = indexOf(selected, id)
  if (multiSelect) {
    if (selectedIndex === -1) {
      newSelected = concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
  } else if (selectedIndex === -1) {
    newSelected = [id]
  }
  return newSelected
}

const EnhancedTableBody: FunctionComponent<Props> = (props: Props) => {
  const {
    customRenders,
    data,
    editableProperties,
    idProperty,
    keys,
    multiSelect,
    onPropertyChange,
    onRowDrag,
    onSelect,
    rowDragType,
    schema,
    selected
  } = props

  const handleSelectClick = (id: number | string) => {
    if (!onSelect) {
      throw Error('Cannot handleSelectClick if setSelected is undefined!')
    }
    if (!selected) {
      throw Error('Cannot handleSelectClick if selected is undefined!')
    }
    onSelect(updateSelected(id, selected, multiSelect))
  }

  const isSelected = (id: number | string) => {
    if (!selected) {
      return false
    }
    return indexOf(selected, id) !== -1
  }

  return (
    <TableBody>
      {data.map((row, index) => {
        const isItemSelected = idProperty ? isSelected((row[idProperty]) as string | number) : false

        return (
          <EnhancedTableRow
            customRenders={customRenders}
            data={row}
            dragType={rowDragType}
            editableProperties={editableProperties}
            index={index}
            idProperty={idProperty}
            isSelected={isItemSelected}
            key={index}
            keys={keys}
            multiSelect={multiSelect}
            onDrag={onRowDrag}
            onPropertyChange={onPropertyChange}
            onSelectClick={onSelect ? handleSelectClick : undefined}
            schema={schema}
          />
        )
      })}
    </TableBody>
  )
}

export default EnhancedTableBody
