import React, { FunctionComponent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ContentLoading from '../../../components/Loading/ContentLoading'
import EnhancedTable from '../../../components/Table/EnhancedTable'
import PageLoading from '../../../components/Loading/PageLoading'
import TransferList from '../../../components/TransferList'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import FunctionApp, {
  NodeTemplatePropertyKeys,
  NodeTemplatePropertyMappingKeys,
  NodeTemplateKeys,
  Operator,
  Paths
} from '../../../api/FunctionApp'

const PropertyMapping: FunctionComponent = () => {
  const intl = useIntl()
  const pagination = usePagination()
  const sort = useSort(NodeTemplateKeys.Id)
  const [selected, setSelected] = useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mappingDescQuery = useQuery(
    Paths.NodeTemplatePropertyMapping + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedMappingSchema',
          description: 'Node template property mapping, fetch mapping schema error message',
          defaultMessage: 'Failed to get Node Template Property Mapping Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const mappingQuery = useQuery(
    [Paths.NodeTemplatePropertyMapping, selected],
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: NodeTemplatePropertyMappingKeys.TemplateId,
        Op: Operator.Equal,
        Val: selected[0]
      }],
      path: Paths.NodeTemplatePropertyMapping
    }), {
      enabled: selected.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedMappings',
          description: 'Node template property mapping, fetch mappings error message',
          defaultMessage: 'Failed to get Node Template Property Mappings!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const mappingCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.failedCreate',
            description: 'Node template property mapping, create mapping error message',
            defaultMessage: 'Failed to create Node Template Property Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.successfulCreate',
            description: 'Node template property mapping, create mapping success message',
            defaultMessage: 'Successfully created Node Template Property Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeTemplatePropertyMapping)
      }
    }
  )
  const mappingDeleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.NodeTemplatePropertyMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.failedDelete',
            description: 'Node template property mapping, delete mapping error message',
            defaultMessage: 'Failed to delete Node Template Property Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'propertyMapping.successfulDelete',
            description: 'Node template property mapping, delete mapping success message',
            defaultMessage: 'Successfully deleted Node Template Property Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeTemplatePropertyMapping)
      }
    }
  )
  const propertiesDescQuery = useQuery(
    Paths.NodeTemplateProperties + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.NodeTemplateProperties
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedPropertiesSchema',
          description: 'Node template property mapping, fetch properties schema error message',
          defaultMessage: 'Failed to get Node Template Property Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const propertiesQuery = useQuery(
    [Paths.NodeTemplateProperties, mappingQuery.data?.Items],
    () => FunctionApp.getList({
      modelExpressions: mappingQuery.data?.Items.map(
        (mapping: Record<string, unknown>) => {
          return {
            Prop: NodeTemplatePropertyKeys.Id,
            Op: Operator.NotEqual,
            Val: mapping[NodeTemplatePropertyMappingKeys.NodeTempPropId]
          }
        }
      ),
      path: Paths.NodeTemplateProperties
    }), {
      enabled: !!mappingQuery.data?.Items,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedProperties',
          description: 'Fetch properties error message',
          defaultMessage: 'Failed to get Nodes Template Properties!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const templatesDescQuery = useQuery(
    Paths.NodeTemplates + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.NodeTemplates
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'propertyMapping.failedTemplateSchema',
          description: 'Fetch template schema error message',
          defaultMessage: 'Failed to get Node Template Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const templatesQuery = useQuery([
    Paths.NodeTemplates,
    sort.orderBy,
    sort.order,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => FunctionApp.getList({
    orderBy1: sort.orderBy,
    order1: sort.order,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.NodeTemplates
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'propertyMapping.failedTemplates',
        description: 'Fetch templates error message',
        defaultMessage: 'Failed to get Node Templates!'
      }), {
        variant: 'error'
      }
    )
  })

  const handleLeftChange = (left: number[]) => {
    mappingDeleteMutation.mutate(left)
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected([])
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected([])
  }

  const handleRightChange = (right: number[]) => {
    mappingCreateMutation.mutate(right.map((value) => ({
      [NodeTemplatePropertyMappingKeys.NodeTempPropId]: value,
      [NodeTemplatePropertyMappingKeys.TemplateId]: selected[0]
    })))
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected([])
  }

  const handleSelect = (ids: (number | string)[]) => {
    setSelected(ids.map(Number))
  }

  const pageReady = mappingDescQuery.isSuccess && propertiesDescQuery.isSuccess &&
    templatesDescQuery.isSuccess

  const pageLoading = mappingDescQuery.isLoading || propertiesDescQuery.isLoading ||
    templatesDescQuery.isLoading

  const transferReady = mappingQuery.isSuccess && propertiesQuery.isSuccess &&
    !mappingCreateMutation.isLoading && !mappingDeleteMutation.isLoading

  const transferLoading = mappingQuery.isLoading || propertiesQuery.isLoading ||
    mappingCreateMutation.isLoading || mappingDeleteMutation.isLoading

  return (
    <>
      {
        pageReady &&
        <Box marginBottom={3} marginTop={1}>
          <Container maxWidth={false}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item md={12} lg={4}>
                { templatesQuery.data?.Items &&
                  templatesQuery.data?.Pagination &&
                  templatesDescQuery.data?.ViewDescription &&
                    <EnhancedTable
                      data={templatesQuery.data.Items}
                      idProperty={NodeTemplateKeys.Id}
                      onPageChange={handlePageChange}
                      onRequestSort={handleRequestSort}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onSelect={handleSelect}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      page={pagination.page}
                      rowsPerPage={pagination.rowsPerPage}
                      schema={templatesDescQuery.data.ViewDescription}
                      selected={selected}
                      title={intl.formatMessage({
                        id: 'propertyMapping.nodeTemplates',
                        description: 'Node Templates table title',
                        defaultMessage: 'Node Templates'
                      })}
                      totalRows={templatesQuery.data.Pagination.TotalCount}
                    />
                }
              </Grid>
              <Grid item md={12} lg={8}>
                { transferReady &&
                  mappingQuery.data?.Items &&
                  mappingDescQuery.data?.ViewDescription &&
                  propertiesQuery.data?.Items &&
                  propertiesDescQuery.data?.ViewDescription &&
                  <TransferList
                    leftList={{
                      data: mappingQuery.data.Items,
                      idProperty: NodeTemplatePropertyMappingKeys.Id,
                      ignoredProperties: [
                        NodeTemplatePropertyMappingKeys.TemplateId,
                        NodeTemplatePropertyMappingKeys.TemplateName,
                        NodeTemplatePropertyMappingKeys.TemplateDescription,
                        NodeTemplatePropertyMappingKeys.NodeTempPropId,
                        NodeTemplatePropertyMappingKeys.DataTypeId,
                        NodeTemplatePropertyMappingKeys.GroupOrdinal
                      ],
                      onChange: handleLeftChange,
                      schema: mappingDescQuery.data.ViewDescription,
                      title: intl.formatMessage({
                        id: 'propertyMapping.templateProperties',
                        description: 'Template Properties table title',
                        defaultMessage: 'Template Properties'
                      })
                    }}
                    rightList={{
                      data: propertiesQuery.data.Items,
                      idProperty: NodeTemplatePropertyKeys.Id,
                      ignoredProperties: [
                        NodeTemplatePropertyKeys.DataTypeId,
                        NodeTemplatePropertyKeys.TemplatePropertyGroupId,
                        NodeTemplatePropertyKeys.GroupOrdinal,
                        NodeTemplatePropertyKeys.CreateDateTime
                      ],
                      onChange: handleRightChange,
                      schema: propertiesDescQuery.data.ViewDescription,
                      title: intl.formatMessage({
                        id: 'propertyMapping.availableProperties',
                        description: 'Available Properties table title',
                        defaultMessage: 'Available Properties'
                      })
                    }}
                  />
                }
                {
                  transferLoading &&
                  <ContentLoading/>
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      { pageLoading &&
        <PageLoading/>
      }
    </>
  )
}

export default PropertyMapping
