import React, { FunctionComponent } from 'react'
import { Box, makeStyles, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  message: {
    color: '#999999'
  }
}))

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  message?: string
}

const Placeholder: FunctionComponent<Props> = (props: Props) => {
  const {
    message
  } = props
  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      clone
      display="flex"
      height="100%"
      justifyContent="center"
      p={12}
      textAlign="center"
    >
      <Paper>
        <Typography
          className={classes.message}
          color="primary"
          variant="h6"
        >
          {message}
        </Typography>
      </Paper>
    </Box>
  )
}

export default Placeholder
