import axios, { AxiosResponse } from 'axios'
import { getAccessToken } from './MsalService'

if (!process.env.REACT_APP_GRAPH_SCOPES) {
  throw Error('REACT_APP_GRAPH_SCOPES environment variable is not defined!')
}
export const graphScopes = process.env.REACT_APP_GRAPH_SCOPES.split(' ')

export enum GraphEndpoint {
  Me = '/me'
}

export const callMsGraph = async (endpoint: GraphEndpoint): Promise<AxiosResponse> => {
  const accessToken = await getAccessToken(graphScopes)
  const { data } = await axios.get(
    'https://graph.microsoft.com/v1.0' + endpoint, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }
  )
  return data
}
