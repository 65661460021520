import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const ContentLoading: FunctionComponent = () => {
  return (
    <Box
      clone
      height="100%"
    >
      <Skeleton
        variant="rect"
        height="100%"
      />
    </Box>
  )
}

export default ContentLoading
