import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedDate } from 'react-intl'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { Brush } from '@visx/brush'
import { Bounds } from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { curveLinear } from '@visx/curve'
import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { PatternLines } from '@visx/pattern'
import { scaleLinear, scaleOrdinal, scaleUtc } from '@visx/scale'
import { Bar, Line, LinePath } from '@visx/shape'
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { bisector, extent } from 'd3-array'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { isValid, parseISO } from 'date-fns'
import { Box, makeStyles, Paper, useTheme } from '@material-ui/core'
import EnhancedToolbar from '../EnhancedToolbar'
import theme from '../../theme'
import { debounce, flatten, throttle } from 'lodash'

const useStyles = makeStyles(theme => ({
  appGraph: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden'
  },
  axis: {
    fontFamily: theme.typography.fontFamily,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none'
  },
  legend: {
    lineHeight: '0.9em',
    fontSize: '10px',
    fontFamily: theme.typography.fontFamily,
    padding: '10px 10px',
    margin: '5px 5px',
    display: 'flex',
    justifyContent: 'center'
  }
}))

const brushHeight = 100
const brushMargin = { top: 20, left: 50, right: 20, bottom: 15 }
const defaultMargin = { top: 20, left: 50, right: 20, bottom: 30 }
const PATTERN_ID = 'brush_pattern'
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: theme.palette.primary.main
}

export interface LineSeries {
  data: Record<string, unknown>[],
  name: string
}

interface Props {
  height: number,
  margin?: { top: number, left: number, right: number, bottom: number },
  series: LineSeries[],
  title?: string,
  width: number,
  xProperty: string,
  xUnits?: string,
  yProperty: string,
  yUnits?: string
}

const LineChart: FunctionComponent<Props> = (props: Props) => {
  const {
    height,
    margin = defaultMargin,
    series,
    title,
    width,
    xProperty,
    yProperty
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen
  } = useTooltip<Record<string, unknown>[]>()
  const brushRef = useRef<BaseBrush | null>(null)
  const [brushDomain, setBrushDomain] = useState<Bounds | null>(null)
  const [filteredSeries, setFilteredSeries] = useState<LineSeries[]>(series)
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    scroll: true
  })

  const getX = useCallback((d: Record<string, unknown>) =>
    parseISO(String(d[xProperty])),
  [xProperty])
  const getY = useCallback((d: Record<string, unknown>) =>
    Number(d[yProperty]),
  [yProperty])
  const bisectX = bisector<Record<string, unknown>, Date>(
    d => getX(d)
  ).left

  useEffect(() => {
    if (!brushDomain) {
      setFilteredSeries(series)
    } else {
      const { x0, x1, y0, y1 } = brushDomain
      const newFilteredSeries = []
      for (const s of series) {
        newFilteredSeries.push({
          data: s.data.filter(d => {
            const x = getX(d).getTime()
            const y = getY(d)
            return x > x0 && x < x1 && y > y0 && y < y1
          }),
          name: s.name
        })
      }
      setFilteredSeries(newFilteredSeries)
    }
  }, [brushDomain, getX, getY, series])

  const xMax = width - margin.left - margin.right

  const xScale = useMemo(() => scaleUtc<number>({
    domain: extent(flatten(filteredSeries.map(s => s.data)), getX) as [Date, Date],
    range: [0, xMax]
  }), [filteredSeries, getX, xMax])

  const xBrushScale = useMemo(() => scaleUtc<number>({
    domain: extent(flatten(series.map(s => s.data)), getX) as [Date, Date],
    range: [0, xMax]
  }), [series, getX, xMax])

  const yMax = height - margin.top - margin.bottom - brushHeight

  const yScale = useMemo(() => scaleLinear<number>({
    domain: extent(flatten(series.map(s => s.data)), getY) as [number, number],
    range: [yMax, 0]
  }), [series, getY, yMax])

  const yBrushMax = brushHeight - brushMargin.top - brushMargin.bottom

  const yBrushScale = useMemo(() => scaleLinear<number>({
    domain: extent(flatten(series.map(s => s.data)), getY) as [number, number],
    range: [yBrushMax, 0]
  }), [series, getY, yBrushMax])

  const colourScale = useMemo(() => {
    return scaleOrdinal({
      domain: series.map(d => d.name),
      range: Array.from(schemeTableau10)
    })
  }, [series])

  const handleBrushChange = (domain: Bounds | null) => {
    setBrushDomain(domain)
  }

  const throttledBrushChange = debounce(handleBrushChange, 100)

  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ) => {
    const x = localPoint(event)?.x
    if (!x) {
      return
    }
    const x0 = xScale.invert(x - margin.left)
    const points: Record<string, unknown>[] = []
    for (const s of series) {
      const index = bisectX(s.data, x0, 1)
      const d0 = s.data[index - 1]
      const d1 = s.data[index]
      let d = d0
      if (d1 && getX(d1)) {
        d =
          x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf()
            ? d1
            : d0
      }
      points.push({ ...d, name: s.name })
    }
    showTooltip({
      tooltipData: points,
      tooltipLeft: xScale(getX(points[0]))
    })
  }

  const throttledTooltip = throttle(handleTooltip, 100)

  return (
    <Box display="relative">
      <EnhancedToolbar
        title={title}
      />
      <div className={classes.legend}>
        <LegendOrdinal scale={colourScale}>
          { labels => (
            <Box
              display="flex"
              flexDirection="row"
            >
              { labels.map((label, i) => (
                <LegendItem
                  key={`legend-category${i}`}
                  margin="0 5px"
                >
                  <svg width={15} height={15}>
                    <rect fill={label.value} width={15} height={15}/>
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </Box>
          )}
        </LegendOrdinal>
      </div>
      <svg
        height={height}
        width={width}
        ref={containerRef}
      >
        <Group left={margin.left} top={margin.top}>
          { filteredSeries.map((s, i) => (
            <LinePath<Record<string, unknown>>
              key={i}
              curve={curveLinear}
              data={s.data}
              x={d => xScale(getX(d)) ?? 0}
              y={d => yScale(getY(d)) ?? 0}
              stroke={colourScale(s.name)}
              strokeWidth={2}
              strokeOpacity={1}
              shapeRendering="geometricPrecision"
            />))
          }
          <Bar
            width={xMax}
            height={yMax}
            fill="transparent"
            onTouchStart={throttledTooltip}
            onTouchMove={throttledTooltip}
            onMouseMove={throttledTooltip}
            onMouseLeave={() => hideTooltip()}
          />
          <AxisLeft
            axisClassName={classes.axis}
            scale={yScale}
            stroke={'#000000'}
          />
          <AxisBottom
            axisClassName={classes.axis}
            top={yMax}
            scale={xScale}
            stroke={'#000000'}
          />
          { tooltipOpen && tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke={theme.palette.primary.main}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              { tooltipData.map((t) => (
                <>
                  <Line
                    from={{ x: 0, y: yScale(getY(t)) }}
                    to={{ x: xMax, y: yScale(getY(t)) }}
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                    pointerEvents="none"
                    strokeDasharray="5,2"
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={yScale(getY(t))}
                    r={4}
                    fill={colourScale(String(t.name))}
                    stroke="white"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                </>
              ))}
            </g>
          )}
        </Group>
        <Group
          left={brushMargin.left}
          top={height - brushHeight + brushMargin.top}
        >
          { series.map((s, i) => (
            <LinePath<Record<string, unknown>>
              key={i}
              curve={curveLinear}
              data={s.data}
              x={d => xBrushScale(getX(d)) ?? 0}
              y={d => yBrushScale(getY(d)) ?? 0}
              stroke={colourScale(s.name)}
              strokeWidth={2}
              strokeOpacity={1}
              shapeRendering="geometricPrecision"
            />))
          }
          <PatternLines
            id={PATTERN_ID}
            height={8}
            width={8}
            stroke={theme.palette.primary.main}
            strokeWidth={1}
            orientation={['diagonal']}
          />
          <Brush
            xScale={xBrushScale}
            yScale={yBrushScale}
            width={xMax}
            height={yBrushMax}
            margin={brushMargin}
            handleSize={8}
            innerRef={brushRef}
            resizeTriggerAreas={['left', 'right']}
            brushDirection="horizontal"
            onChange={throttledBrushChange}
            selectedBoxStyle={selectedBrushStyle}
          />
        </Group>
      </svg>
      { tooltipOpen && tooltipData &&
        <>
          { tooltipData.map((t, i) => (
            <TooltipInPortal
              key={i}
              top={yScale(getY(t))}
              left={0}
              style={{
                ...defaultStyles
              }}
            >
              {String(getY(t))}
            </TooltipInPortal>
          ))}
          { isValid(getX(tooltipData[0])) &&
            <TooltipInPortal
              top={yMax + 12}
              left={tooltipLeft}
              style={{
                ...defaultStyles
              }}
            >
              <FormattedDate
                dateStyle="short"
                timeStyle="medium"
                value={getX(tooltipData[0])}
              />
            </TooltipInPortal>
          }
        </>
      }
    </Box>
  )
}

interface ResponsiveProps extends Omit<Props, 'height' | 'width'> {
  height?: number,
  width?: number
}

const ResponsiveLineChart: FunctionComponent<ResponsiveProps> =
(props: ResponsiveProps) => {
  const {
    height,
    width
  } = props
  const classes = useStyles()

  return (
    <Paper className={classes.appGraph}>
      <ParentSize>
        {({ width: visWidth, height: visHeight }) => (
          <LineChart
            {...props}
            width={width ?? visWidth}
            height={height ?? visHeight}
          />
        )}
      </ParentSize>
    </Paper>
  )
}

export default ResponsiveLineChart
