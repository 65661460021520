import { isNil } from 'lodash'
import { useState } from 'react'
import { Order } from '../api/FunctionApp'

interface Sort {
  order: Order,
  orderBy?: string,
  requestSort: (property?: string, order?: Order) => void
}

export default function useSort (
  defaultProperty?: string, defaultOrder?: Order
): Sort {
  const [order, setOrder] = useState<Order>(defaultOrder || Order.asc)
  const [orderBy, setOrderBy] = useState<string | undefined>(defaultProperty)

  const requestSort = (property?: string, newOrder?: Order) => {
    if (isNil(newOrder)) {
      const isAsc = orderBy === property && order === Order.asc
      setOrder(isAsc ? Order.desc : Order.asc)
    } else {
      setOrder(newOrder)
    }
    setOrderBy(property)
  }

  return {
    order,
    orderBy,
    requestSort
  }
}
