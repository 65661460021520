import React, { FunctionComponent } from 'react'

interface Props {
  children?: React.ReactNode,
  index: number,
  value: number
}

const TabPanel: FunctionComponent<Props> = (props: Props) => {
  const {
    children,
    value,
    index,
    ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      { value === index && (
        children
      )}
    </div>
  )
}

export default TabPanel
