import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDrop } from 'react-dnd'
import { clone, isEqual, isNil, sortBy } from 'lodash'
import { HierarchyNode, stratify } from 'd3-hierarchy'
import { Box, Paper, Typography } from '@material-ui/core'
import {
  TreeItem,
  TreeView
} from '@material-ui/lab'
import {
  ExpandMore,
  ChevronRight,
  Add,
  Delete,
  Edit,
  ArrowDownward,
  ArrowUpward,
  Clear,
  Save
} from '@material-ui/icons'
import EnhancedToolbar from '../EnhancedToolbar'
import TreeFork from './TreeFork'

export enum ItemType {
  Branch = 'branch'
}

export enum DropRegion {
  Bottom,
  Middle,
  Top
}

enum InsertPosition {
  After,
  Before,
  End,
  Start
}

interface TreeRootProps {
  activeProperty?: string,
  idProperty: string,
  label?: string,
  nameProperty: string,
  onAdd?: (initial: Record<string, unknown>) => void,
  onDrop?: (
    dragId: number,
    dropId: number,
    region: DropRegion,
  ) => void,
  parentIdProperty: string,
  rootNode: HierarchyNode<Record<string, unknown>>
}

function TreeRoot (props: TreeRootProps) {
  const {
    activeProperty,
    idProperty,
    label,
    nameProperty,
    onAdd,
    onDrop,
    parentIdProperty,
    rootNode
  } = props
  const [{ isOverCurrent }, drop] = useDrop({
    accept: ItemType.Branch,
    drop (_item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      return { id: null }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  })

  let fontWeight = 'fontWeightRegular'
  if (isOverCurrent) {
    fontWeight = 'fontWeightBold'
  }

  return (
    <div ref={drop}>
      <TreeItem
        nodeId="-1"
        label={
          <Typography component="div">
            <Box fontWeight={fontWeight}>
              Root
            </Box>
          </Typography>
        }>
        <TreeFork
          activeProperty={activeProperty}
          data={[rootNode]}
          idProperty={idProperty}
          label={label}
          nameProperty={nameProperty}
          onAdd={onAdd}
          onDrop={onDrop}
          parentIdProperty={parentIdProperty}
        />
      </TreeItem>
    </div>
  )
}

interface Props {
  activeProperty?: string,
  data: Record<string, unknown>[],
  idProperty: string,
  label?: string,
  multiSelect?: boolean,
  nameProperty: string,
  onAdd?: (initial: Record<string, unknown>) => void,
  onDelete?: () => void,
  onDiscard?: () => void,
  onEdit?: () => void,
  onSave?: () => void,
  onSelect?: (id: number[]) => void,
  onUpdate?: (items: Record<string, unknown>[]) => void,
  ordinalProperty?: string,
  parentIdProperty: string,
  selected?: (number | string)[],
  title: string,
  unsavedChanges?: boolean
}

const Hierarchy: FunctionComponent<Props> = (props: Props) => {
  const {
    activeProperty,
    data,
    idProperty,
    label,
    multiSelect,
    nameProperty,
    onAdd,
    onDelete,
    onDiscard,
    onEdit,
    onSave,
    onSelect,
    onUpdate,
    ordinalProperty,
    parentIdProperty,
    selected,
    title,
    unsavedChanges
  } = props
  const [expanded, setExpanded] = useState<string[]>([])
  const [hierarchy, setHierarchy] = useState<HierarchyNode<Record<string, unknown>>>()

  const hasValidAncestry = useCallback((
    parentId: number | null,
    traversedIds: number[]
  ): boolean => {
    if (isNil(parentId)) {
      return true
    }
    if (traversedIds.includes(parentId)) {
      return false
    }
    const parent = data.find(d => Number(d[idProperty]) === parentId)
    if (isNil(parent)) {
      return false
    }
    const grandParentId = parent[parentIdProperty]
    traversedIds.push(parentId)
    return hasValidAncestry(
      isNil(grandParentId)
        ? null
        : Number(grandParentId),
      traversedIds
    )
  }, [data, idProperty, parentIdProperty])

  const validData = useMemo(() => {
    return data.filter(d => hasValidAncestry(
      isNil(d[parentIdProperty])
        ? null
        : Number(d[parentIdProperty]),
      [Number(d[idProperty])]
    ))
  }, [data, hasValidAncestry, idProperty, parentIdProperty])

  const sorted = useMemo(() => {
    if (!ordinalProperty) {
      return validData
    }
    return sortBy(validData, d => d[ordinalProperty])
  }, [validData, ordinalProperty])

  useEffect(() => {
    const allNodeIds = sorted.map(x => String(x[idProperty]))
    allNodeIds.unshift('-1')
    setExpanded(allNodeIds)

    const hierarchy = stratify<Record<string, unknown>>()
      .id(d => String(d[idProperty]))
      .parentId(d => isNil(d[parentIdProperty])
        ? null
        : String(d[parentIdProperty])
      )(sorted)

    setHierarchy(hierarchy)
  }, [idProperty, parentIdProperty, sorted])

  const selectedNeighbours = useMemo(() => {
    if (!selected) {
      return null
    }
    if (selected.length === 0) {
      return null
    }
    const filtered = sorted.filter(
      i => selected.some(s => isEqual(s, i[idProperty]))
    )
    const parents = filtered.map(i => i[parentIdProperty])
    const set = new Set(parents)
    if (set.size !== 1) {
      return null
    }
    const parent = set.values().next().value
    const children = sorted.filter(i => i[parentIdProperty] === parent)
    return children
  }, [idProperty, parentIdProperty, selected, sorted])

  const canMoveDown = useMemo(() => {
    if (!onUpdate || !selected || !selectedNeighbours) {
      return false
    }
    return !selected.some(s =>
      isEqual(s, selectedNeighbours[selectedNeighbours.length - 1][idProperty])
    )
  }, [idProperty, onUpdate, selected, selectedNeighbours])

  const canMoveUp = useMemo(() => {
    if (!onUpdate) {
      return
    }
    if (!selected) {
      return false
    }
    if (!selectedNeighbours) {
      return false
    }
    return !selected.some(s =>
      isEqual(s, selectedNeighbours[0][idProperty])
    )
  }, [idProperty, onUpdate, selected, selectedNeighbours])

  const moveSelectedItems = (delta: number) => {
    if (!selectedNeighbours) {
      throw Error('Cannot moveSelectedItems if selectedNeighbours is null!')
    }
    if (!selected) {
      throw Error('Cannot moveSelectedItems if selected is undefined!')
    }
    const neighbours = [...selectedNeighbours]
    for (const id of selected) {
      const item = neighbours.find(n => Number(n[idProperty]) === id)
      if (!item) {
        throw Error('Item was not found in selected neighbours!')
      }
      const index = neighbours.indexOf(item)
      neighbours.splice(index, 1)
      neighbours.splice(index + delta, 0, item)
    }
    if (!ordinalProperty) {
      throw Error('Cannot moveSelectedItems if ordinalProperty is undefined!')
    }
    const changes = []
    for (let i = 0; i < neighbours.length; i++) {
      if (neighbours[i][ordinalProperty] !== i) {
        neighbours[i][ordinalProperty] = i
        changes.push(clone(neighbours[i]))
      }
    }
    if (onUpdate) {
      onUpdate(changes)
    }
  }

  const relocate = (
    item: Record<string, unknown>,
    parentId: number,
    position?: InsertPosition,
    neighbourId?: number
  ) => {
    if ((position === InsertPosition.After || position === InsertPosition.Before) &&
      isNil(neighbourId)) {
      throw Error('Cannot relocate before or after without neighbour id!')
    } else if ((position === InsertPosition.End || position === InsertPosition.Start) &&
      !isNil(neighbourId)) {
      console.error('Neighbour id is ignored if insert position is start or end!')
    }
    const neighbours = sorted.filter(d => d[parentIdProperty] === parentId)
    let neighbour
    if (!isNil(neighbourId)) {
      neighbour = neighbours.find(n => n[idProperty] === neighbourId)
    }

    const previousIndex = neighbours.findIndex(n => n[idProperty] === item[idProperty])
    if (previousIndex >= 0) {
      neighbours.splice(previousIndex, 1)
    }
    let index
    switch (position) {
      case InsertPosition.After:
        if (isNil(neighbour)) {
          throw Error('Cannot relocate after if neighbour evaluates to undefined!')
        }
        index = neighbours.indexOf(neighbour) + 1
        break

      case InsertPosition.Before:
        if (isNil(neighbour)) {
          throw Error('Cannot relocate before if neighbour evaluates to undefined!')
        }
        index = neighbours.indexOf(neighbour)
        break

      case InsertPosition.End:
        index = neighbours.length
        break

      default:
        index = 0
    }
    neighbours.splice(index, 0, item)

    neighbours[index][parentIdProperty] = parentId
    if (!isNil(ordinalProperty)) {
      neighbours.forEach((n, index) => { n[ordinalProperty] = index })
    }

    return neighbours
  }

  const searchDescendants = (
    dragId: number,
    parentId: number
  ): boolean => {
    const parentNode = hierarchy?.descendants().find(d => Number(d.id) === dragId)
    return !isNil(parentNode?.descendants().find(d => Number(d.id) === parentId))
  }

  const handleMoveDown = () => {
    moveSelectedItems(1)
  }

  const handleMoveUp = () => {
    moveSelectedItems(-1)
  }

  const handleDrop = (
    dragId: number,
    dropId: number,
    region: DropRegion
  ) => {
    if (!onUpdate) {
      throw Error('Cannot handleNodeDrop if onUpdate is undefined!')
    }
    const dragged = sorted.find(d => d[idProperty] === dragId)
    if (!dragged) {
      throw Error('Cannot find drag source in hierarchy data!')
    }
    const target = sorted.find(d => d[idProperty] === dropId)
    if (!target) {
      throw Error('Cannot find drop target in hierarchy data!')
    }
    let parentId: number
    let neighbours: Record<string, unknown>[]
    switch (region) {
      case DropRegion.Bottom:
        parentId = Number(target[parentIdProperty])
        neighbours = relocate(dragged, parentId, InsertPosition.After, dropId)
        break

      case DropRegion.Middle:
        parentId = dropId
        neighbours = relocate(dragged, parentId, InsertPosition.End)
        break

      case DropRegion.Top:
        parentId = Number(target[parentIdProperty])
        neighbours = relocate(dragged, parentId, InsertPosition.Before, dropId)
        break
    }
    if (searchDescendants(dragId, parentId)) {
      return
    }

    onUpdate(neighbours)
  }

  const handleSelect = (event: React.ChangeEvent<unknown>, nodeIds: string[]) => {
    if (!onSelect) {
      throw Error('Cannot handleSelect if onSelect is undefined!')
    }
    const ids: string[] = Array.isArray(nodeIds) ? nodeIds : [nodeIds]
    if (!(event.target as HTMLElement).closest('.MuiTreeItem-iconContainer')) {
      if (!selected || !isEqual(selected, ids.map(Number))) {
        onSelect(ids.map(Number))
      }
    }
  }

  const handleToggle = (event: React.ChangeEvent<unknown>, nodeIds: string[]) => {
    if ((event.target as Element).closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds)
    }
  }

  const selectedStrings = useMemo(() => {
    if (!selected) {
      return undefined
    }
    return selected.map(n => n.toString())
  }, [selected])

  const canAdd = selected && selected.length === 1
  const canDelete = selected && selected.length > 0
  const canEdit = selected && selected.length === 1

  return (
    <>
      {
        hierarchy &&
        <Box clone height="100%">
          <Paper>
            { (label || onAdd || selected) &&
              <EnhancedToolbar
                buttons={
                  [{
                    icon: Add,
                    onClick: onAdd && selected
                      ? () => onAdd({ [parentIdProperty]: selected[0] })
                      : undefined,
                    tooltip: 'Add',
                    visible: canAdd
                  }, {
                    icon: Delete,
                    onClick: onDelete,
                    tooltip: 'Delete',
                    visible: canDelete
                  }, {
                    icon: Edit,
                    onClick: onEdit,
                    tooltip: 'Edit',
                    visible: canEdit
                  }, {
                    icon: Clear,
                    onClick: onDiscard,
                    tooltip: 'Discard',
                    visible: unsavedChanges
                  }, {
                    icon: Save,
                    onClick: onSave,
                    tooltip: 'Save',
                    visible: unsavedChanges
                  }, {
                    icon: ArrowDownward,
                    onClick: handleMoveDown,
                    tooltip: 'Move down',
                    visible: canMoveDown
                  }, {
                    icon: ArrowUpward,
                    onClick: handleMoveUp,
                    tooltip: 'Move up',
                    visible: canMoveUp
                  }]
                }
                multiSelect={!!multiSelect}
                selectedLength={selected ? selected.length : undefined}
                title={title}
              />
            }
            <Box p={3}>
              <TreeView
                defaultCollapseIcon={<ExpandMore/>}
                defaultExpandIcon={<ChevronRight/>}
                expanded={expanded}
                onNodeToggle={handleToggle}
                onNodeSelect={onSelect ? handleSelect : undefined}
                multiSelect={multiSelect ? true : undefined}
                selected={selectedStrings || []}
              >
                <TreeRoot
                  activeProperty={activeProperty}
                  idProperty={idProperty}
                  label={label}
                  nameProperty={nameProperty}
                  onAdd={onAdd}
                  onDrop={onUpdate ? handleDrop : undefined}
                  parentIdProperty={parentIdProperty}
                  rootNode={hierarchy}
                />
              </TreeView>
            </Box>
          </Paper>
        </Box>
      }
    </>
  )
}

export default Hierarchy
