import { useState } from 'react'

interface Pagination {
  page: number,
  rowsPerPage: number,
  rowsPerPageOptions?: number[],
  setPage: (page: number) => void,
  setRowsPerPage: (rows: number) => void
}

export default function usePagination (
  defaultRowsPerPage?: number,
  rowsPerPageOptions?: number[]
): Pagination {
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage || 10)

  const handleSetRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  return {
    page,
    rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions || [10, 25, 50, 100],
    setPage,
    setRowsPerPage: handleSetRowsPerPage
  }
}
