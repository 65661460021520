import React, { FunctionComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

export interface Action {
  handler: () => void,
  text: string
}

interface Props {
  actions: Action[],
  message: string,
  open: boolean,
  title: string
}

const AlertDialogue: FunctionComponent<Props> = (props: Props) => {
  const {
    actions,
    message,
    open,
    title
  } = props

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        { actions.map((action, index) => (
          <Button onClick={action.handler} key={index}>
            {action.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialogue
