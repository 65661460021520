import React, { FunctionComponent } from 'react'
import {
  Box,
  Divider,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1)
    }
  }
}))

const Copyright: FunctionComponent = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ricoh.com/">
        Ricoh UK Products Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Footer: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <>
      <Divider/>
      <Box className={classes.footer}>
        <Copyright />
      </Box>
    </>
  )
}

export default Footer
