import React, { FunctionComponent, useMemo } from 'react'
import { DragSourceMonitor } from 'react-dnd'
import { filter, isEqual, isNil } from 'lodash'
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import { Add, Clear, Delete, Edit, Save } from '@material-ui/icons'
import EnhancedTable from './EnhancedTable'
import { updateSelected } from './EnhancedTableBody'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableRow from './EnhancedTableRow'
import EnhancedToolbar from '../EnhancedToolbar'
import FunctionApp, { Schema } from '../../api/FunctionApp'

interface InnerData {
  data: Record<string, unknown>[],
  idProperty: string,
  ignoredKeys?: string[],
  multiSelect?: boolean,
  onAdd?: (parentId: number | string | null) => void,
  onDelete?: () => void,
  onEdit?: () => void,
  onPropertyChange?: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  onSelect?: (ids: (number | string)[]) => void,
  ordinalProperty?: string,
  parentIdProperty: string,
  schema?: Schema,
  selected?: (number | string)[],
  title: string
}

interface Collapsible {
  children: InnerData[],
  [propName: string]: unknown
}

enum ItemTypes {
  Inner = 'inner'
}

const unifyData = (
  innerData: InnerData[],
  outerData: Record<string, unknown>[],
  outerIdProperty: string,
  outerKeys: string[]
): Collapsible[] => {
  const unified: Collapsible[] = []
  for (const outerRow of outerData) {
    const children: InnerData[] = []
    for (const inner of innerData) {
      const filtered = inner.data.filter(
        d => d[inner.parentIdProperty] === outerRow[outerIdProperty]
      )
      children.push({
        ...inner,
        data: filtered
      })
    }
    const collapsible: Collapsible = {
      ...outerRow,
      children: children
    }
    unified.push(collapsible)
  }
  const orphans: InnerData[] = []
  for (const inner of innerData) {
    const filtered = inner.data.filter(
      i => !outerData.some(o => o[outerIdProperty] === i[inner.parentIdProperty])
    )
    if (filtered.length > 0) {
      orphans.push({
        ...inner,
        data: filtered
      })
    }
  }
  if (orphans.length > 0) {
    const ungrouped: Collapsible = {
      children: orphans
    }
    for (const key of outerKeys) {
      ungrouped[key] = null
    }
    unified.push(ungrouped)
  }
  return unified
}

export const updateOpened = (
  id: number | string | null,
  opened: (number | string | null)[]
): (number | string | null)[] => {
  let newOpened: (number | string | null)[]
  const selectedIndex = opened.indexOf(id)
  newOpened = []
  if (selectedIndex === -1) {
    newOpened = newOpened.concat(opened, id)
  } else if (selectedIndex === 0) {
    newOpened = newOpened.concat(opened.slice(1))
  } else if (selectedIndex === opened.length - 1) {
    newOpened = newOpened.concat(opened.slice(0, -1))
  } else if (selectedIndex > 0) {
    newOpened = newOpened.concat(
      opened.slice(0, selectedIndex),
      opened.slice(selectedIndex + 1)
    )
  }

  return newOpened
}

interface RowProps {
  data: Collapsible,
  idPropertyOuter: string,
  isOpened: boolean,
  isSelected: boolean,
  multiSelect?: boolean,
  onOpen: (id: number | string | null) => void,
  onSelect?: (id: number | string) => void,
  outerKeys: string[]
}

const Row: FunctionComponent<RowProps> = (props: RowProps) => {
  const {
    data,
    idPropertyOuter,
    isOpened,
    isSelected,
    multiSelect,
    onOpen,
    onSelect,
    outerKeys
  } = props

  let colSpan = outerKeys.length + 1
  if (idPropertyOuter) {
    colSpan += 1
  }

  const handleAdd = (onAdd: ((parentId: number | string | null) => void) | undefined) => {
    if (onAdd) {
      onAdd(data[idPropertyOuter] as number | string | null)
    }
  }

  const handleRowDrag = (
    item: { id: number} | undefined,
    monitor: DragSourceMonitor,
    data: Record<string, unknown>,
    parentIdProperty: string,
    onPropertyChange?: (changes: {
      data: Record<string, unknown>,
      property: string,
      value: unknown
    }[]) => void
  ) => {
    if (isNil(onPropertyChange)) {
      throw Error('Cannot handleRowDrag if onPropertyChange is nil!')
    }
    const dropResult = monitor.getDropResult<{ id: number }>()
    if (item && dropResult) {
      if (data[idPropertyOuter] !== dropResult.id) {
        onPropertyChange([{
          data: data,
          property: parentIdProperty,
          value: dropResult.id
        }])
      }
    }
  }

  return (
    <>
      <EnhancedTableRow
        data={data}
        dropType={ItemTypes.Inner}
        keys={outerKeys}
        idProperty={idPropertyOuter}
        isSelected={isSelected}
        multiSelect={multiSelect}
        onSelectClick={onSelect}
        onToggleOpen={onOpen}
        open={isOpened}
      />
      <TableRow selected={isSelected}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={colSpan}
        >
          <Collapse in={isOpened} timeout="auto" unmountOnExit>
            { data.children.map((table, index) => {
              const selected = filter(table.selected, s =>
                table.data.some(d => isEqual(s, d[table.idProperty]))
              )

              return (
                <Box key={index} mb={2}>
                  <EnhancedTable
                    data={table.data}
                    dense
                    idProperty={table.idProperty}
                    ignoredProperties={table.ignoredKeys}
                    multiSelect={table.multiSelect}
                    onAdd={table.onAdd
                      ? () => handleAdd(table.onAdd)
                      : undefined
                    }
                    onDelete={table.onDelete}
                    onRowDrag={!isNil(table.onPropertyChange)
                      ? (item, monitor, data) => handleRowDrag(
                        item,
                        monitor,
                        data,
                        table.parentIdProperty,
                        table.onPropertyChange
                      )
                      : undefined
                    }
                    onEdit={table.onEdit}
                    onPropertyChange={table.onPropertyChange}
                    onSelect={table.onSelect}
                    ordinalProperty={table.ordinalProperty}
                    rowDragType={ItemTypes.Inner}
                    schema={table.schema}
                    selected={selected}
                    title={table.title}
                  />
                </Box>
              )
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

interface Props {
  dataInner: InnerData[],
  dataOuter: Record<string, unknown>[],
  idProperty: string,
  ignoredKeys?: string[],
  multiSelect?: boolean,
  onAdd?: () => void,
  onDelete?: () => void,
  onDiscard?: () => void,
  onEdit?: () => void,
  onOpen: (ids: (number | string | null)[]) => void,
  onSave?: () => void,
  onSelect?: (ids: (number | string)[]) => void,
  opened: (number | string | null)[],
  schema?: Schema,
  selected?: (number | string)[],
  title: string,
  unsavedChanges?: boolean
}

const CollapsibleTable: FunctionComponent<Props> = (props: Props) => {
  const {
    dataInner,
    dataOuter,
    idProperty,
    ignoredKeys,
    multiSelect,
    onAdd,
    onDelete,
    onDiscard,
    onEdit,
    onOpen,
    onSave,
    onSelect,
    opened,
    schema,
    selected,
    title,
    unsavedChanges
  } = props

  let outerKeys: string[] = []
  if (schema) {
    outerKeys = FunctionApp.getSchemaKeys(schema)
  } else if (dataOuter[0]) {
    outerKeys = Object.keys(dataOuter[0])
  }

  let filteredOuterKeys: string[]
  if (ignoredKeys) {
    filteredOuterKeys = outerKeys.filter(key => !ignoredKeys.includes(key))
  } else {
    filteredOuterKeys = outerKeys
  }

  const collapsible = useMemo(() => {
    return unifyData(dataInner, dataOuter, idProperty, filteredOuterKeys)
  }, [dataInner, dataOuter, idProperty, filteredOuterKeys])

  const handleOpen = (id: number | string | null) => {
    onOpen(updateOpened(id, opened))
  }

  const handleSelect = (id: number | string) => {
    if (!onSelect) {
      throw Error('Cannot handleSelect if onSelectOuter is undefined!')
    }
    if (!selected) {
      throw Error('Cannot handleSelect if onSelectOuter is undefined!')
    }
    onSelect(updateSelected(id, selected, multiSelect))
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onSelect) {
      throw Error('Cannot handleSelectAllClick is onSelectOuter is undefined!')
    }
    if (!multiSelect) {
      throw Error('Cannot handleSelectAllClick if multiSelect is not set true!')
    }
    if (!idProperty) {
      throw Error('Cannot handleSelectAllClick if idPropertyOuter is undefined!')
    }
    if (event.target.checked) {
      const newSelecteds = dataOuter.map(n => Number(n[idProperty]))
      onSelect(newSelecteds)
    } else {
      onSelect([])
    }
  }

  const isOpened = (id: number | string | null) => {
    return opened.indexOf(id) !== -1
  }

  const isSelected = (id: number | string) => {
    if (!selected) {
      return false
    }
    return selected.indexOf(id) !== -1
  }

  return (
    <Box clone height="100%">
      <Paper>
        <EnhancedToolbar
          buttons={
            [{
              icon: Add,
              onClick: onAdd,
              tooltip: 'Add',
              visible: !isNil(onAdd)
            }, {
              icon: Delete,
              onClick: onDelete,
              tooltip: 'Delete',
              visible: selected && selected.length > 0
            }, {
              icon: Edit,
              onClick: onEdit,
              tooltip: 'Edit',
              visible: selected && selected.length === 1
            }, {
              icon: Clear,
              onClick: onDiscard,
              tooltip: 'Discard',
              visible: unsavedChanges
            }, {
              icon: Save,
              onClick: onSave,
              tooltip: 'Save',
              visible: unsavedChanges
            }]
          }
          multiSelect={multiSelect}
          selectedLength={selected
            ? selected.length
            : undefined
          }
          title={title}
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              canExpand={true}
              canSelect={!isNil(onSelect)}
              keys={filteredOuterKeys}
              numSelected={selected ? selected.length : 0}
              onSelectAllClick={multiSelect ? handleSelectAllClick : undefined}
              rowCount={onSelect ? dataOuter.length : undefined}
            />
            <TableBody>
              {collapsible.map((row, index) => {
                const isItemOpened = idProperty
                  ? isOpened(row[idProperty] as number | string)
                  : false
                const isItemSelected = idProperty
                  ? isSelected(row[idProperty] as number | string)
                  : false
                return (
                  <Row
                    data={row}
                    key={index}
                    idPropertyOuter={idProperty}
                    isOpened={isItemOpened}
                    isSelected={isItemSelected}
                    multiSelect={multiSelect}
                    onOpen={handleOpen}
                    onSelect={onSelect ? handleSelect : undefined}
                    outerKeys={filteredOuterKeys}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default CollapsibleTable
