import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { includes, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { Box, Container, Grid } from '@material-ui/core'
import AlertDialogue from '../../../../components/AlertDialogue'
import ContentLoading from '../../../../components/Loading/ContentLoading'
import DataForm from '../../../../components/DataForm'
import EnhancedTable from '../../../../components/Table/EnhancedTable'
import FilterDialogue from '../../../../components/FilterDialogue'
import Hierarchy from '../../../../components/Hierarchy/Hierarchy'
import PageLoading from '../../../../components/Loading/PageLoading'
import Placeholder from '../../../../components/Placeholder'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import useIotMapping from '../../../../hooks/useIotMapping'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import FunctionApp, {
  AccumulatorCfgKeys,
  AccumulatorDefCfgKeys,
  AccumulatorType,
  AccumulatorTypeCfgKeys,
  DataCollectionCategoryKeys,
  NodeKeys,
  Operator,
  Order,
  Paths
} from '../../../../api/FunctionApp'

const messages = defineMessages({
  alertMessage: {
    id: 'accumulatorInstances.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected ' +
      '{count, plural, one{Accumulator} other{Accumulators}}?'
  },
  alertTitle: {
    id: 'accumulatorInstances.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Accumulator} other{Accumulators}}'
  }
})

const omitFilterProperties = [
  AccumulatorCfgKeys.Id,
  AccumulatorCfgKeys.NodeId,
  AccumulatorCfgKeys.RcfId
]

const AccumulatorInstances: FunctionComponent = () => {
  const intl = useIntl()
  const configurator = useConfigure()
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(AccumulatorCfgKeys.Id)
  const [activeAccumulator, setActiveAccumulator] = useState<Record<string, unknown>>()
  const iotMapping = useIotMapping(!isNil(activeAccumulator)
    ? String(activeAccumulator[AccumulatorCfgKeys.RcfId])
    : undefined
  )
  const [accumulatorType, setAccumulatorType] = useState<number>()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedAccumulators, setSelectedAccumulators] = useState<number[]>([])
  const [selectedNodes, setSelectedNodes] = useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const accumulatorDefCfgQuery = useQuery(
    Paths.AccumulatorDefCfg,
    () => FunctionApp.getList({
      path: Paths.AccumulatorDefCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedAccumulatorDefinitions',
          description: 'Fetch accumulator definitions error notification text',
          defaultMessage: 'Failed to get Accumulator Definitions!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const accumulatorsDescQuery = useQuery(
    Paths.AccumulatorCfg + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.AccumulatorCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedAccumulatorSchema',
          description: 'Fetch accumulator schema error notification text',
          defaultMessage: 'Failed to get Accumulator Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const accumulatorsQuery = useQuery(
    [
      Paths.AccumulatorCfg,
      filterer.active,
      sort.orderBy,
      sort.order,
      pagination.page,
      pagination.rowsPerPage,
      selectedNodes
    ],
    () => FunctionApp.getList({
      modelExpressions: [
        ...filterer.active, {
          Prop: AccumulatorCfgKeys.NodeId,
          Op: Operator.Equal,
          Val: selectedNodes[0]
        }
      ],
      orderBy1: sort.orderBy,
      order1: sort.order,
      pageNumber: pagination.page,
      pageSize: pagination.rowsPerPage,
      path: Paths.AccumulatorCfg
    }), {
      enabled: selectedNodes.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedAccumulators',
          description: 'Fetch accumulators error notification text',
          defaultMessage: 'Failed to get Accumulators!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const accumulatorCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.AccumulatorCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.failedCreateAccumulator',
            description: 'Create accumulator error notification text',
            defaultMessage: 'Failed to create Accumulator!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.successfulCreateAccumulator',
            description: 'Create accumulator success notification text',
            defaultMessage: 'Successfully created Accumulator!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.AccumulatorCfg)
      }
    }
  )
  const accumulatorDeleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.AccumulatorCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.failedDeleteAccumulator',
            description: 'Delete accumulator error notification text',
            defaultMessage: 'Failed to delete Accumulators!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.successfulDeleteAccumulator',
            description: 'Delete accumulator success notification text',
            defaultMessage: 'Successfully deleted Accumulators!'
          }), {
            variant: 'success'
          }
        )
        setSelectedAccumulators([])
        queryClient.invalidateQueries(Paths.AccumulatorCfg)
      }
    }
  )
  const accumulatorUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.AccumulatorCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.failedUpdateAccumulator',
            description: 'Update accumulator error notification text',
            defaultMessage: 'Failed to update Accumulator!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'accumulatorInstances.successfulUpdateAccumulator',
            description: 'Update accumulator success notification text',
            defaultMessage: 'Successfully updated Accumulator!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.AccumulatorCfg)
      }
    }
  )
  const accumulatorTypesQuery = useQuery(
    Paths.AccumulatorTypeCfg,
    () => FunctionApp.getList({
      path: Paths.AccumulatorTypeCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedAccumulatorTypes',
          description: 'Fetch accumulator types error notification text',
          defaultMessage: 'Failed to get Accumulator Types!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const categoriesQuery = useQuery(
    Paths.DataCollectionCategories,
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: DataCollectionCategoryKeys.DataCategoryName,
        Op: Operator.Equal,
        Val: 'Accumulators'
      }],
      path: Paths.DataCollectionCategories
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedCategories',
          description: 'Fetch data collection categories error notification text',
          defaultMessage: 'Failed to get Data Collection Categories!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      order1: Order.asc,
      orderBy1: NodeKeys.OrdinalPosition,
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulatorInstances.failedNodes',
          description: 'Fetch nodes error notification text',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  useEffect(() => {
    if (!accumulatorsQuery.data?.Items) {
      return
    }
    if (selectedAccumulators.length !== 1) {
      return
    }
    const newActiveAccumulator =
      accumulatorsQuery.data.Items.find((d: Record<string, unknown>) =>
        d[AccumulatorCfgKeys.Id] === selectedAccumulators[0]
      )
    setActiveAccumulator(newActiveAccumulator)
  }, [accumulatorsQuery.data?.Items, selectedAccumulators])

  const accumulatorLookupProperties = useMemo(() => {
    if (!categoriesQuery.data?.Items ||
      !accumulatorDefCfgQuery.data?.Items ||
      !accumulatorTypesQuery.data?.Items) {
      return
    }
    return [{
      data: categoriesQuery.data.Items,
      localProperty: AccumulatorCfgKeys.DataCollectionCategoryId,
      nameProperty: DataCollectionCategoryKeys.Name,
      remoteProperty: DataCollectionCategoryKeys.Id
    }, {
      data: accumulatorDefCfgQuery.data.Items,
      label: 'Global Accumulator Name',
      localProperty: AccumulatorCfgKeys.DefId,
      nameProperty: AccumulatorDefCfgKeys.Name,
      remoteProperty: AccumulatorDefCfgKeys.Id
    }, {
      data: accumulatorTypesQuery.data.Items,
      localProperty: AccumulatorCfgKeys.TypeId,
      nameProperty: AccumulatorTypeCfgKeys.Name,
      remoteProperty: AccumulatorTypeCfgKeys.Id
    }]
  }, [
    categoriesQuery.data?.Items,
    accumulatorDefCfgQuery.data?.Items,
    accumulatorTypesQuery.data?.Items
  ])

  const disabledAccumulatorProperties = useMemo(() => {
    const disabled = []
    switch (accumulatorType) {
      case AccumulatorType.ValueEqualTrigger:
        disabled.push(AccumulatorCfgKeys.RolloverValue)
        break

      case AccumulatorType.Solicited:
        disabled.push(
          AccumulatorCfgKeys.Trigger,
          AccumulatorCfgKeys.TriggerValue
        )
        break

      case AccumulatorType.ValueChangeTrigger:
        disabled.push(
          AccumulatorCfgKeys.RolloverValue,
          AccumulatorCfgKeys.Trigger
        )
        break

      default:
        disabled.push(
          AccumulatorCfgKeys.RolloverValue,
          AccumulatorCfgKeys.Trigger,
          AccumulatorCfgKeys.TriggerValue
        )
        break
    }
    return disabled
  }, [accumulatorType])

  const handleAccumulatorPropertyChange = (property: string, value: unknown) => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleAccumulatorPropertyChange if accumulatorConfigurator data is nil!')
    }

    if (property === AccumulatorCfgKeys.TypeId) {
      const typeId = Number(value)
      switch (value) {
        case AccumulatorType.ValueEqualTrigger:
          configurator.update({
            [AccumulatorCfgKeys.RolloverValue]: 0
          })
          break
        case AccumulatorType.Solicited:
          configurator.update({
            [AccumulatorCfgKeys.Trigger]: '0',
            [AccumulatorCfgKeys.TriggerValue]: 0
          })
          break
        case AccumulatorType.ValueChangeTrigger:
          configurator.update({
            [AccumulatorCfgKeys.RolloverValue]: 0,
            [AccumulatorCfgKeys.Trigger]: '0'
          })
          break

        default:
          configurator.update({
            [AccumulatorCfgKeys.RolloverValue]: 0,
            [AccumulatorCfgKeys.Trigger]: '0',
            [AccumulatorCfgKeys.TriggerValue]: 0
          })
          break
      }
      setAccumulatorType(typeId)
    }

    configurator.update({
      [property]: value
    })
  }

  const handleAdd = () => {
    if (!accumulatorsDescQuery.data?.CrudDescription) {
      throw Error('Cannot handleAdd if accumulators CrudDescription is null!')
    }
    configurator.create(
      accumulatorsDescQuery.data.CrudDescription.Properties, {
        [AccumulatorCfgKeys.NodeId]: selectedNodes[0],
        [AccumulatorCfgKeys.RolloverValue]: 0,
        [AccumulatorCfgKeys.Trigger]: '0',
        [AccumulatorCfgKeys.TriggerValue]: 0
      }
    )
    setAccumulatorType(undefined)
    iotMapping.add()
  }

  const handleAddSubmit = async () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleAddSubmit if accumulatorConfigurator data is nil!')
    }
    const createResponse = await accumulatorCreateMutation.mutateAsync([
      configurator.data
    ])
    const getListResponse = await FunctionApp.getList({
      modelExpressions: [{
        Op: Operator.Equal,
        Prop: AccumulatorCfgKeys.Id,
        Val: createResponse.data[0]
      }],
      path: Paths.AccumulatorCfg
    })
    iotMapping.addSubmit(String(getListResponse.Items[0][AccumulatorCfgKeys.RcfId]))
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    accumulatorDeleteMutation.mutate(selectedAccumulators)
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEdit = () => {
    if (isNil(activeAccumulator)) {
      throw Error('Cannot handleEdit if activeAccumulator is nil!')
    }
    configurator.edit(activeAccumulator)
    setAccumulatorType(Number(activeAccumulator[AccumulatorCfgKeys.TypeId]))
    iotMapping.edit()
  }

  const handleEditSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleEditSubmit if accumulatorConfigurator data is nil!')
    }
    accumulatorUpdateMutation.mutate([configurator.data])
    iotMapping.editSubmit()
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    iotMapping.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelectedAccumulators([])
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelectedAccumulators([])
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelectedAccumulators([])
  }

  const handleSelectAccumulators = (ids: (number | string)[]) => {
    setSelectedAccumulators(ids.map(Number))
  }

  const handleSelectNodes = (ids: (number | string)[]) => {
    setSelectedNodes(ids.map(Number))
    setSelectedAccumulators([])
  }

  const handleSubmit = () => {
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        handleAddSubmit()
        break
      case ConfigurationMode.Edit:
        handleEditSubmit()
        break
      default:
        throw Error('Cannot handleSubmit if both accumulatorConfigurator mode is undefined!')
    }
  }

  const pageReady = accumulatorsDescQuery.isSuccess && nodesQuery.isSuccess &&
    iotMapping.isReady && !accumulatorCreateMutation.isLoading &&
    !accumulatorDeleteMutation.isLoading && !accumulatorUpdateMutation.isLoading &&
    !(isNil(configurator.data) && iotMapping.mode === ConfigurationMode.Create)

  const pageLoading = accumulatorsDescQuery.isLoading || nodesQuery.isLoading ||
    iotMapping.isLoading || accumulatorCreateMutation.isLoading ||
    accumulatorDeleteMutation.isLoading || accumulatorUpdateMutation.isLoading ||
    (isNil(configurator.data) && iotMapping.mode === ConfigurationMode.Create)

  return (
    <>
      { configurator.data &&
        accumulatorsDescQuery.data?.CrudDescription &&
        accumulatorLookupProperties &&
        iotMapping.formSection &&
        <DataForm
          formSections={[{
            data: configurator.data,
            disabledProperties: disabledAccumulatorProperties,
            ignoredProperties: [
              AccumulatorCfgKeys.Id,
              AccumulatorCfgKeys.NodeId
            ],
            lookupProperties: accumulatorLookupProperties,
            onPropertyChange: handleAccumulatorPropertyChange,
            schema: accumulatorsDescQuery.data.CrudDescription
          }, iotMapping.formSection]}
          onCancel={handleFormCancel}
          onSubmit={handleSubmit}
          title={configurator.mode === ConfigurationMode.Create
            ? intl.formatMessage({
              id: 'accumulatorInstances.create',
              description: 'Create accumulator dialogue title',
              defaultMessage: 'Create Accumulator'
            })
            : intl.formatMessage({
              id: 'accumulatorInstances.edit',
              description: 'Edit accumulator dialogue title',
              defaultMessage: 'Edit Accumulator'
            })
          }
        />
      }
      {
        filterer.data &&
        accumulatorsDescQuery.data?.ViewDescription &&
        <FilterDialogue
          filter={filterer.data}
          lookupProperties={accumulatorLookupProperties}
          onCancel={handleFormCancel}
          onExpressionChange={filterer.update}
          onReset={filterer.reset}
          onSubmit={handleFilterSubmit}
          schema={accumulatorsDescQuery.data.ViewDescription}
          title={intl.formatMessage({
            id: 'accumulatorInstances.title',
            description: 'Filter accumulators dialogue title',
            defaultMessage: 'Filter Accumulators'
          })}
        />
      }
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <>
              { nodesQuery.data?.Items &&
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Box alignItems="flex-start">
                      <Hierarchy
                        activeProperty={NodeKeys.Active}
                        data={nodesQuery.data.Items}
                        idProperty={NodeKeys.Id}
                        nameProperty={NodeKeys.Name}
                        onSelect={handleSelectNodes}
                        ordinalProperty={NodeKeys.OrdinalPosition}
                        parentIdProperty={NodeKeys.ParentId}
                        selected={selectedNodes}
                        title={intl.formatMessage({
                          id: 'accumulatorInstances.nodes',
                          description: 'Accumulator instances page, node hierarchy title',
                          defaultMessage: 'Nodes'
                        })}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Box height="100%" position="sticky" top={3}>
                      {
                        accumulatorsQuery.isSuccess &&
                        accumulatorsQuery.data?.Items &&
                        accumulatorsQuery.data?.Pagination &&
                        accumulatorsDescQuery.data?.ViewDescription &&
                        <EnhancedTable
                          data={accumulatorsQuery.data.Items}
                          idProperty={AccumulatorCfgKeys.Id}
                          ignoredProperties={[
                            AccumulatorCfgKeys.DataCollectionCategoryId,
                            AccumulatorCfgKeys.DefId,
                            AccumulatorCfgKeys.NodeId,
                            AccumulatorCfgKeys.RcfId,
                            AccumulatorCfgKeys.TypeDescription,
                            AccumulatorCfgKeys.TypeId
                          ]}
                          isFiltered={filterer.isActive}
                          multiSelect
                          onAdd={handleAdd}
                          onDelete={handleDelete}
                          onEdit={iotMapping.canEdit
                            ? handleEdit
                            : undefined
                          }
                          onFilter={() => filterer.initialise(
                            accumulatorsDescQuery.data.ViewDescription.Properties.filter(
                              p => !includes(omitFilterProperties, p.PropertyName)
                            )
                          )}
                          onPageChange={handlePageChange}
                          onRequestSort={handleRequestSort}
                          onRowsPerPageChange={handleRowsPerPageChange}
                          onSelect={handleSelectAccumulators}
                          order={sort.order}
                          orderBy={sort.orderBy}
                          page={pagination.page}
                          rowsPerPage={pagination.rowsPerPage}
                          schema={accumulatorsDescQuery.data.ViewDescription}
                          selected={selectedAccumulators}
                          title={intl.formatMessage({
                            id: 'accumulatorInstances.accumulators',
                            description: 'Accumulator table title',
                            defaultMessage: 'Accumulators'
                          })}
                          totalRows={accumulatorsQuery.data.Pagination.TotalCount}
                        />
                      }
                      {
                        accumulatorsQuery.isLoading &&
                        <ContentLoading/>
                      }
                      { selectedNodes.length !== 1 &&
                        <Placeholder
                          message={intl.formatMessage({
                            id: 'accumulatorInstances.placeholderMessage',
                            description: 'Node selection placeholder message',
                            defaultMessage: 'Select a Node to View and Edit its Accumulators'
                          })}
                        />
                      }
                    </Box>
                  </Grid>
                </Grid>
              }
            </>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'accumulatorInstances.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'accumulatorInstances.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(
          messages.alertMessage,
          { count: selectedAccumulators.length }
        )}
        open={alertOpen}
        title={intl.formatMessage(
          messages.alertTitle,
          { count: selectedAccumulators.length }
        )}
      />
    </>
  )
}

export default AccumulatorInstances
