import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import {
  Box,
  Divider,
  IconButton,
  lighten,
  makeStyles,
  SvgIconTypeMap,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  }
}))

interface ToolbarIconButton {
  highlight?: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
  onClick?: () => void,
  tooltip: string,
  visible?: boolean
}

interface Props {
  buttons?: ToolbarIconButton[]
  multiSelect?: boolean,
  selectedLength?: number,
  title?: string
}

const EnhancedToolbar: FunctionComponent<Props> = (props: Props) => {
  const {
    buttons,
    multiSelect,
    selectedLength,
    title
  } = props
  const classes = useStyles()

  const selectionActive = selectedLength !== undefined && selectedLength > 0
  const displaySelected = selectionActive && multiSelect

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: selectionActive
        })}
      >
        { displaySelected &&
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedLength} selected
          </Typography>
        }
        { !displaySelected &&
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        }
        {
          buttons &&
          buttons.map((button, index) => (
            <Box key={index}>
              { button.visible && button.onClick &&
                <Tooltip title={button.tooltip}>
                  <IconButton
                    aria-label={button.tooltip}
                    onClick={button.onClick}
                    color={button.highlight ? 'secondary' : 'default'}
                  >
                    <button.icon/>
                  </IconButton>
                </Tooltip>
              }
            </Box>
          ))
        }
      </Toolbar>
      <Divider/>
    </>
  )
}

export default EnhancedToolbar
