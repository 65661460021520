import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Tabs, useTheme } from '@material-ui/core'
import IncidentDefinitions from './IncidentDefinitions'
import IncidentInstances from './IncidentInstances'
import LinkTab from '../../../../components/Tabs/LinkTab'
import TabPanel from '../../../../components/Tabs/TabPanel'
import UpdateMappedIotTags from '../UpdateMappedIotTags'

export enum Routes {
  Definitions = '/incident-definitions',
  Instances = '/incident-instances'
}

enum TabsEnum {
  Instances,
  Definitions
}

const IncidentsConfig: FunctionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState<number>(TabsEnum.Instances)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box position="relative" paddingTop={1}>
      <Tabs
        aria-label="incidents tabs"
        onChange={handleTabChange}
        value={tabValue}
      >
        <LinkTab
          label={intl.formatMessage({
            id: 'incidents.instances',
            description: 'Incidents configuration page, instances tab title',
            defaultMessage: 'Instances'
          })}
          to={'.' + Routes.Instances}
        />
        <LinkTab
          label={intl.formatMessage({
            id: 'incidents.definitions',
            description: 'Incidents configuration page, definitions tab title',
            defaultMessage: 'Definitions'
          })}
          to={'.' + Routes.Definitions}
        />
      </Tabs>
      <TabPanel value={tabValue} index={TabsEnum.Instances}>
        <IncidentInstances/>
      </TabPanel>
      <TabPanel value={tabValue} index={TabsEnum.Definitions}>
        <IncidentDefinitions/>
      </TabPanel>
      <Box
        position="absolute"
        top={theme.spacing(0)}
        right={theme.spacing(4)}
      >
        <UpdateMappedIotTags/>
      </Box>
    </Box>
  )
}

export default IncidentsConfig
