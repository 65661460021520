import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore, ShowChart } from '@material-ui/icons'
import Accumulators from './Accumulators'
import Contents from '../../components/Contents'
import Events from './Events'
import Incidents from './Incidents'
import { Routes as AppRoutes } from '../../App'
import { Routes as HomeRoutes } from '../Home'
import accumulators from '../../images/accumulators_card.jpg'
import events from '../../images/events_card.jpg'
import hero from '../../images/factory_viewer_hero.jpg'
import incidents from '../../images/incidents_card.jpg'

export enum Routes {
  Accumulators = '/accumulators',
  Events = '/events',
  Incidents = '/incidents'
}

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const FactoryViewerSideMenu: FunctionComponent<SideMenuProps> =
(props: SideMenuProps) => {
  const {
    classes
  } = props
  const viewerMatch = useRouteMatch({
    path: HomeRoutes.FactoryViewer
  })
  const history = useHistory()
  const intl = useIntl()
  const [viewerOpen, setViewerOpen] = useState(false)

  useEffect(() => {
    setViewerOpen(Boolean(viewerMatch))
  }, [viewerMatch])

  const handeViewerClick = () => {
    if (!viewerMatch) {
      setViewerOpen(!viewerOpen)
    } else {
      history.push(HomeRoutes.FactoryViewer)
    }
  }

  return (
    <>
      <ListItem button onClick={handeViewerClick}>
        <ListItemIcon>
          <ShowChart/>
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({
          id: 'factoryViewer.sideMenu.factoryViewer',
          description: 'Factory viewer side menu, factory viewer item',
          defaultMessage: 'Factory Viewer'
        })}/>
        {!viewerMatch &&
          <>
            {viewerOpen ? <ExpandLess /> : <ExpandMore />}
          </>
        }
      </ListItem>
      <Collapse
        in={viewerOpen}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryViewer +
            Routes.Accumulators
          }
        >
          <ListItem button>
            <ListItemText primary={intl.formatMessage({
              id: 'factoryViewer.sideMenu.accumulators',
              description: 'Factory viewer side menu, accumulators item',
              defaultMessage: 'Accumulators'
            })}/>
          </ListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryViewer +
            Routes.Events
          }
        >
          <ListItem button>
            <ListItemText primary={intl.formatMessage({
              id: 'factoryViewer.sideMenu.events',
              description: 'Factory viewer side menu, events item',
              defaultMessage: 'Events'
            })}/>
          </ListItem>
        </Link>
        <Link
          className={classes.link}
          to={
            HomeRoutes.FactoryViewer +
            Routes.Incidents
          }
        >
          <ListItem button>
            <ListItemText primary={intl.formatMessage({
              id: 'factoryViewer.sideMenu.incidents',
              description: 'Factory viewer side menu, incidents item',
              defaultMessage: 'Incidents'
            })}/>
          </ListItem>
        </Link>
      </Collapse>
    </>
  )
}

const FactoryViewerContent: FunctionComponent = () => {
  const intl = useIntl()
  const match = useRouteMatch()

  const items = [{
    image: accumulators,
    route: match.path + Routes.Accumulators,
    text: intl.formatMessage({
      id: 'factoryViewer.accumulatorsDescription',
      description: 'Factory viewer page, accumulators description',
      defaultMessage: 'View and visualise node accumulators.'
    }),
    title: intl.formatMessage({
      id: 'factoryViewer.accumulators',
      description: 'Factory viewer page, accumulators item',
      defaultMessage: 'Accumulators'
    })
  }, {
    image: events,
    route: match.path + Routes.Events,
    text: intl.formatMessage({
      id: 'factoryViewer.eventsDescription',
      description: 'Factory viewer page, events description',
      defaultMessage: 'View and visualise node events and event variables.'
    }),
    title: intl.formatMessage({
      id: 'factoryViewer.events',
      description: 'Factory viewer page, events item',
      defaultMessage: 'Events'
    })
  }, {
    image: incidents,
    route: match.path + Routes.Incidents,
    text: intl.formatMessage({
      id: 'factoryViewer.incidentsDescription',
      description: 'Factory viewer page, incidents description',
      defaultMessage: 'View and visualise node incidents.'
    }),
    title: intl.formatMessage({
      id: 'factoryViewer.incidents',
      description: 'Factory viewer page, incidents item',
      defaultMessage: 'Incidents'
    })
  }]

  return (
    <Contents
      hero={hero}
      items={items}
      title={intl.formatMessage({
        id: 'factoryViewer.factoryViewer',
        description: 'Factory viewer page title',
        defaultMessage: 'Factory Viewer'
      })}
    />
  )
}

const FactoryViewer: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={path + Routes.Accumulators}
      >
        <Accumulators/>
      </Route>
      <Route
        exact
        path={path + Routes.Events}
      >
        <Events/>
      </Route>
      <Route
        exact
        path={path + Routes.Incidents}
      >
        <Incidents/>
      </Route>
      <Route
        exact
        path={path}
      >
        <FactoryViewerContent/>
      </Route>
      <Redirect to={AppRoutes.PageNotFound}/>
    </Switch>
  )
}

export default FactoryViewer
