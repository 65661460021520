import axios, { AxiosResponse } from 'axios'
import { clean } from './Utils'
import { getAccessToken } from './MsalService'

export enum Operator {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equal = '==',
  GreaterThan = '>',
  GreaterThanEqual = '>=',
  LessThan = '<',
  LessThanEqual = '<=',
  NotEqual = '!=',
  StartsWith = 'startsWith'
}

export interface Expression {
  Op: Operator,
  Prop: string,
  Val: unknown
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}

export interface Property {
  ForeignKey?: boolean,
  JsPropertyType: string,
  KeyAttribute?: boolean,
  MaximumLength?: number,
  MinimumLength?: number,
  PropertyName: string,
  PropertyType?: string,
  Required?: boolean
}

export interface Pagination {
  TotalCount: number
  ContinuationToken?: string
  HasNext: boolean,
  CurrentPage: number
}

export interface List {
  Pagination: Pagination,
  Items: Record<string, unknown>[]
}

export interface Schema {
  ClassName: string
  Properties: Property[]
}

export interface Description {
  ViewDescription: Schema,
  CrudDescription: Schema
}

export interface BaseProps {
  path: string
}

export interface GetListProps extends BaseProps {
  modelExpressions?: Array<Expression>,
  orderBy1?: string,
  order1?: Order,
  orderBy2?: string,
  order2?: Order,
  pageNumber?: number,
  pageSize?: number,
  continuationToken?: string
}

export type GetDescProps = BaseProps

export interface CreateProps extends BaseProps {
  items: Record<string, unknown>[],
}

export interface DeleteProps extends BaseProps {
  ids: number[] | string[]
}
export interface PropertyLookup {
  data?: Record<string, unknown>[],
  label?: string,
  loading?: boolean,
  localProperty: string,
  nameProperty?: string,
  remoteProperty: string
}

export enum Paths {
  AccumulatorCfg = '/api/AccumulatorCfg',
  AccumulatorDefCfg = '/api/AccumulatorDefCfg',
  Accumulators = '/api/Accumulators',
  AccumulatorTypeCfg = '/api/AccumulatorTypeCfg',
  DataCategoryDefCfg = '/api/DataCategoryDefCfg',
  DataCollectionCategories = '/api/DataCollectionCategories',
  DataTypes = '/api/DataTypes',
  EventAlphaVarCfg = '/api/EventAlphaVarCfg',
  EventCfg = '/api/EventCfg',
  EventDataVarType = '/api/EventDataVarType',
  EventVar = '/api/EventVar',
  EventVarCfg = '/api/EventVarCfg',
  EventVariablesCfgAll = '/api/EventVariablesCfgAll',
  EventVariables = '/api/EventVariables',
  IdentifiersConfig = '/api/IdentifiersConfig',
  IdentifiersDefCfg = '/api/IdentifiersDefCfg',
  Incidents = '/api/Incidents',
  IncidentsConfig = '/api/IncidentsConfig',
  IncidentsDefCfg = '/api/IncidentsDefCfg',
  IotDevices = '/api/IotDevices',
  IotTagDistinct = '/api/IotTagDistinct',
  IotTagMapping = '/api/IotTagMapping',
  IotDeviceProvisioning = '/api/IotDeviceProvisioning',
  NodeProperties = '/api/NodeProperties',
  Nodes = '/api/Nodes',
  NodeTemplateProperties = '/api/NodeTemplateProperties',
  NodeTemplatePropertyMapping = '/api/NodeTemplatePropertyMapping',
  NodeTemplates = '/api/NodeTemplates',
  Subscribers = '/api/Subscribers',
  Subscription = '/api/Subscription',
  TemplatePropertyGroups = '/api/TemplatePropertyGroups',
  Unit = '/api/Unit',
  UpdateMappedTagStatus = '/api/UpdateMappedTagStatus',
  UtilsGetDesc = '/Utils/GetDesc',
  VariableCfg = '/api/VariableCfg'
}

export enum AccumulatorType {
  ValueEqualTrigger = 1,
  Solicited = 2,
  SetValue = 3,
  ValueChangeTrigger = 4
}

const cleanExpressions = (expressions: Array<Expression>) => {
  return expressions?.filter(expression =>
    expression.Val !== '' &&
    expression.Val !== undefined
  )
}

const getFunctionAppAccessToken = async () => {
  if (!process.env.REACT_APP_FUNCTION_APP_SCOPES) {
    throw Error('REACT_FUNCTION_APP_SCOPES environment variable is not defined!')
  }
  const scopes = process.env.REACT_APP_FUNCTION_APP_SCOPES.split(' ')
  const accessToken = await getAccessToken(scopes)
  if (!accessToken) {
    throw Error('Failed to get Function app API access token!')
  }
  return accessToken
}

export default class FunctionApp {
  static get maxPageSize (): number {
    return 25000
  }

  static get requestTimeoutMs (): number {
    return 20000
  }

  static createObjectFromProps = (
    properties: Property[],
    initial?: Record<string, unknown>
  ): Record<string, unknown> => {
    const data: Record<string, unknown> = {}
    for (const property of properties) {
      if (property.JsPropertyType === 'boolean' && property.Required) {
        data[property.PropertyName] = false
      } else {
        data[property.PropertyName] = undefined
      }
    }
    return { ...data, ...initial }
  }

  static filterProps = (
    data: Record<string, unknown>,
    properties: Property[]
  ): Record<string, unknown> => {
    const filtered: Record<string, unknown> = {}
    for (const key in data) {
      if (properties.some(
        p => p.PropertyName === key
      )) {
        filtered[key] = data[key]
      }
    }
    return filtered
  }

  static getSchemaKeys (schema: Schema): string[] {
    return schema.Properties.map(p => p.PropertyName)
  }

  static async getList (props: GetListProps): Promise<List> {
    const {
      modelExpressions,
      orderBy1,
      order1,
      orderBy2,
      order2,
      pageNumber = 0,
      pageSize = this.maxPageSize,
      path,
      continuationToken = null
    } = props
    let cleanedExpressions = null
    if (modelExpressions) {
      cleanedExpressions = cleanExpressions(modelExpressions)
    }
    const params = {
      modelExpressions: cleanedExpressions
        ? JSON.stringify(cleanedExpressions)
        : null,
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      sort1: {
        orderByProp: orderBy1,
        orderByDir: order1?.toUpperCase()
      },
      sort2: {
        orderByProp: orderBy2,
        orderByDir: order2?.toUpperCase()
      },
      continuationToken: continuationToken
    }
    const cleanedParams = clean(params)
    const accessToken = await getFunctionAppAccessToken()
    const { data } = await axios.get(
      process.env.REACT_APP_FUNCTION_APP_URL + path, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        params: cleanedParams,
        timeout: this.requestTimeoutMs
      }
    )
    return data
  }

  static async getDesc (props: GetDescProps): Promise<Description> {
    const {
      path
    } = props
    const accessToken = await getFunctionAppAccessToken()
    const { data } = await axios.get(
      process.env.REACT_APP_FUNCTION_APP_URL + path + Paths.UtilsGetDesc, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        timeout: this.requestTimeoutMs
      }
    )
    return data
  }

  static async create (props: CreateProps): Promise<AxiosResponse> {
    const {
      items,
      path
    } = props
    const accessToken = await getFunctionAppAccessToken()
    const cleanedItems = clean(items)
    return axios.post(
      process.env.REACT_APP_FUNCTION_APP_URL + path,
      cleanedItems, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        timeout: this.requestTimeoutMs
      })
  }

  static async update (props: CreateProps): Promise<AxiosResponse> {
    const {
      items,
      path
    } = props
    const accessToken = await getFunctionAppAccessToken()
    return axios.put(
      process.env.REACT_APP_FUNCTION_APP_URL + path,
      items, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        },
        timeout: this.requestTimeoutMs
      })
  }

  static async delete (props: DeleteProps): Promise<AxiosResponse> {
    const {
      ids,
      path
    } = props
    const accessToken = await getFunctionAppAccessToken()
    return axios.delete(
      process.env.REACT_APP_FUNCTION_APP_URL + path, {
        data: { Ids: ids },
        headers: { Authorization: 'Bearer ' + accessToken },
        timeout: this.requestTimeoutMs
      })
  }
}

export enum AccumulatorKeys {
  Id = 'AccumulatorId',
  Name = 'AccumulatorName',
  NodeId = 'NodeId',
  RecordDateTime = 'RecordDateTime'
}

export enum AccumulatorCfgKeys {
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  DefId = 'AccumulatorDefId',
  Id = 'AccumulatorId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  RolloverValue = 'RollOverValue',
  Trigger = 'AccumulatorTrigger',
  TriggerValue = 'AccumulatorTriggerValue',
  Type = 'AccumulatorType',
  TypeDescription = 'AccumulatorTypeDescription',
  TypeId = 'AccumulatorTypeId'
}

export enum AccumulatorDefCfgKeys {
  Id = 'AccumulatorDefId',
  Name = 'AccumulatorName'
}

export enum AccumulatorTypeCfgKeys {
  Id = 'AccumulatorTypeId',
  Name = 'AccumulatorType'
}

export enum DataCategoryDefCfgKeys {
  DataCategoryName = 'DataCategoryName',
  Id = 'DataCategoryId'
}

export enum DataCollectionCategoryKeys {
  DataCategoryId = 'DataCategoryId',
  DataCategoryName = 'DataCategoryName',
  Id = 'DataCollectionCategoryId',
  Name = 'CollectionCategoryName'
}

export enum DataTypeKeys {
  Id = 'DataTypeId',
  Name = 'DataType'
}

export enum EventAlphaVarCfgKeys {
  EventId = 'EventId',
  Id = 'EventAlphaVarId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  VariableId = 'VariableId'
}

export enum EventCfgKeys {
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  Id = 'EventId',
  Name = 'EventName',
  NodeId = 'NodeId',
  RcfId = 'RcfId'
}

export enum EventDataVarTypeKeys {
  Id = 'EventDataVarId',
  Name = 'VariableTypeName'
}

export enum EventVarCfgKeys {
  EventId = 'EventId',
  Id = 'EventVarId',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  UnitId = 'UnitId',
  VariableId = 'VariableId',
  VariableName = 'VariableName',
}

export enum EventVarKeys {
  EventId = 'EventId',
  EventName = 'EventName',
  NodeId = 'NodeId',
  RecordTime = 'RecordTime',
  Value = 'Value',
  VariableName = 'VariableName',
  UnitId = 'UnitId',
  UnitName = 'UnitName'
}

export enum EventVariablesKeys {
  EventFilter = 'EventFilter',
  EventId = 'EventId',
  EventName = 'EventName',
  NodeId = 'NodeId',
  RecordTime = 'RecordTime',
  Value = 'Value',
  VariableName = 'VariableName',
  UnitId = 'UnitId',
  UnitName = 'UnitName'
}

export enum EventVariablesCfgKeys {
  EventDataVarId = 'EventDataVarId',
  EventId = 'EventId',
  Id = 'Id',
  NodeId = 'NodeId',
  RcfId = 'RcfId',
  VariableId = 'VariableId'
}

export enum IdentifiersConfigKeys {
  CollectionCategoryName = 'CollectionCategoryName',
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  GlobalIdentifierName = 'GlobalIncidentName',
  Id = 'IdentifierId',
  IdentifierDefId = 'IdentifierDefId',
  NodeId = 'NodeId',
  RcfId = 'RcfId'
}

export enum IdentifiersDefCfgKeys {
  Id = 'IdentifierDefId',
  Name = 'IdentifierName'
}

export enum IncidentKeys {
  Category = 'IncidentCategory',
  Description = 'IncidentDescription',
  Duration = 'Duration',
  EndDateTime = 'EndDateTime',
  GlobalIncidentName = 'GlobalIncidentLinkName',
  Name = 'IncidentName',
  NodeId = 'NodeId',
  StartDateTime = 'StartDateTime',
  Status = 'Status'
}

export enum IncidentsConfigKeys {
  CollectionCategoryName = 'CollectionCategoryName',
  CreateDateTime = 'CreateDateTime',
  DataCollectionCategoryId = 'DataCollectionCategoryId',
  GlobalIncidentName = 'GlobalIncidentName',
  HasAttachment = 'HasAttachment',
  Id = 'IncidentId',
  IncidentDefId = 'IncidentDefId',
  NodeId = 'NodeId',
  RcfId = 'RcfId'
}

export enum IncidentsDefCfgKeys {
  Id = 'IncidentDefId',
  Name = 'IncidentName'
}

export enum IotDevicesKeys {
  Id = 'IotDeviceId'
}

export enum IotDeviceProvisioningKeys {
  AssignedHub = 'AssignedHub',
  Connected = 'Connected',
  ConnectionState = 'ConnectionState',
  CreatedDateTime = 'CreatedDateTime',
  Id = 'Id',
  LastActivityTime = 'LastActivityTime',
  LastUpdatedDateTime = 'LastUpdatedDateTime',
  Name = 'Name',
  SAKConnectionString = 'SAKConnectionString',
  SASConnectionString = 'SASConnectionString',
  Status = 'Status',
  StatusUpdatedTime = 'StatusUpdatedTime'
}

export enum IotTagDistinctKeys {
  Id = 'IotTagId',
  IotDeviceId = 'IotDeviceId',
  Name = 'TagName',
  LatestTagValue = 'LatestTagValue',
  LastSeen = 'LastSeen'
}

export enum IotTagMappingKeys {
  Id = 'MappingId',
  IotDeviceId = 'IotDeviceId',
  IotTagId = 'IotTagId',
  ObjectName = 'ObjectName',
  RcfId = 'RcfId'
}

export enum NodePropertyKeys {
  Id = 'NodePropertyId',
  GroupOrdinal = 'GroupOrdinal',
  GroupName = 'GroupName',
  NodeId = 'NodeId',
  NodePropertyName = 'NodePropertyName',
  NodePropertyValue = 'NodePropertyValue',
  NodeTempPropId = 'NodeTempPropId',
  TemplatePropertyGroupId = 'TemplatePropertyGroupId'
}

export enum NodeKeys {
  Id = 'NodeId',
  Name = 'NodeName',
  Active = 'Active',
  NodeTemplateId = 'NodeTemplateId',
  OrdinalPosition = 'OrdinalPosition',
  ParentId = 'ParentNodeId'
}

export enum NodeTemplatePropertyKeys {
  CreateDateTime = 'CreateDateTime',
  DataTypeId = 'DataTypeId',
  GroupName = 'GroupName',
  GroupOrdinal = 'GroupOrdinal',
  Id = 'NodeTempPropId',
  Name = 'NodePropertyName',
  TemplatePropertyGroupId = 'TemplatePropertyGroupId'
}

export enum NodeTemplatePropertyMappingKeys {
  DataTypeId = 'DataTypeId',
  GroupOrdinal = 'GroupOrdinal',
  Id = 'NodeTempPropMapId',
  NodePropertyName = 'NodePropertyName',
  NodeTempPropId = 'NodeTempPropId',
  TemplateId = 'NodeTemplateId',
  TemplateName = 'TemplateName',
  TemplateDescription = 'TemplateDescription'
}

export enum NodeTemplateKeys {
  Id = 'NodeTemplateId',
  Name = 'TemplateName'
}

export enum TemplatePropertyGroupKeys {
  Id = 'TemplatePropertyGroupId',
  Name = 'GroupName'
}

export enum UnitKeys {
  Id = 'UnitId',
  Name = 'UnitName'
}

export enum UpdateMappedTagResponseKeys {
  TagsActivated = 'TagsActivated',
  TagsDeactivated = 'TagsDeactivated',
  ActiveTags = 'ActiveTags'
}

export enum VariableKeys {
  EventDataVarId = 'EventDataVarId',
  Name = 'VariableName',
  Id = 'VariableId',
  Type = 'VariableTypeName'
}
