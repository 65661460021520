import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Prompt, useHistory } from 'react-router'
import { FormattedMessage } from 'react-intl'
import type { Action, Location } from 'history'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'

interface Options {
  onCancel?: () => void,
  onDiscard: () => void,
  onSave: () => void,
  text: string,
  unsavedChanges: boolean,
  validPath?: string
}

interface Props {
  options: Options[]
}

const handleWindowBeforeUnload = (event: Event) => {
  event.preventDefault()
  event.returnValue = true
}

const UnsavedDialogue: FunctionComponent<Props> = (props: Props) => {
  const {
    options
  } = props
  const [action, setAction] = useState<Action | undefined>()
  const [location, setLocation] = useState<Location | undefined>()
  const [open, setOpen] = useState<boolean>(false)
  const history = useHistory()

  const text = useMemo(() => {
    const text: string[] = []
    options.forEach(o => {
      if (o.unsavedChanges) {
        text.push(o.text)
      }
    })
    return text
  }, [options])

  useEffect(() => {
    if (!options.some(o => o.unsavedChanges)) {
      setOpen(false)
      if (action && location) {
        switch (action) {
          case 'POP':
            history.goBack()
            break

          case 'PUSH':
            history.push(location.pathname)
            break

          case 'REPLACE':
            history.replace(location.pathname)
            break
        }
      }
      setAction(undefined)
      setLocation(undefined)
    }
  }, [action, history, location, options])

  useEffect(() => {
    if (options.some(o => o.unsavedChanges)) {
      window.addEventListener('beforeunload', handleWindowBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleWindowBeforeUnload)
    }
  }, [options])

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    options.forEach(o => {
      if (o.unsavedChanges) {
        if (o.onCancel) {
          o.onCancel()
        }
      }
    })
    setAction(undefined)
    setLocation(undefined)
    handleClose()
  }

  const handleDiscard = () => {
    options.forEach(o => {
      if (o.unsavedChanges) {
        o.onDiscard()
      }
    })
  }

  const handleNavigation = (location: Location, action: Action): string | boolean => {
    setAction(action)
    setLocation(location)
    let activate = false
    options.forEach(o => {
      if (o.unsavedChanges) {
        if (!o.validPath || !location.pathname.startsWith(o.validPath)) {
          activate = true
        }
      }
    })
    if (activate) {
      setOpen(true)
      return false
    }
    return true
  }

  const handleSave = () => {
    options.forEach(o => {
      if (o.unsavedChanges) {
        o.onSave()
      }
    })
  }

  return (
    <>
      <Prompt when={options.some(o => o.unsavedChanges)} message={handleNavigation}/>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage
            id="unsavedDialog.warning"
            description="Unsaved dialog warning message"
            defaultMessage="Are you sure you want to leave the page?"
          />
        </DialogTitle>
        <DialogContent>
          {
            text.map((t, index) =>
              <Typography key={index} gutterBottom>
                {t}
              </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <FormattedMessage
              id="unsavedDialog.cancel"
              description="Unsaved dialog cancel button text"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={handleDiscard}
            color="primary"
          >
            <FormattedMessage
              id="unsavedDialog.discardChanges"
              description="Unsaved dialog discard changes button text"
              defaultMessage="Discard changes"
            />
          </Button>
          <Button
            onClick={handleSave}
            color="primary" autoFocus
          >
            <FormattedMessage
              id="unsavedDialog.saveChanges"
              description="Unsaved dialog save changes button text"
              defaultMessage="Save changes"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UnsavedDialogue
