import { useState, useEffect } from 'react'

interface Size {
  height?: number,
  width?: number
}

// Hook
export default function useWindowSize (): Size {
  const [height, setHeight] = useState<number>()
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    function handleResize () {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { height, width }
}
