import React, { FunctionComponent, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { Duration, sub } from 'date-fns'
import { isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ContentLoading from '../../components/Loading/ContentLoading'
import EnhancedDataGrid from '../../components/EnhancedDataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import PageLoading from '../../components/Loading/PageLoading'
import TimeRange from '../../components/TimeRange'
import FunctionApp, {
  AccumulatorKeys,
  Expression,
  NodeKeys,
  Operator,
  Order,
  Paths
} from '../../api/FunctionApp'

const defaultDuration: Duration = { days: 1 }

const Accumulators: FunctionComponent = () => {
  const intl = useIntl()
  const [columnFilter, setColumnFilter] = useState<Expression[]>([])
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [selectedNodes, setSelectedNodes] = useState<number[]>([])
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()
  const accumulatorsQuery = useQuery(
    [Paths.Accumulators, endTime, columnFilter, selectedNodes, startTime],
    () => {
      const expressions: Expression[] = [{
        Op: Operator.Equal,
        Prop: AccumulatorKeys.NodeId,
        Val: selectedNodes[0]
      }]
      if (!isNil(startTime)) {
        expressions.push({
          Op: Operator.GreaterThanEqual,
          Prop: AccumulatorKeys.RecordDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: Operator.LessThanEqual,
          Prop: AccumulatorKeys.RecordDateTime,
          Val: endTime
        })
      }
      return FunctionApp.getList({
        modelExpressions: expressions.concat(columnFilter),
        order1: Order.desc,
        orderBy1: AccumulatorKeys.RecordDateTime,
        path: Paths.Accumulators
      })
    }, {
      enabled: selectedNodes.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulators.failedAccumulators',
          description: 'Failed to get accumulators notification text',
          defaultMessage: 'Failed to get Accumulators!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const accumulatorDescQuery = useQuery(
    Paths.Accumulators + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({ path: Paths.Accumulators }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulators.failedAccumulatorSchema',
          description: 'Failed to get accumulator schema notification text',
          defaultMessage: 'Failed to get Accumulator Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: NodeKeys.Active,
        Op: Operator.Equal,
        Val: true
      }],
      order1: Order.asc,
      orderBy1: NodeKeys.OrdinalPosition,
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'accumulators.failedNodes',
          description: 'Failed to get nodes notification text',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const handleFilterChange = (expressions: Expression[]) => {
    setColumnFilter(expressions)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelectedNodes(ids)
    setColumnFilter([])
  }

  const pageReady = nodesQuery.isSuccess
  const pageLoading = nodesQuery.isLoading

  return (
    <>
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              {
                nodesQuery.data?.Items &&
                <Grid item xs={12} md={5} lg={4} xl={3}>
                  <Hierarchy
                    data={nodesQuery.data.Items}
                    idProperty={NodeKeys.Id}
                    label="Node"
                    nameProperty={NodeKeys.Name}
                    onSelect={handleSelectNodes}
                    ordinalProperty={NodeKeys.OrdinalPosition}
                    parentIdProperty={NodeKeys.ParentId}
                    selected={selectedNodes}
                    title={intl.formatMessage({
                      id: 'accumulators.nodes',
                      description: 'Accumulators viewer page, node hierarchy title',
                      defaultMessage: 'Nodes'
                    })}
                  />
                </Grid>
              }
              <Grid item xs={12} md={7} lg={8} xl={9}>
                <Box
                  display="flex"
                  height="100%"
                  minHeight={750}
                >
                  <Box flexGrow={1}>
                    { accumulatorDescQuery.isSuccess &&
                      <EnhancedDataGrid
                        data={accumulatorsQuery.data}
                        filterMode="server"
                        ignoredProperties={[
                          AccumulatorKeys.Id,
                          AccumulatorKeys.NodeId
                        ]}
                        loading={accumulatorsQuery.isLoading}
                        onFilterChange={handleFilterChange}
                        schema={accumulatorDescQuery.data.ViewDescription}
                        widths={{
                          [AccumulatorKeys.Name]: 200
                        }}
                      />
                    }
                    { accumulatorDescQuery.isLoading &&
                      <ContentLoading/>
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      { pageLoading &&
        <PageLoading />
      }
    </>
  )
}

export default Accumulators
