import React, { FunctionComponent } from 'react'
import { Paper, PaperProps } from '@material-ui/core'
import Draggable from 'react-draggable'

const DraggablePaper: FunctionComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

export default DraggablePaper
