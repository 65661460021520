import { useState } from 'react'
import { clone, cloneDeep, findIndex, isNil } from 'lodash'
import { Expression, Operator, Property } from '../api/FunctionApp'

interface Filterer {
  active: Expression[],
  clearActive: () => void,
  clearData: () => void,
  data?: Expression[],
  initialise: (properties: Property[]) => void,
  isActive: boolean,
  reset: () => void,
  submit: () => void,
  update: (
    property: string,
    operator: Operator,
    value: unknown
  ) => void
}

export default function useFilter (): Filterer {
  const [data, setData] = useState<Expression[]>()
  const [active, setActive] = useState<Expression[]>([])

  const clearActive = () => {
    setActive([])
  }

  const clearData = () => {
    setData(undefined)
  }

  const initialise = (properties: Property[]) => {
    const newData = []
    for (const property of properties) {
      if (!isNil(active)) {
        const index = findIndex(active, ['Prop', property.PropertyName])
        if (index >= 0) {
          newData.push(clone(active[index]))
          continue
        }
      }
      newData.push({
        Prop: property.PropertyName,
        Op: Operator.Equal,
        Val: undefined
      })
    }
    setData(newData)
  }

  const reset = () => {
    if (!data) {
      throw Error('Cannot reset if data is null!')
    }
    const newData = [...data]
    for (const expression of data) {
      expression.Val = undefined
    }
    setData(newData)
  }

  const submit = () => {
    if (!data) {
      throw Error('Cannot submit if data is null!')
    }
    const validData = data.filter(e => !isNil(e.Val))
    setActive(cloneDeep(validData))
  }

  const update = (
    property: string,
    operator: Operator,
    value: unknown
  ) => {
    if (!data) {
      throw Error('Cannot update if data is null!')
    }
    const newData = [...data]
    const index = findIndex(data, ['Prop', property])
    newData[index].Val = value
    newData[index].Op = operator
    setData(newData)
  }

  const isActive = !isNil(active) && active.length > 0

  return {
    active,
    clearActive,
    clearData,
    data,
    initialise,
    isActive,
    reset,
    submit,
    update
  }
}
