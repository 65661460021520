import React, { FunctionComponent, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'
import {
  Box,
  Container,
  FormGroup,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TableCell
} from '@material-ui/core'
import { GridSortItem } from '@material-ui/data-grid'
import { Brightness1 } from '@material-ui/icons'
import { green, red } from '@material-ui/core/colors'
import { useSnackbar } from 'notistack'
import produce from 'immer'
import { FormattedDate, useIntl } from 'react-intl'
import AlertDialogue from '../../components/AlertDialogue'
import ContentLoading from '../../components/Loading/ContentLoading'
import DataForm from '../../components/DataForm'
import EnhancedDataGrid from '../../components/EnhancedDataGrid'
import EnhancedTable from '../../components/Table/EnhancedTable'
import EnhancedToolbar from '../../components/EnhancedToolbar'
import PageLoading from '../../components/Loading/PageLoading'
import PasswordInput from '../../components/PasswordInput'
import Placeholder from '../../components/Placeholder'
import useConfigure from '../../hooks/useConfigure'
import usePagination from '../../hooks/usePagination'
import FunctionApp, {
  Expression,
  GetListProps,
  IotDeviceProvisioningKeys,
  IotTagDistinctKeys,
  IotTagMappingKeys,
  List,
  Operator,
  Order,
  Paths
} from '../../api/FunctionApp'
import { Routes as HomeRoutes } from '../Home'
import { Routes as ManagerRoutes } from './FactoryManager'
import { camelCaseToTitle } from '../../api/Utils'

const useStyles = makeStyles({
  form: {
    '& .MuiInputBase-root': {
      position: 'relative',
      width: '100%'
    }
  },
  message: {
    'font-size': '16px'
  }
})

interface SideMenuProps {
  classes: Record<string, string | undefined>
}

export const IotConfigurationSideMenu: FunctionComponent<SideMenuProps> =
(props: SideMenuProps) => {
  const {
    classes
  } = props

  return (
    <>
      <Link
        className={classes.link}
        to={HomeRoutes.FactoryManager + ManagerRoutes.IotConfiguration}
      >
        <ListItem button>
          <ListItemText primary="IoT Configuration"/>
        </ListItem>
      </Link>
    </>
  )
}

const IotConfiguration: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const configurator = useConfigure()
  const devicePagination = usePagination()
  const tagPagination = usePagination(100)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [continuationToken, setContinuationToken] = useState<string | undefined>()
  const [continuationTokens, setContinuationTokens] =
    useState<Map<number, string>>(new Map())
  const [filter, setFilter] = useState<Expression[]>([])
  const [selectData, setSelectData] = useState<Record<string, unknown> | null>(null)
  const [selected, setSelected] = useState<string[]>([])
  const [sort, setSort] = useState<GridSortItem[]>([{
    field: IotTagDistinctKeys.LastSeen,
    sort: 'desc'
  }])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const devicesDescQuery = useQuery(
    Paths.IotDeviceProvisioning + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({ path: Paths.IotDeviceProvisioning }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'iotConfiguration.devicesSchemaFailed',
          description: 'Failed to get schema error notification text',
          defaultMessage: 'Failed to get IoT Devices Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const devicesQuery = useQuery([
    Paths.IotDeviceProvisioning,
    devicePagination.page,
    devicePagination.rowsPerPage
  ],
  () => FunctionApp.getList({
    path: Paths.IotDeviceProvisioning,
    pageNumber: devicePagination.page,
    pageSize: devicePagination.rowsPerPage,
    continuationToken: continuationToken
  }), {
    onSuccess: (data: List) => {
      if (data.Pagination.HasNext && data.Pagination.ContinuationToken) {
        setContinuationTokens(produce(continuationTokens, tokens => tokens?.set(data.Pagination.CurrentPage + 1, data.Pagination.ContinuationToken as string)))
      }
    },
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'iotConfiguration.devicesFailed',
        description: 'Failed to get devices error notification text',
        defaultMessage: 'Failed to get IoT Devices!'
      }), {
        variant: 'error'
      }
    )
  })

  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.IotDeviceProvisioning
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConfiguration.failedCreate',
            description: 'Failed to create device error notification text',
            defaultMessage: 'Failed to create IoT Device!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConfiguration.successfulCreate',
            description: 'Successfully created device notification text',
            defaultMessage: 'Successfully created IoT Device!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IotDeviceProvisioning)
      }
    }
  )

  const deleteMutation = useMutation(
    (ids: string[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.IotDeviceProvisioning
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConfiguration.failedDelete',
            description: 'Failed to delete device notification text',
            defaultMessage: 'Failed to delete IoT Device!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'iotConfiguration.successfulDelete',
            description: 'Successfully deleted device notification text',
            defaultMessage: 'Successfully deleted IoT Device!'
          }), {
            variant: 'success'
          }
        )
        setSelected([])
        queryClient.invalidateQueries(Paths.IotDeviceProvisioning)
      }
    }
  )

  const tagsQuery = useQuery(
    [
      Paths.IotTagDistinct,
      filter,
      sort,
      selectData?.Name,
      tagPagination.page,
      tagPagination.rowsPerPage
    ],
    () => {
      const params: GetListProps = {
        modelExpressions: filter?.concat({
          Prop: IotTagDistinctKeys.IotDeviceId,
          Op: Operator.Equal,
          Val: selectData?.Name
        }),
        path: Paths.IotTagDistinct,
        pageNumber: tagPagination.page,
        pageSize: tagPagination.rowsPerPage
      }
      if (sort.length > 0) {
        params.order1 = sort[0].sort === 'asc' ? Order.asc : Order.desc
        params.orderBy1 = sort[0].field
      }
      return FunctionApp.getList(params)
    }, {
      enabled: !isNil(selectData),
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'iotConfiguration.failedTags',
          description: 'Failed to get IoT Tags notification text',
          defaultMessage: 'Failed to get IoT Tags!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const tagsDescQuery = useQuery(
    Paths.IotTagDistinct + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.IotTagDistinct
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'iotConfiguration.failedTagsSchema',
          description: 'Failed to get IoT tags schema notification text',
          defaultMessage: 'Failed to get Iot Tags Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    if (!selectData) {
      throw Error('Cannot handleAlertDelete if selectData is null')
    }
    deleteMutation.mutate([selectData[IotDeviceProvisioningKeys.Name] as string])
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleFormCancel = () => {
    configurator.clear()
  }

  const handleFormSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleFormSubmit if configurator data is undefined!')
    }
    createMutation.mutate([configurator.data])
  }

  const handleSelect = (ids: (number | string)[]) => {
    setFilter([])
    tagPagination.setPage(0)
    setSelected(ids.map(String))
    const item = devicesQuery.data?.Items.find(d => d.Id === ids[0])
    if (item) {
      setSelectData({ ...item })
    }
  }

  const handleDevicesPageChange = (newPage: number) => {
    devicePagination.setPage(newPage)
    setSelected([])
    setContinuationToken(continuationTokens.get(newPage + 1))
  }

  const handleDevicesRowsPerPageChange = (rows: number) => {
    devicePagination.setRowsPerPage(rows)
    setSelected([])
  }

  const handleFilterChange = (expressions: Expression[]) => {
    setFilter(expressions)
  }

  const renderConnected = (row: Record<string, unknown>, key: string) => {
    return (
      <TableCell padding="checkbox">
        { row[key] &&
          <Brightness1 style={{ color: green[500] }}/>
        }
        { !row[key] &&
          <Brightness1 style={{ color: red[500] }}/>
        }
      </TableCell>
    )
  }

  const renderTime = (row: Record<string, unknown>, key: string) => {
    const showDate = row[key] !== '0001-01-01T00:00:00'
    return (
      <TableCell>
        { showDate &&
          <FormattedDate
            dateStyle="short"
            timeStyle="medium"
            value={row[key] as string}
          />
        }
        {
          !showDate &&
          <span>-</span>
        }
      </TableCell>
    )
  }

  const validateDeviceName = (value: string) => {
    const regEx = /[^A-Za-z0-9-_]/g
    return (!regEx.test(value))
  }

  const pageReady = devicesDescQuery.isSuccess && devicesQuery.isSuccess &&
    !createMutation.isLoading

  const pageLoading = devicesDescQuery.isLoading || devicesQuery.isLoading ||
    createMutation.isLoading

  return (
    <>
      { pageReady &&
        <>
          { configurator.data &&
            devicesDescQuery.data?.CrudDescription &&
            <DataForm
              customValidators={[{
                propertyName: IotDeviceProvisioningKeys.Name,
                validateFunction: validateDeviceName,
                errorMessage: intl.formatMessage({
                  id: 'iotConfiguration.validate.deviceName',
                  description: 'IoT configuration page, IoT Device name validation',
                  defaultMessage: 'Device Name can only contain upper and lowercase letters, numbers, hyphens (-) or underscores (_)'
                })
              }]}
              formSections={[{
                data: configurator.data,
                ignoredProperties: [IotDeviceProvisioningKeys.Id],
                onPropertyChange: (property, value) => configurator.update({
                  [property]: value
                }),
                schema: devicesDescQuery.data.CrudDescription
              }]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={intl.formatMessage({
                id: 'iotConfiguration.createIotDevice',
                description: 'IoT configuration page, device create dialogue title',
                defaultMessage: 'Create IoT Device'
              })}
            />
          }
          <Box
            paddingTop={1}
            paddingBottom={3}
            height="100%"
            clone
          >
            <Container maxWidth='xl'>
              <Box height="100%" clone>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12} md={6}>
                    { devicesQuery.data?.Items &&
                      devicesQuery.data?.Pagination &&
                      devicesDescQuery.data?.ViewDescription &&
                      <EnhancedTable
                        customRenders={[{
                          propertyName: IotDeviceProvisioningKeys.Connected,
                          renderFunction: renderConnected
                        }, {
                          propertyName: IotDeviceProvisioningKeys.LastActivityTime,
                          renderFunction: renderTime
                        }]}
                        data={devicesQuery.data.Items}
                        idProperty={IotDeviceProvisioningKeys.Id}
                        ignoredProperties={[
                          IotDeviceProvisioningKeys.AssignedHub,
                          IotDeviceProvisioningKeys.ConnectionState,
                          IotDeviceProvisioningKeys.CreatedDateTime,
                          IotDeviceProvisioningKeys.LastUpdatedDateTime,
                          IotDeviceProvisioningKeys.SAKConnectionString,
                          IotDeviceProvisioningKeys.SASConnectionString,
                          IotDeviceProvisioningKeys.StatusUpdatedTime
                        ]}
                        onAdd={() => configurator.create(
                          devicesDescQuery.data.CrudDescription.Properties
                        )}
                        onDelete={handleDelete}
                        onPageChange={handleDevicesPageChange}
                        onRowsPerPageChange={handleDevicesRowsPerPageChange}
                        onSelect={handleSelect}
                        page={devicePagination.page}
                        rowsPerPage={devicePagination.rowsPerPage}
                        schema={devicesDescQuery.data.ViewDescription}
                        selected={selected}
                        title={intl.formatMessage({
                          id: 'iotConfiguration.iotDevices',
                          description: 'IoT configuration page, IoT devices table title',
                          defaultMessage: 'IoT Devices'
                        })}
                        totalRows={devicesQuery.data.Pagination.TotalCount}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    { selectData &&
                      <Box
                        clone
                        display="flex"
                        flexDirection="column"
                        height="100%"
                      >
                        <Paper>
                          <EnhancedToolbar
                            title={String(selectData[IotDeviceProvisioningKeys.Name])}
                          />
                          <form
                            className={classes.form}
                            noValidate
                            autoComplete="off"
                          >
                            <FormGroup>
                              <PasswordInput
                                canCopy
                                canReveal
                                label={camelCaseToTitle(IotDeviceProvisioningKeys.SAKConnectionString)}
                                value={String(selectData[IotDeviceProvisioningKeys.SAKConnectionString])}
                              />
                              <PasswordInput
                                canCopy
                                canReveal
                                label={camelCaseToTitle(IotDeviceProvisioningKeys.SASConnectionString)}
                                value={String(selectData[IotDeviceProvisioningKeys.SASConnectionString])}
                              />
                            </FormGroup>
                          </form>
                          <Box
                            paddingLeft={2}
                            paddingRight={2}
                            paddingBottom={2}
                            flex="1"
                          >
                            {
                              tagsQuery.isSuccess &&
                              tagsDescQuery.isSuccess &&
                              <>
                                <EnhancedDataGrid
                                  data={tagsQuery.data}
                                  idProperty={IotTagMappingKeys.IotTagId}
                                  ignoredProperties={[
                                    IotTagMappingKeys.IotDeviceId,
                                    IotTagMappingKeys.IotTagId
                                  ]}
                                  filterMode="server"
                                  onFilterChange={handleFilterChange}
                                  onPageChange={tagPagination.setPage}
                                  onRowsPerPageChange={tagPagination.setRowsPerPage}
                                  onSortChange={setSort}
                                  paginationMode="server"
                                  page={tagPagination.page}
                                  schema={tagsDescQuery.data.ViewDescription}
                                  sort={sort}
                                  sortingMode="server"
                                  rowsPerPage={tagPagination.rowsPerPage}
                                  rowsPerPageOptions={tagPagination.rowsPerPageOptions}
                                  totalRows={tagsQuery.data.Pagination.TotalCount}
                                />
                              </>
                            }
                            {
                              tagsQuery.isLoading &&
                              <ContentLoading/>
                            }
                          </Box>
                        </Paper>
                      </Box>
                    }
                    { !selectData &&
                      <Placeholder
                        message={intl.formatMessage({
                          id: 'iotConfiguration.selectPlaceholder',
                          description: 'IoT configuration page, selection placeholder text',
                          defaultMessage: 'Select an IoT Device view its details and tags'
                        })}
                      />
                    }
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </>
      }
      { pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'iotConfiguration.delete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'iotConfiguration.cancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage({
          id: 'iotConfiguration.alertDialogueMessage',
          description: 'Delete alert dialogue message content',
          defaultMessage: 'Are you sure you want to delete the selected IoT device?'
        })}
        open={alertOpen}
        title={intl.formatMessage({
          id: 'iotConfiguration.alertDialogueTitle',
          description: 'Delete alert dialogue title',
          defaultMessage: 'Delete IoT Device'
        })}
      />
    </>
  )
}

export default IotConfiguration
