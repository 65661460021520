import { cloneDeep, isNil } from 'lodash'

const isUpperCase = (str: string) => {
  return str === str.toUpperCase()
}

export const camelCaseToTitle = (str: string): string => {
  for (let i = str.length - 1; i > 0; i--) {
    const followsUpperCase = isUpperCase(str[i - 1])
    const atUpperCase = isUpperCase(str[i])
    let precedesLowerCase
    if (i < str.length - 1) {
      precedesLowerCase = !isUpperCase(str[i + 1])
    }
    if ((!followsUpperCase && atUpperCase) ||
      (followsUpperCase && atUpperCase && precedesLowerCase)) {
      str = str.slice(0, i) + ' ' + str.slice(i)
    }
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const clean = (obj: Record<string, unknown> | Record<string, unknown>[])
: Record<string, unknown> | Record<string, unknown>[] => {
  const clone = cloneDeep(obj)
  if (Array.isArray(clone)) {
    clone.forEach(element => clean(element))
  } else {
    const keys = Object.keys(clone)
    for (const key of keys) {
      if (isNil(clone[key])) {
        delete clone[key]
      } else if (typeof clone[key] === 'object') {
        clone[key] = clean(clone[key] as Record<string, unknown>)
        if (Object.keys(clone[key] as Record<string, unknown>).length === 0) {
          delete clone[key]
        }
      }
    }
  }
  return clone
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const routeNameToTitle = (str: string): string => {
  const split = str.split('-')
  const capitalised = split.map(s => s[0].toUpperCase() + s.substring(1))
  return capitalised.join(' ')
}
