import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { enUS } from '@material-ui/core/locale'

let locale
switch (navigator.language) {
  default: locale = enUS
}

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#303233'
    },
    secondary: {
      main: '#32a852'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  }
}, locale)

export default theme
