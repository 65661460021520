import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

interface Props {
  endTime: Date | null,
  onEndTimeChange: (date: MaterialUiPickersDate | null) => void,
  onStartTimeChange: (date: MaterialUiPickersDate | null) => void,
  startTime: Date | null
}

const TimeRange: FunctionComponent<Props> = (props: Props) => {
  const {
    endTime,
    onEndTimeChange,
    onStartTimeChange,
    startTime
  } = props
  const intl = useIntl()

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item>
        <KeyboardDateTimePicker
          ampm={false}
          format="Ppp"
          inputVariant="outlined"
          label={intl.formatMessage({
            id: 'timeRange.startTime',
            description: 'Time range start time input label',
            defaultMessage: 'Start Time'
          })}
          maxDate={endTime}
          maxDateMessage={intl.formatMessage({
            id: 'timeRange.maxDateMessage',
            description: 'Max date message displayed on time range start time picker',
            defaultMessage: 'Start Time cannot be greater than End Time!'
          })}
          onChange={onStartTimeChange}
          showTodayButton
          value={startTime}
        />
      </Grid>
      <Grid item>
        <KeyboardDateTimePicker
          ampm={false}
          format="Ppp"
          inputVariant="outlined"
          label={intl.formatMessage({
            id: 'timeRange.endTime',
            description: 'Time range end time input label',
            defaultMessage: 'End Time'
          })}
          minDate={startTime}
          minDateMessage={intl.formatMessage({
            id: 'timeRange.minDateMessage',
            description: 'Min date message displayed on time range end time picker',
            defaultMessage: 'End Time cannot be less than Start Time!'
          })}
          onChange={onEndTimeChange}
          showTodayButton
          value={endTime}
        />
      </Grid>
    </Grid>
  )
}

export default TimeRange
