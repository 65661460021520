import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { includes } from 'lodash'
import {
  Box,
  Container,
  Grid,
  useTheme
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../components/AlertDialogue'
import DataForm from '../../../components/DataForm'
import EnhancedTable from '../../../components/Table/EnhancedTable'
import FilterDialogue from '../../../components/FilterDialogue'
import PageLoading from '../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import useFilter from '../../../hooks/useFilter'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import FunctionApp, {
  DataCategoryDefCfgKeys,
  DataCollectionCategoryKeys,
  Paths
} from '../../../api/FunctionApp'
import UpdateMappedIotTags from './UpdateMappedIotTags'

const messages = defineMessages({
  alertMessage: {
    id: 'collectionCategories.alertMessage',
    description: 'Delete alert dialogue, message content',
    defaultMessage: 'Are you sure you want to delete the selected Collection ' +
      '{count, plural, one{Category} other{Categories}}?'
  },
  alertTitle: {
    id: 'collectionCategories.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete Collection {count, plural, one{Category} other{Categories}}'
  }
})

const omitFilterProperties = [
  DataCollectionCategoryKeys.DataCategoryName,
  DataCollectionCategoryKeys.Id
]

const CollectionCategories: FunctionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()
  const configurator = useConfigure()
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(DataCollectionCategoryKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const categoriesDescQuery = useQuery(
    Paths.DataCollectionCategories + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.DataCollectionCategories
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'collectionCategories.failedSchema',
          description: 'Fetch collection category schema error notification text',
          defaultMessage: 'Failed to get Data Collection Category Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const categoriesQuery = useQuery([
    Paths.DataCollectionCategories,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => FunctionApp.getList({
    modelExpressions: filterer.active,
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.DataCollectionCategories
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'collectionCategories.failedCategories',
        description: 'Fetch collection categories error notification text',
        defaultMessage: 'Failed to get Data Collection Categories!'
      }), {
        variant: 'error'
      }
    )
  })
  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.DataCollectionCategories
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.failedCreate',
            description: 'Create collection category error notification text',
            defaultMessage: 'Failed to create Data Category!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.successfulCreate',
            description: 'Create collection category success notification text',
            defaultMessage: 'Successfully created Data Category!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.DataCollectionCategories)
      }
    }
  )
  const deleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.DataCollectionCategories
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.failedDelete',
            description: 'Delete collection category error notification text',
            defaultMessage: 'Failed to delete Data Categories!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.successfulDelete',
            description: 'Delete collection categories success notification text',
            defaultMessage: 'Successfully deleted Data Categories!'
          }), {
            variant: 'success'
          }
        )
        setSelected([])
        queryClient.invalidateQueries(Paths.DataCollectionCategories)
      }
    }
  )
  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.DataCollectionCategories
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.failedUpdate',
            description: 'Update collection category error notification text',
            defaultMessage: 'Failed to update Data Category!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.successfulUpdate',
            description: 'Update collection category success notification text',
            defaultMessage: 'Successfully updated Data Category!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.DataCollectionCategories)
      }
    }
  )
  const categoryDefCfgQuery = useQuery(
    Paths.DataCategoryDefCfg,
    () => FunctionApp.getList({
      path: Paths.DataCategoryDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'collectionCategories.failedCategoryDefCfg',
            description: 'Fetch data category definitions error notification text',
            defaultMessage: 'Failed to get Data Category Definitions!'
          }), {
            variant: 'error'
          }
        )
      }
    }
  )

  const activeCategory = useMemo(() => {
    if (!categoriesQuery.data?.Items) {
      return
    }
    if (selected.length !== 1) {
      return
    }
    return categoriesQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[DataCollectionCategoryKeys.Id] === selected[0])
  }, [selected, categoriesQuery.data?.Items])

  const lookupProperties = useMemo(() => {
    if (!categoryDefCfgQuery.data?.Items) {
      return
    }
    return [{
      data: categoryDefCfgQuery.data?.Items,
      localProperty: DataCollectionCategoryKeys.DataCategoryId,
      nameProperty: DataCategoryDefCfgKeys.DataCategoryName,
      remoteProperty: DataCategoryDefCfgKeys.Id
    }]
  }, [categoryDefCfgQuery.data?.Items])

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    deleteMutation.mutate(selected)
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handleFormSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleFormSubmit if configurator data is undefined!')
    }
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        createMutation.mutate([configurator.data])
        break

      case ConfigurationMode.Edit:
        updateMutation.mutate([configurator.data])
        break

      default:
        throw Error('Cannot handleFormSubmit if configurator mode is undefined!')
    }
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected([])
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected([])
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected([])
  }

  const handleSelect = (ids: (number | string)[]) => {
    setSelected(ids.map(Number))
  }

  const pageReady = categoriesDescQuery.isSuccess && categoriesQuery.isSuccess &&
    categoryDefCfgQuery.isSuccess && !createMutation.isLoading &&
    !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = categoriesDescQuery.isLoading || categoriesQuery.isLoading ||
    categoryDefCfgQuery.isLoading || createMutation.isLoading ||
    deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      { pageReady &&
        <>
          { configurator.data &&
            lookupProperties &&
            categoriesDescQuery.data?.CrudDescription &&
            <DataForm
              formSections={[{
                data: configurator.data,
                ignoredProperties: [DataCollectionCategoryKeys.Id],
                lookupProperties: lookupProperties,
                onPropertyChange: (property, value) => configurator.update({
                  [property]: value
                }),
                schema: categoriesDescQuery.data.CrudDescription
              }]}
              onCancel={handleFormCancel}
              onSubmit={handleFormSubmit}
              title={configurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'dataCollectionCategories.create',
                  description: 'Create collection category form title',
                  defaultMessage: 'Create Data Collection Category'
                })
                : intl.formatMessage({
                  id: 'dataCollectionCategories.edit',
                  description: 'Edit collection category form title',
                  defaultMessage: 'Edit Data Collection Category'
                })
              }
            />
          }
          { filterer.data &&
            categoriesDescQuery.data?.ViewDescription &&
            <FilterDialogue
              filter={filterer.data}
              lookupProperties={lookupProperties}
              onCancel={handleFormCancel}
              onExpressionChange={filterer.update}
              onReset={filterer.reset}
              onSubmit={handleFilterSubmit}
              schema={categoriesDescQuery.data.ViewDescription}
              title={intl.formatMessage({
                id: 'dataCollectionCategories.filterCollectionCategories',
                description: 'Collection categories filter dialogue title',
                defaultMessage: 'Filter Collection Categories'
              })}
            />
          }
          <Box
            position="relative"
            paddingTop={10}
            paddingBottom={3}
          >
            <Container maxWidth='md'>
              <Grid container>
                <Grid item xs={12}>
                  { categoriesQuery.data?.Items &&
                    categoriesQuery.data?.Pagination &&
                    categoriesDescQuery.data &&
                    <EnhancedTable
                      data={categoriesQuery.data.Items}
                      idProperty={DataCollectionCategoryKeys.Id}
                      ignoredProperties={[DataCollectionCategoryKeys.DataCategoryId]}
                      isFiltered={filterer.isActive}
                      multiSelect
                      onAdd={() => configurator.create(
                        categoriesDescQuery.data.CrudDescription.Properties
                      )}
                      onDelete={handleDelete}
                      onEdit={activeCategory
                        ? () => configurator.edit(activeCategory)
                        : undefined
                      }
                      onFilter={() => filterer.initialise(
                        categoriesDescQuery.data.ViewDescription.Properties.filter(
                          p => !includes(omitFilterProperties, p.PropertyName)
                        )
                      )}
                      onPageChange={handlePageChange}
                      onRequestSort={handleRequestSort}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      onSelect={handleSelect}
                      order={sort.order}
                      orderBy={sort.orderBy}
                      page={pagination.page}
                      rowsPerPage={pagination.rowsPerPage}
                      schema={categoriesDescQuery.data.ViewDescription}
                      selected={selected}
                      title={intl.formatMessage({
                        id: 'collectionCategories.dataCollectionCategories',
                        description: 'Data collection categories table title',
                        defaultMessage: 'Data Collection Categories'
                      })}
                      totalRows={categoriesQuery.data.Pagination.TotalCount}
                    />
                  }
                </Grid>
              </Grid>
            </Container>
            <Box
              position="absolute"
              top={theme.spacing(0)}
              right={theme.spacing(4)}
            >
              <UpdateMappedIotTags/>
            </Box>
          </Box>
        </>
      }
      { pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'collectionCategories.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'collectionCategories.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, { count: selected.length })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, { count: selected.length })}
      />
    </>
  )
}

export default CollectionCategories
