import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { includes } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import DataForm from '../../../../components/DataForm'
import EnhancedTable from '../../../../components/Table/EnhancedTable'
import FilterDialogue from '../../../../components/FilterDialogue'
import PageLoading from '../../../../components/Loading/PageLoading'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import FunctionApp, {
  IdentifiersDefCfgKeys,
  Paths
} from '../../../../api/FunctionApp'

const messages = defineMessages({
  alertMessage: {
    id: 'identifierDefinitions.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected Identifier ' +
      '{count, plural, one{Definition} other{Definitions}}?'
  },
  alertTitle: {
    id: 'identifierDefinitions.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete Identifier {count, plural, one{Definition} other{Definitions}}'
  }
})

const omitFilterProperties = [
  IdentifiersDefCfgKeys.Id
]

const IdentifierDefinitions: FunctionComponent = () => {
  const intl = useIntl()
  const configurator = useConfigure()
  const filterer = useFilter()
  const pagination = usePagination()
  const sort = useSort(IdentifiersDefCfgKeys.Id)
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const descQuery = useQuery(
    Paths.IdentifiersDefCfg + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.IdentifiersDefCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'identifierDefinitions.failedSchema',
          description: 'Fetch identifier definition schema error notification text',
          defaultMessage: 'Failed to get Identifier Definition Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const dataQuery = useQuery([
    Paths.IdentifiersDefCfg,
    filterer.active,
    sort.order,
    sort.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => FunctionApp.getList({
    modelExpressions: filterer.active,
    order1: sort.order,
    orderBy1: sort.orderBy,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.IdentifiersDefCfg
  }), {
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'identifierDefinitions.failedIdentifierDefinitions',
        description: 'Fetch identifier definitions error notification text',
        defaultMessage: 'Failed to get Identifier Definitions!'
      }), {
        variant: 'error'
      }
    )
  })
  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.IdentifiersDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierDefinitions.failedCreate',
            description: 'Create identifier definition error notification text',
            defaultMessage: 'Failed to create Identifier Definition!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierDefinitions.successfulCreate',
            description: 'Create identifier definition success notification text',
            defaultMessage: 'Successfully created Identifier Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IdentifiersDefCfg)
      }
    }
  )
  const deleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.IdentifiersDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierDefinitions.failedDelete',
            description: 'Delete identifier definition error notification text',
            defaultMessage: 'Failed to delete Identifier Definitions!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierDefinitions.successfulDelete',
            description: 'Delete identifier definition success notification text',
            defaultMessage: 'Successfully deleted Identifier Definitions!'
          }), {
            variant: 'success'
          }
        )
        setSelected([])
        queryClient.invalidateQueries(Paths.IdentifiersDefCfg)
      }
    }
  )
  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.IdentifiersDefCfg
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identifierDefinitions.failedUpdate',
            description: 'Update identifier definition error notification text',
            defaultMessage: 'Failed to update Identifier Definition!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'identificationDefinitions.successfulUpdate',
            description: 'Update identifier definition success notification text',
            defaultMessage: 'Successfully updated Identifier Definition!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IdentifiersDefCfg)
      }
    }
  )

  const activeDefinition = useMemo(() => {
    if (!dataQuery.data?.Items) {
      return null
    }
    if (selected.length !== 1) {
      return null
    }
    return dataQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[IdentifiersDefCfgKeys.Id] === selected[0])
  }, [selected, dataQuery.data?.Items])

  const handleAddSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleAddSubmit if configurator data is undefined!')
    }
    createMutation.mutate([configurator.data])
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    deleteMutation.mutate(selected)
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEditSubmit = () => {
    if (!configurator.data) {
      throw Error('Cannot handleEditSubmit if configurator data is undefined!')
    }
    updateMutation.mutate([configurator.data])
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelected([])
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelected([])
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelected([])
  }

  const handleSelect = (ids: (number | string)[]) => {
    setSelected(ids.map(id => Number(id)))
  }

  const pageReady = descQuery.isSuccess && dataQuery.isSuccess &&
  !createMutation.isLoading && !deleteMutation.isLoading && !updateMutation.isLoading

  const pageLoading = descQuery.isLoading || dataQuery.isLoading ||
  createMutation.isLoading || deleteMutation.isLoading || updateMutation.isLoading

  return (
    <>
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                { configurator.data &&
                  descQuery.data?.CrudDescription &&
                  <DataForm
                    formSections={[{
                      data: configurator.data,
                      ignoredProperties: [IdentifiersDefCfgKeys.Id],
                      onPropertyChange: (property, value) => configurator.update({
                        [property]: value
                      }),
                      schema: descQuery.data.CrudDescription
                    }]}
                    onCancel={handleFormCancel}
                    onSubmit={configurator.mode === ConfigurationMode.Create
                      ? handleAddSubmit
                      : handleEditSubmit
                    }
                    title={configurator.mode === ConfigurationMode.Create
                      ? intl.formatMessage({
                        id: 'identifierDefinitions.create',
                        description: 'Create identifier definition dialogue title',
                        defaultMessage: 'Create Identifier Definition'
                      })
                      : intl.formatMessage({
                        id: 'identifierDefinitions.edit',
                        description: 'Edit identifier definition dialogue title',
                        defaultMessage: 'Edit Identifier Definition'
                      })
                    }
                  />
                }
                { filterer.data &&
                  descQuery.data?.ViewDescription &&
                  <FilterDialogue
                    filter={filterer.data}
                    onCancel={handleFormCancel}
                    onExpressionChange={filterer.update}
                    onReset={filterer.reset}
                    onSubmit={handleFilterSubmit}
                    schema={descQuery.data.ViewDescription}
                    title={intl.formatMessage({
                      id: 'identifierDefinitions.filter',
                      description: 'Filter identifier definitions dialogue title',
                      defaultMessage: 'Filter Identifier Definitions'
                    })}
                  />
                }
                { dataQuery.data?.Items &&
                  dataQuery.data?.Pagination &&
                  descQuery.data &&
                  <EnhancedTable
                    data={dataQuery.data.Items}
                    idProperty={IdentifiersDefCfgKeys.Id}
                    isFiltered={filterer.isActive}
                    multiSelect
                    onAdd={() => configurator.create(
                      descQuery.data.CrudDescription.Properties
                    )}
                    onDelete={handleDelete}
                    onEdit={activeDefinition
                      ? () => configurator.edit(activeDefinition)
                      : undefined
                    }
                    onFilter={() => filterer.initialise(
                      descQuery.data.ViewDescription.Properties.filter(
                        p => !includes(omitFilterProperties, p.PropertyName)
                      )
                    )}
                    onPageChange={handlePageChange}
                    onRequestSort={handleRequestSort}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelect={handleSelect}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    page={pagination.page}
                    rowsPerPage={pagination.rowsPerPage}
                    schema={descQuery.data.ViewDescription}
                    selected={selected}
                    title={intl.formatMessage({
                      id: 'identifierDefinitions.identifierDefinitions',
                      description: 'Identifier definitions table title',
                      defaultMessage: 'Identifier Definitions'
                    })}
                    totalRows={dataQuery.data.Pagination.TotalCount}
                  />
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'identifierDefinitions.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'identifierDefinitions.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, { count: selected.length })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, { count: selected.length })}
      />
    </>
  )
}

export default IdentifierDefinitions
