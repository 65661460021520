import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Tabs, useTheme } from '@material-ui/core'
import AccumulatorDefinitions from './AccumulatorDefinitions'
import LinkTab from '../../../../components/Tabs/LinkTab'
import TabPanel from '../../../../components/Tabs/TabPanel'
import UpdateMappedIotTags from '../UpdateMappedIotTags'
import AccumulatorInstances from './AccumulatorInstances'

export enum Routes {
  Definitions = '/accumulator-definitions',
  Instances = '/accumulator-instances'
}

enum TabsEnum {
  Instances,
  Definitions
}

const AccumulatorsCfg: FunctionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState<number>(TabsEnum.Instances)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box position="relative" paddingTop={1}>
      <Tabs
        onChange={handleTabChange}
        value={tabValue}
      >
        <LinkTab
          label={intl.formatMessage({
            id: 'accumulators.instances',
            description: 'Accumulators configuration page, instances tab title',
            defaultMessage: 'Instances'
          })}
          to={'.' + Routes.Instances}
        />
        <LinkTab
          label={intl.formatMessage({
            id: 'accumulators.definitions',
            description: 'Accumulators configuration page, definitions tab title',
            defaultMessage: 'Definitions'
          })}
          to={'.' + Routes.Definitions}
        />
      </Tabs>
      <TabPanel value={tabValue} index={TabsEnum.Instances}>
        <AccumulatorInstances/>
      </TabPanel>
      <TabPanel value={tabValue} index={TabsEnum.Definitions}>
        <AccumulatorDefinitions/>
      </TabPanel>
      <Box
        position="absolute"
        top={theme.spacing(0)}
        right={theme.spacing(4)}
      >
        <UpdateMappedIotTags/>
      </Box>
    </Box>
  )
}

export default AccumulatorsCfg
