import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Tab } from '@material-ui/core'

interface Props {
  label?: string,
  to: string
}

const LinkTab: FunctionComponent<Props> = (props: Props) => {
  return (
    <Tab
      component={Link}
      {...props}
    />
  )
}

export default LinkTab
