import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Tabs, useTheme } from '@material-ui/core'
import IdentifierDefinitions from './IdentifierDefinitions'
import IdentifierInstances from './IdentifierInstances'
import LinkTab from '../../../../components/Tabs/LinkTab'
import TabPanel from '../../../../components/Tabs/TabPanel'
import UpdateMappedIotTags from '../UpdateMappedIotTags'

export enum Routes {
  Definitions = '/identifier-definitions',
  Instances = '/identifier-instances'
}

enum TabsEnum {
  Instances,
  Definitions
}

const IdentifiersConfig: FunctionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState<number>(TabsEnum.Instances)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box position="relative" paddingTop={1}>
      <Tabs
        onChange={handleTabChange}
        value={tabValue}
      >
        <LinkTab
          label={intl.formatMessage({
            id: 'identifiers.instances',
            description: 'Identifiers configuration page, instances tab title',
            defaultMessage: 'Instances'
          })}
          to={'.' + Routes.Instances}
        />
        <LinkTab
          label={intl.formatMessage({
            id: 'identifiers.definitions',
            description: 'Identifiers configuration page, definitions tab title',
            defaultMessage: 'Definitions'
          })}
          to={'.' + Routes.Definitions}
        />
      </Tabs>
      <TabPanel value={tabValue} index={TabsEnum.Instances}>
        <IdentifierInstances/>
      </TabPanel>
      <TabPanel value={tabValue} index={TabsEnum.Definitions}>
        <IdentifierDefinitions/>
      </TabPanel>
      <Box
        position="absolute"
        top={theme.spacing(0)}
        right={theme.spacing(4)}
      >
        <UpdateMappedIotTags/>
      </Box>
    </Box>
  )
}

export default IdentifiersConfig
