import React, { FunctionComponent } from 'react'
import { useForm, Validate } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import DialogueTitle from './DialogueTitle'
import DraggablePaper from './DraggablePaper'
import { camelCaseToTitle } from '../api/Utils'
import { Property, PropertyLookup, Schema } from '../api/FunctionApp'

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      position: 'relative',
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  }
}))

export interface FormSection {
  data: Record<string, unknown>,
  disabledProperties?: string[],
  ignoredProperties?: string[],
  lookupProperties?: PropertyLookup[],
  onPropertyChange: (property: string, value: unknown) => void,
  schema: Schema,
  title?: string
}

export interface CustomValidator {
  propertyName: string,
  validateFunction: Validate | Record<string, Validate>
  errorMessage?: string
}

interface Props {
  customValidators?: CustomValidator[],
  formSections: FormSection[],
  onCancel: () => void,
  onSubmit: () => void,
  title?: string
}

const DataForm: FunctionComponent<Props> = (props: Props) => {
  const {
    customValidators,
    formSections,
    onCancel,
    onSubmit,
    title
  } = props
  const classes = useStyles()
  const { register, errors, handleSubmit } = useForm()

  const getLookupProperty = (
    data: Record<string, unknown>,
    lookupProperty: PropertyLookup
  ) => {
    const lookupMatch = lookupProperty.data?.find(
      p => data[lookupProperty.localProperty] === p[lookupProperty.remoteProperty]
    )
    return lookupMatch
  }

  const setLookupProperty = (
    lookupProperty: PropertyLookup,
    onPropertyChange: (property: string, value: unknown) => void,
    property: Property,
    value: unknown
  ) => {
    const valueProperty = lookupProperty.nameProperty
      ? lookupProperty.nameProperty
      : lookupProperty.remoteProperty
    const lookupMatch = lookupProperty.data?.find(
      p => p[valueProperty] === value
    )
    onPropertyChange(
      property.PropertyName,
      lookupMatch ? lookupMatch[lookupProperty.remoteProperty] : undefined
    )
  }

  const renderField = (property: Property, section: FormSection) => {
    const customValidation = customValidators?.find(
      x => x.propertyName === property.PropertyName
    )
    const disabled = section.disabledProperties?.some(
      x => x === property.PropertyName
    )
    if (section.lookupProperties) {
      const lookupProperty = section.lookupProperties.find(
        p => p.localProperty === property.PropertyName
      )
      if (lookupProperty) {
        const valueProperty = lookupProperty.nameProperty || lookupProperty.remoteProperty
        return (
          <Box width="100%">
            <Autocomplete
              disabled={disabled}
              options={lookupProperty.data || []}
              getOptionLabel={
                (option: Record<string, unknown>) =>
                  option[valueProperty] as string
              }
              loading={lookupProperty.loading}
              onInputChange={(_event, newInputValue) => {
                setLookupProperty(
                  lookupProperty,
                  section.onPropertyChange,
                  property,
                  newInputValue
                )
              }}
              renderInput={params =>
                <TextField
                  {...params}
                  disabled={disabled}
                  label={lookupProperty.label ||
                    camelCaseToTitle(valueProperty)}
                  required={property.Required}
                  variant="outlined"
                />
              }
              value={getLookupProperty(section.data, lookupProperty)}
            />
          </Box>
        )
      }
    }
    switch (property.JsPropertyType) {
      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(section.data[property.PropertyName])}
                disabled={disabled}
                onChange={(event) =>
                  section.onPropertyChange(
                    property.PropertyName, event.target.checked
                  )
                }
              />
            }
            label={camelCaseToTitle(property.PropertyName)}
          />
        )

      case 'number':
        return (
          <TextField
            disabled={disabled}
            error={errors[property.PropertyName]}
            helperText={customValidation?.errorMessage ?? ''}
            inputRef={register({
              validate: customValidation?.validateFunction
            })}
            label={camelCaseToTitle(property.PropertyName)}
            name={property.PropertyName}
            onChange={(event) =>
              section.onPropertyChange(
                property.PropertyName, event.target.value
              )
            }
            required={property.Required}
            type="number"
            value={section.data[property.PropertyName] ?? ''}
          />
        )

      default:
        return (
          <TextField
            disabled={disabled}
            error={errors[property.PropertyName]}
            helperText={customValidation?.errorMessage}
            inputRef={register({
              validate: customValidation?.validateFunction
            })}
            label={camelCaseToTitle(property.PropertyName)}
            name={property.PropertyName}
            onChange={(event) =>
              section.onPropertyChange(
                property.PropertyName, event.target.value
              )
            }
            required={property.Required}
            value={section.data[property.PropertyName] ?? ''}
          />
        )
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      scroll="body"
      PaperComponent={DraggablePaper}
    >
      <DialogueTitle draggable onClose={onCancel}>
        {title}
      </DialogueTitle>
      <DialogContent>
        { formSections.map((section, index) => {
          const filteredProperties = section.schema.Properties.filter(p => {
            if (section.ignoredProperties) {
              if (section.ignoredProperties.includes(p.PropertyName)) {
                return false
              }
            }
            return true
          })

          return (
            <React.Fragment key={index}>
              { section.title &&
                <Box marginTop={1} marginBottom={3}>
                  <Typography variant="h6">
                    {section.title}
                  </Typography>
                </Box>
              }
              <form
                className={classes.form}
                noValidate
                autoComplete="off"
              >
                <FormGroup>
                  {
                    filteredProperties.map((property) =>
                      <React.Fragment key={property.PropertyName}>
                        {renderField(property, section)}
                      </React.Fragment>
                    )
                  }
                </FormGroup>
              </form>
            </React.Fragment>
          )
        })}
        <DialogActions>
          {
            onSubmit &&
            <Button onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          }
          {
            onCancel &&
            <Button onClick={onCancel}>
              Cancel
            </Button>
          }
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DataForm
