import React from 'react'
import {
  createStyles,
  DialogTitle,
  IconButton,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  draggable?: boolean,
  onClose: () => void;
}

const DialogueTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    draggable,
    onClose,
    ...other
  } = props

  return (
    <DialogTitle
      disableTypography
      id={draggable ? 'draggable-dialog-title' : undefined}
      style={draggable ? { cursor: 'move' } : undefined}
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      { onClose
        ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        )
        : null
      }
    </DialogTitle>
  )
})

export default DialogueTitle
