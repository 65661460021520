import React, { FunctionComponent, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { isNil } from 'lodash'
import { Duration, sub } from 'date-fns'
import prettyMilliseconds from 'pretty-ms'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ContentLoading from '../../components/Loading/ContentLoading'
import EnhancedDataGrid from '../../components/EnhancedDataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import PageLoading from '../../components/Loading/PageLoading'
import Timeline from '../../components/Chart/Timeline'
import TimeRange from '../../components/TimeRange'
import Treemap from '../../components/Chart/Treemap'
import FunctionApp, {
  Expression,
  IncidentKeys,
  NodeKeys,
  Operator,
  Order,
  Paths
} from '../../api/FunctionApp'

const defaultDuration: Duration = { days: 1 }

const Incidents: FunctionComponent = () => {
  const intl = useIntl()
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [filter, setFilter] = useState<Expression[]>([])
  const [selectedNodes, setSelectedNodes] = useState<number[]>([])
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()
  const incidentsQuery = useQuery(
    [Paths.Incidents, endTime, filter, selectedNodes, startTime],
    () => {
      const expressions: Expression[] = [{
        Op: Operator.Equal,
        Prop: IncidentKeys.NodeId,
        Val: selectedNodes[0]
      }]
      if (!isNil(startTime)) {
        expressions.push({
          Op: Operator.GreaterThanEqual,
          Prop: IncidentKeys.StartDateTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: Operator.LessThanEqual,
          Prop: IncidentKeys.EndDateTime,
          Val: endTime
        })
      }
      return FunctionApp.getList({
        modelExpressions: expressions.concat(filter),
        order1: Order.desc,
        orderBy1: IncidentKeys.StartDateTime,
        path: Paths.Incidents
      })
    }, {
      enabled: selectedNodes.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidents.failedIncidents',
          description: 'Failed to fetch incidents error notification text',
          defaultMessage: 'Failed to get Incidents!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const incidentsDescQuery = useQuery(
    Paths.Incidents + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({ path: Paths.Incidents }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidents.failedIncidentSchema',
          description: 'Failed to fetch incident schema error notification text',
          defaultMessage: 'Failed to get Incident Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: NodeKeys.Active,
        Op: Operator.Equal,
        Val: true
      }],
      order1: Order.asc,
      orderBy1: NodeKeys.OrdinalPosition,
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidents.failedNodes',
          description: 'Failed to fetch nodes error notification text',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const handleFilterChange = (expressions: Expression[]) => {
    setFilter(expressions)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelectedNodes(ids)
    setFilter([])
  }

  const pageReady = incidentsDescQuery.isSuccess && nodesQuery.isSuccess
  const pageLoading = incidentsDescQuery.isLoading || nodesQuery.isLoading

  return (
    <>
      {
        pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              <Grid container item spacing={2} alignItems="stretch">
                {
                  nodesQuery.data?.Items &&
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Hierarchy
                      data={nodesQuery.data.Items}
                      idProperty={NodeKeys.Id}
                      nameProperty={NodeKeys.Name}
                      onSelect={handleSelectNodes}
                      ordinalProperty={NodeKeys.OrdinalPosition}
                      parentIdProperty={NodeKeys.ParentId}
                      selected={selectedNodes}
                      title={intl.formatMessage({
                        id: 'incidents.nodes',
                        description: 'Incidents viewer page, node hierarchy title',
                        defaultMessage: 'Nodes'
                      })}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={7} lg={8} xl={9}>
                  <Box
                    display="flex"
                    height="100%"
                    minHeight={750}
                  >
                    <Box flexGrow={1}>
                      { incidentsDescQuery.isSuccess &&
                        <EnhancedDataGrid
                          customColumns={[{
                            property: IncidentKeys.Duration,
                            gridColTypeDef: {
                              type: 'number',
                              width: 160,
                              valueFormatter: ({ value }) => prettyMilliseconds(Number(value))
                            }
                          }]}
                          data={incidentsQuery.data}
                          filterMode="server"
                          ignoredProperties={[IncidentKeys.NodeId]}
                          loading={incidentsQuery.isLoading}
                          onFilterChange={handleFilterChange}
                          schema={incidentsDescQuery.data?.ViewDescription}
                          widths={{
                            [IncidentKeys.Category]: 200,
                            [IncidentKeys.Description]: 280,
                            [IncidentKeys.GlobalIncidentName]: 280,
                            [IncidentKeys.Name]: 280
                          }}
                        />
                      }
                      { incidentsDescQuery.isLoading &&
                        <ContentLoading />
                      }
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {
                incidentsQuery.data?.Items &&
                incidentsQuery.data.Items.length > 0 &&
                <>
                  <Grid item xs={12}>
                    <Timeline
                      bandwidth={27}
                      categoryProperty={IncidentKeys.Category}
                      data={incidentsQuery.data.Items}
                      durationProperty={IncidentKeys.Duration}
                      endProperty={IncidentKeys.EndDateTime}
                      nameProperty={IncidentKeys.Name}
                      startProperty={IncidentKeys.StartDateTime}
                      title={intl.formatMessage({
                        id: 'incidents.incidentTimeline',
                        description: 'Incident timeline chart title',
                        defaultMessage: 'Incident Timeline'
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Treemap
                      data={incidentsQuery.data.Items}
                      height={750}
                      nameProperty={IncidentKeys.Name}
                      renderValue={prettyMilliseconds}
                      valueProperty={IncidentKeys.Duration}
                      title={intl.formatMessage({
                        id: 'incidents.incidentTreemap',
                        description: 'Incident treemap chart title',
                        defaultMessage: 'Incident Treemap'
                      })}
                    />
                  </Grid>
                </>
              }
            </Grid>
          </Container>
        </Box>
      }
      {
        pageLoading &&
        <PageLoading/>
      }
    </>
  )
}

export default Incidents
