import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { defineMessages, useIntl } from 'react-intl'
import { includes, isNil } from 'lodash'
import { Box, Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../../components/AlertDialogue'
import ContentLoading from '../../../../components/Loading/ContentLoading'
import DataForm from '../../../../components/DataForm'
import EnhancedTable from '../../../../components/Table/EnhancedTable'
import FilterDialogue from '../../../../components/FilterDialogue'
import Hierarchy from '../../../../components/Hierarchy/Hierarchy'
import PageLoading from '../../../../components/Loading/PageLoading'
import Placeholder from '../../../../components/Placeholder'
import useConfigure, { ConfigurationMode } from '../../../../hooks/useConfigure'
import useFilter from '../../../../hooks/useFilter'
import useIotMapping from '../../../../hooks/useIotMapping'
import usePagination from '../../../../hooks/usePagination'
import useSort from '../../../../hooks/useSort'
import FunctionApp, {
  DataCollectionCategoryKeys,
  IncidentsConfigKeys,
  IncidentsDefCfgKeys,
  NodeKeys,
  Operator,
  Order,
  Paths
} from '../../../../api/FunctionApp'

const messages = defineMessages({
  alertMessage: {
    id: 'incidentInstances.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected ' +
      '{count, plural, one{Incident} other{Incidents}}?'
  },
  alertTitle: {
    id: 'incidentInstances.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Incident} other{Incidents}}'
  }
})

const omitFilterProperties = [
  IncidentsConfigKeys.CreateDateTime,
  IncidentsConfigKeys.CollectionCategoryName,
  IncidentsConfigKeys.GlobalIncidentName,
  IncidentsConfigKeys.Id,
  IncidentsConfigKeys.NodeId,
  IncidentsConfigKeys.RcfId
]

const IncidentInstances: FunctionComponent = () => {
  const intl = useIntl()
  const filterer = useFilter()
  const configurator = useConfigure()
  const pagination = usePagination()
  const sort = useSort(IncidentsConfigKeys.Id)
  const [activeIncident, setActiveIncident] = useState<Record<string, unknown>>()
  const iotMapping = useIotMapping(!isNil(activeIncident)
    ? String(activeIncident[IncidentsConfigKeys.RcfId])
    : undefined
  )
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [selectedIncidents, setSelectedIncidents] = useState<number[]>([])
  const [selectedNodes, setSelectedNodes] = useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const categoriesQuery = useQuery(
    Paths.DataCollectionCategories,
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: DataCollectionCategoryKeys.DataCategoryName,
        Op: Operator.Equal,
        Val: 'Incidents'
      }],
      path: Paths.DataCollectionCategories
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidentInstances.failedCategories',
          description: 'Fetch data collection categories error notification text',
          defaultMessage: 'Failed to get Data Collection Categories!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const incidentsDefCfgQuery = useQuery(
    Paths.IncidentsDefCfg,
    () => FunctionApp.getList({
      path: Paths.IncidentsDefCfg
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidentInstances.failedIncidentDefinitions',
          description: 'Fetch incident definitions error notification text',
          defaultMessage: 'Failed to get Incident Definitions!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const incidentsDescQuery = useQuery(
    Paths.IncidentsConfig + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.IncidentsConfig
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidentInstances.failedIncidentSchema',
          description: 'Fetch incident schema error notification text',
          defaultMessage: 'Failed to get Incident Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const incidentsQuery = useQuery([
    Paths.IncidentsConfig,
    filterer.active,
    sort.orderBy,
    sort.order,
    pagination.page,
    pagination.rowsPerPage,
    selectedNodes
  ],
  () => FunctionApp.getList({
    modelExpressions: [
      ...filterer.active, {
        Prop: IncidentsConfigKeys.NodeId,
        Op: Operator.Equal,
        Val: selectedNodes[0]
      }
    ],
    orderBy1: sort.orderBy,
    order1: sort.order,
    pageNumber: pagination.page,
    pageSize: pagination.rowsPerPage,
    path: Paths.IncidentsConfig
  }), {
    enabled: selectedNodes.length === 1,
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'incidentInstances.failedIncidents',
        description: 'Fetch incidents error notification text',
        defaultMessage: 'Failed to get Incidents!'
      }), {
        variant: 'error'
      }
    )
  })
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      order1: Order.asc,
      orderBy1: NodeKeys.OrdinalPosition,
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'incidentInstances.failedNodes',
          description: 'Fetch nodes error notification text',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const incidentCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.IncidentsConfig
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.failedCreateIncident',
            description: 'Create incident error notification text',
            defaultMessage: 'Failed to create Incident!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.successfulCreateIncident',
            description: 'Create incident success notification text',
            defaultMessage: 'Successfully created Incident!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IncidentsConfig)
      }
    }
  )
  const incidentDeleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.IncidentsConfig
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.failedDeleteIncidents',
            description: 'Delete incidents error notification text',
            defaultMessage: 'Failed to delete Incidents!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.successfulDeleteIncidents',
            description: 'Delete incidents success notification text',
            defaultMessage: 'Successfully deleted Incidents!'
          }), {
            variant: 'success'
          }
        )
        setSelectedIncidents([])
        queryClient.invalidateQueries(Paths.IncidentsConfig)
      }
    }
  )
  const incidentUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.IncidentsConfig
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.failedUpdateIncident',
            description: 'Update incidents error notification text',
            defaultMessage: 'Failed to update Incident!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'incidentInstances.successfulUpdateIncident',
            description: 'Update incidents success notification text',
            defaultMessage: 'Successfully updated Incident!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.IncidentsConfig)
      }
    }
  )

  useEffect(() => {
    if (!incidentsQuery.data?.Items) {
      return
    }
    if (selectedIncidents.length !== 1) {
      return
    }
    const newActiveIncident = incidentsQuery.data?.Items.find(
      (d: Record<string, unknown>) => d[IncidentsConfigKeys.Id] === selectedIncidents[0]
    )
    setActiveIncident(newActiveIncident)
  }, [incidentsQuery.data?.Items, selectedIncidents])

  const incidentLookupProperties = useMemo(() => {
    if (!categoriesQuery.data?.Items ||
      !incidentsDefCfgQuery.data?.Items) {
      return
    }
    return [{
      data: categoriesQuery.data.Items,
      localProperty: IncidentsConfigKeys.DataCollectionCategoryId,
      nameProperty: DataCollectionCategoryKeys.Name,
      remoteProperty: DataCollectionCategoryKeys.Id
    }, {
      data: incidentsDefCfgQuery.data.Items,
      label: intl.formatMessage({
        id: 'incidentInstances.globalIncidentName',
        description: 'Global incident name form input label',
        defaultMessage: 'Global Incident Name'
      }),
      localProperty: IncidentsConfigKeys.IncidentDefId,
      nameProperty: IncidentsDefCfgKeys.Name,
      remoteProperty: IncidentsDefCfgKeys.Id
    }]
  }, [categoriesQuery.data?.Items, incidentsDefCfgQuery.data?.Items, intl])

  const handleAdd = () => {
    if (isNil(incidentsDescQuery.data)) {
      throw Error('Cannot handleAdd if incidents description is nil!')
    }
    configurator.create(
      incidentsDescQuery.data.CrudDescription.Properties, {
        [IncidentsConfigKeys.NodeId]: selectedNodes[0]
      }
    )
    iotMapping.add()
  }

  const handleAddSubmit = async () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleAddSubmit if incidentConfigurator data is nil!')
    }
    const createResponse = await incidentCreateMutation.mutateAsync([
      configurator.data
    ])
    const getListResponse = await FunctionApp.getList({
      modelExpressions: [{
        Op: Operator.Equal,
        Prop: IncidentsConfigKeys.Id,
        Val: createResponse.data[0]
      }],
      path: Paths.IncidentsConfig
    })
    iotMapping.addSubmit(String(getListResponse.Items[0][IncidentsConfigKeys.RcfId]))
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    incidentDeleteMutation.mutate(selectedIncidents)
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEdit = () => {
    if (!activeIncident) {
      throw Error('Cannot handleEdit if activeIncident is null!')
    }
    configurator.edit(activeIncident)
    iotMapping.edit()
  }

  const handleEditSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleEditSubmit if incidentConfigurator data is nil!')
    }
    incidentUpdateMutation.mutate([configurator.data])
    iotMapping.editSubmit()
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    iotMapping.clear()
    filterer.clearData()
  }

  const handlePageChange = (newPage: number) => {
    pagination.setPage(newPage)
    setSelectedIncidents([])
  }

  const handleRowsPerPageChange = (rows: number) => {
    pagination.setRowsPerPage(rows)
    setSelectedIncidents([])
  }

  const handleRequestSort = (property: string) => {
    sort.requestSort(property)
    setSelectedIncidents([])
  }

  const handleSelectIncidents = (ids: (number | string)[]) => {
    setSelectedIncidents(ids.map(Number))
  }

  const handleSelectNodes = (ids: (number | string)[]) => {
    setSelectedNodes(ids.map(Number))
    setSelectedIncidents([])
  }

  const handleSubmit = () => {
    switch (configurator.mode) {
      case ConfigurationMode.Create:
        handleAddSubmit()
        break

      case ConfigurationMode.Edit:
        handleEditSubmit()
        break

      default:
        throw Error('Cannot handleSubmit if both incidentConfigurator mode is undefined!')
    }
  }

  const pageReady = categoriesQuery.isSuccess && incidentsDefCfgQuery.isSuccess &&
    incidentsDescQuery.isSuccess && nodesQuery.isSuccess && iotMapping.isReady &&
    !incidentCreateMutation.isLoading && !incidentDeleteMutation.isLoading &&
    !incidentUpdateMutation.isLoading &&
    !(isNil(configurator.data) && iotMapping.mode === ConfigurationMode.Create)

  const pageLoading = categoriesQuery.isLoading || incidentsDefCfgQuery.isLoading ||
    incidentsDescQuery.isLoading || nodesQuery.isLoading || iotMapping.isLoading ||
    incidentCreateMutation.isLoading || incidentDeleteMutation.isLoading ||
    incidentUpdateMutation.isLoading ||
    (isNil(configurator.data) && iotMapping.mode === ConfigurationMode.Create)

  return (
    <>
      { pageReady &&
        <>
          { configurator.data &&
            incidentsDescQuery.data?.CrudDescription &&
            incidentLookupProperties &&
            iotMapping.formSection &&
            <DataForm
              formSections={[{
                data: configurator.data,
                ignoredProperties: [
                  IncidentsConfigKeys.Id,
                  IncidentsConfigKeys.NodeId
                ],
                lookupProperties: incidentLookupProperties,
                onPropertyChange: (property, value) => configurator.update({
                  [property]: value
                }),
                schema: incidentsDescQuery.data.CrudDescription
              }, iotMapping.formSection]}
              onCancel={handleFormCancel}
              onSubmit={handleSubmit}
              title={configurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'incidentInstances.createIncident',
                  description: 'Incident instances page, create incident dialogue title',
                  defaultMessage: 'Create Incident'
                })
                : intl.formatMessage({
                  id: 'incidentInstances.editIncident',
                  description: 'Incident instances page, edit incident dialogue title',
                  defaultMessage: 'Edit Incident'
                })
              }
            />
          }
          {
            filterer.data &&
            incidentsDescQuery.data?.ViewDescription &&
            <FilterDialogue
              filter={filterer.data}
              lookupProperties={incidentLookupProperties}
              onCancel={handleFormCancel}
              onExpressionChange={filterer.update}
              onReset={filterer.reset}
              onSubmit={handleFilterSubmit}
              schema={incidentsDescQuery.data.ViewDescription}
              title={intl.formatMessage({
                id: 'incidentInstances.filterIncidents',
                description: 'Incident instances page, filter instance dialogue title',
                defaultMessage: 'Filter Incidents'
              })}
            />
          }
          <Box paddingTop={3} paddingBottom={3}>
            <Container maxWidth={false}>
              <>
                {
                  nodesQuery.data?.Items &&
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                      <Box alignItems="flex-start">
                        <Hierarchy
                          activeProperty={NodeKeys.Active}
                          data={nodesQuery.data.Items}
                          idProperty={NodeKeys.Id}
                          nameProperty={NodeKeys.Name}
                          onSelect={handleSelectNodes}
                          ordinalProperty={NodeKeys.OrdinalPosition}
                          parentIdProperty={NodeKeys.ParentId}
                          selected={selectedNodes}
                          title={intl.formatMessage({
                            id: 'incidentInstances.nodes',
                            description: 'Incident instances page, node hierarchy title',
                            defaultMessage: 'Nodes'
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                      <Box height="100%" position="sticky" top={3}>
                        {
                          incidentsQuery.isSuccess &&
                          incidentsQuery.data?.Items &&
                          incidentsQuery.data?.Pagination &&
                          incidentsDescQuery.data?.ViewDescription &&
                          <EnhancedTable
                            data={incidentsQuery.data.Items}
                            idProperty={IncidentsConfigKeys.Id}
                            isFiltered={filterer.isActive}
                            ignoredProperties={[
                              IncidentsConfigKeys.CreateDateTime,
                              IncidentsConfigKeys.HasAttachment,
                              IncidentsConfigKeys.Id,
                              IncidentsConfigKeys.IncidentDefId,
                              IncidentsConfigKeys.NodeId,
                              IncidentsConfigKeys.DataCollectionCategoryId,
                              IncidentsConfigKeys.RcfId
                            ]}
                            multiSelect
                            onAdd={handleAdd}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onDelete={handleDelete}
                            onEdit={iotMapping.canEdit
                              ? handleEdit
                              : undefined
                            }
                            onFilter={() => filterer.initialise(
                              incidentsDescQuery.data.ViewDescription.Properties.filter(
                                p => !includes(omitFilterProperties, p.PropertyName)
                              )
                            )}
                            onRequestSort={handleRequestSort}
                            onSelect={handleSelectIncidents}
                            order={sort.order}
                            orderBy={sort.orderBy}
                            page={pagination.page}
                            rowsPerPage={pagination.rowsPerPage}
                            schema={incidentsDescQuery.data.ViewDescription}
                            selected={selectedIncidents}
                            title={intl.formatMessage({
                              id: 'incidentInstances.incidents',
                              description: 'Incidents table title',
                              defaultMessage: 'Incidents'
                            })}
                            totalRows={incidentsQuery.data.Pagination.TotalCount}
                          />
                        }
                        { incidentsQuery.isLoading &&
                          <ContentLoading/>
                        }
                        { selectedNodes.length !== 1 &&
                          <Placeholder
                            message={intl.formatMessage({
                              id: 'incidentInstances.placeholderMessage',
                              description: 'Node selection placeholder message',
                              defaultMessage: 'Select a Node to View and Edit its Incidents'
                            })}
                          />
                        }
                      </Box>
                    </Grid>
                  </Grid>
                }
              </>
            </Container>
          </Box>
        </>
      }
      { pageLoading &&
        <PageLoading/>
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'incidentInstances.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'incidentInstances.alertCancel',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(
          messages.alertMessage,
          { count: selectedIncidents.length }
        )}
        open={alertOpen}
        title={intl.formatMessage(
          messages.alertTitle,
          { count: selectedIncidents.length }
        )}
      />
    </>
  )
}

export default IncidentInstances
