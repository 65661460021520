import React, { FunctionComponent, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { filter, isNil, uniq } from 'lodash'
import { Duration, sub } from 'date-fns'
import { Box, Container, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import ContentLoading from '../../components/Loading/ContentLoading'
import EnhancedDataGrid from '../../components/EnhancedDataGrid'
import Hierarchy from '../../components/Hierarchy/Hierarchy'
import LineChart, { LineSeries } from '../../components/Chart/LineChart'
import PageLoading from '../../components/Loading/PageLoading'
import TimeRange from '../../components/TimeRange'
import FunctionApp, {
  EventVariablesKeys,
  Expression,
  NodeKeys,
  Operator,
  Order,
  Paths
} from '../../api/FunctionApp'
import { camelCaseToTitle } from '../../api/Utils'

const defaultDuration: Duration = { days: 1 }

const Events: FunctionComponent = () => {
  const intl = useIntl()
  const [endTime, setEndTime] = useState<Date | null>(new Date())
  const [columnFilter, setColumnFilter] = useState<Expression[]>([])
  const [selectedEvent, setSelectedEvent] = useState<string>()
  const [selectedEventVars, setSelectedEventVars] = useState<string[]>([])
  const [selectedNodes, setSelectedNodes] = useState<number[]>([])
  const [startTime, setStartTime] = useState<Date | null>(
    sub(new Date(), defaultDuration)
  )
  const { enqueueSnackbar } = useSnackbar()
  const eventVarQuery = useQuery(
    [Paths.EventVariables, endTime, columnFilter, selectedNodes, startTime],
    () => {
      const expressions: Expression[] = [{
        Op: Operator.Equal,
        Prop: EventVariablesKeys.NodeId,
        Val: selectedNodes[0]
      }]
      if (!isNil(startTime)) {
        expressions.push({
          Op: Operator.GreaterThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: startTime
        })
      }
      if (!isNil(endTime)) {
        expressions.push({
          Op: Operator.LessThanEqual,
          Prop: EventVariablesKeys.RecordTime,
          Val: endTime
        })
      }
      return FunctionApp.getList({
        modelExpressions: expressions.concat(columnFilter),
        order1: Order.desc,
        orderBy1: EventVariablesKeys.RecordTime,
        path: Paths.EventVariables
      })
    }, {
      enabled: selectedNodes.length === 1,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'events.failedEventVariables',
          description: 'Failed to get event variables notification text',
          defaultMessage: 'Failed to get Event Variables!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const eventVarDescQuery = useQuery(
    Paths.EventVariables + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({ path: Paths.EventVariables }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'events.failedEventVariablesSchema',
          description: 'Failed to get event variables schema notification text',
          defaultMessage: 'Failed to get Event Variable Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: NodeKeys.Active,
        Op: Operator.Equal,
        Val: true
      }],
      order1: Order.asc,
      orderBy1: NodeKeys.OrdinalPosition,
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'events.failedNodes',
          description: 'Failed to get nodes notification text',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const events = useMemo(() => {
    if (isNil(eventVarQuery.data) || isNil(eventVarQuery.data.Items)) {
      return undefined
    }
    return uniq(eventVarQuery.data.Items.map(d => d[EventVariablesKeys.EventName]))
  }, [eventVarQuery.data])

  const eventVars = useMemo(() => {
    if (isNil(eventVarQuery.data) || isNil(eventVarQuery.data.Items) || isNil(selectedEvent)) {
      return undefined
    }
    return uniq(eventVarQuery.data.Items.filter(
      f => f[EventVariablesKeys.EventName] === selectedEvent).map(
      m => m[EventVariablesKeys.VariableName]
    ))
  }, [eventVarQuery.data, selectedEvent])

  const eventData = useMemo(() => {
    if (!eventVarQuery.data?.Items) {
      return []
    }
    const identifiers = selectedEventVars.map(m => ({
      [EventVariablesKeys.EventName]: selectedEvent,
      [EventVariablesKeys.VariableName]: m
    }))
    const selections: LineSeries[] = []
    for (const identity of identifiers) {
      selections.push({
        data: filter(eventVarQuery.data.Items, identity).reverse(),
        name: String(identity[EventVariablesKeys.VariableName])
      })
    }
    return selections
  }, [eventVarQuery.data?.Items, selectedEvent, selectedEventVars])

  const handleEventChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    value: unknown
  ) => {
    setSelectedEvent(!isNil(value) ? String(value) : undefined)
    setSelectedEventVars([])
  }

  const handleEventVarsChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    values: unknown[]
  ) => {
    setSelectedEventVars(values.map(String))
  }

  const handleFilterChange = (expressions: Expression[]) => {
    setColumnFilter(expressions)
  }

  const handleSelectNodes = (ids: number[]) => {
    setSelectedNodes(ids)
    setColumnFilter([])
    setSelectedEvent(undefined)
    setSelectedEventVars([])
  }

  const pageReady = nodesQuery.isSuccess
  const pageLoading = nodesQuery.isLoading

  return (
    <>
      { pageReady &&
        <Box paddingTop={3} paddingBottom={3}>
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <TimeRange
                endTime={endTime}
                onEndTimeChange={setEndTime}
                onStartTimeChange={setStartTime}
                startTime={startTime}
              />
              {
                nodesQuery.data?.Items &&
                <Grid item xs={12} md={5} lg={4} xl={3}>
                  <Hierarchy
                    data={nodesQuery.data.Items}
                    idProperty={NodeKeys.Id}
                    label="Node"
                    nameProperty={NodeKeys.Name}
                    onSelect={handleSelectNodes}
                    ordinalProperty={NodeKeys.OrdinalPosition}
                    parentIdProperty={NodeKeys.ParentId}
                    selected={selectedNodes}
                    title={intl.formatMessage({
                      id: 'events.nodes',
                      description: 'Events viewer page, node hierarchy title',
                      defaultMessage: 'Nodes'
                    })}
                  />
                </Grid>
              }
              <Grid item xs={12} md={7} lg={8} xl={9}>
                <Box
                  display="flex"
                  height="100%"
                  minHeight={750}
                >
                  <Box flexGrow={1}>
                    { eventVarDescQuery.isSuccess &&
                      <EnhancedDataGrid
                        data={eventVarQuery.data}
                        filterMode="server"
                        ignoredProperties={[
                          EventVariablesKeys.EventId,
                          EventVariablesKeys.NodeId,
                          EventVariablesKeys.UnitId
                        ]}
                        loading={eventVarQuery.isLoading}
                        onFilterChange={handleFilterChange}
                        schema={eventVarDescQuery.data?.ViewDescription}
                        widths={{
                          [EventVariablesKeys.EventFilter]: 130,
                          [EventVariablesKeys.EventName]: 200,
                          [EventVariablesKeys.Value]: 200,
                          [EventVariablesKeys.VariableName]: 200,
                          [EventVariablesKeys.UnitName]: 120
                        }}
                      />
                    }
                    { eventVarDescQuery.isLoading &&
                      <ContentLoading />
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    disabled={isNil(events)}
                    loading={eventVarQuery.isLoading}
                    onChange={handleEventChange}
                    options={events || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={intl.formatMessage({
                          id: 'events.event',
                          description: 'Event input field label',
                          defaultMessage: 'Event'
                        })}
                      />
                    )}
                    value={selectedEvent}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Autocomplete
                    disabled={isNil(eventVars)}
                    loading={eventVarQuery.isLoading}
                    multiple
                    onChange={handleEventVarsChange}
                    options={eventVars || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={intl.formatMessage({
                          id: 'events.eventVariables',
                          description: 'Event variables input field label',
                          defaultMessage: 'Event Variables'
                        })}
                      />
                    )}
                    value={selectedEventVars}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {
                  selectedEvent &&
                  selectedEventVars.length > 0 &&
                  <>
                    {
                      <LineChart
                        series={eventData}
                        height={750}
                        title={intl.formatMessage({
                          id: 'events.lineChartTitle',
                          description: 'Events page line chart title',
                          defaultMessage: '{yProperty} vs {xProperty}'
                        }, {
                          xProperty: camelCaseToTitle(
                            EventVariablesKeys.RecordTime
                          ),
                          yProperty: selectedEvent
                        })}
                        xProperty={EventVariablesKeys.RecordTime}
                        yProperty={EventVariablesKeys.Value}
                      />
                    }
                  </>
                }
              </Grid>
            </Grid>
          </Container>
        </Box>
      }
      { pageLoading &&
        <PageLoading />
      }
    </>
  )
}

export default Events
