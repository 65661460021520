import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { isNil, uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import FunctionApp, {
  IotDevicesKeys,
  IotTagDistinctKeys,
  IotTagMappingKeys,
  List,
  Operator,
  Paths
} from '../api/FunctionApp'
import useConfigure, { ConfigurationMode } from './useConfigure'
import { FormSection } from '../components/DataForm'

interface IotMapping {
  add: () => void,
  addSubmit: (rcfId: string) => void,
  canEdit: boolean,
  clear: () => void,
  edit: () => void,
  editSubmit: () => void,
  formSection?: FormSection,
  isLoading: boolean,
  isReady: boolean,
  mode?: ConfigurationMode
}

export default function useIotMapping (rcfId?: string): IotMapping {
  const intl = useIntl()
  const configurator = useConfigure()
  const [deviceId, setDeviceId] = useState<string>()
  const [objectName, setObjectName] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const devicesQuery = useQuery(
    Paths.IotDevices,
    () => FunctionApp.getList({
      path: Paths.IotDevices
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'useIotMapping.failedIotDevices',
          description: 'Fetch IoT devices error notification text',
          defaultMessage: 'Failed to get IoT Devices!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const mappingQuery = useQuery(
    [Paths.IotTagMapping, rcfId],
    () => {
      if (rcfId) {
        return FunctionApp.getList({
          modelExpressions: [{
            Prop: IotTagMappingKeys.RcfId,
            Op: Operator.Equal,
            Val: rcfId
          }],
          path: Paths.IotTagMapping
        })
      }
    }, {
      enabled: !isNil(rcfId),
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'useIotMapping.failedIotTagMappings',
          description: 'Fetch IoT tag mappings error notification text',
          defaultMessage: 'Failed to get IoT Tag Mappings!'
        }), {
          variant: 'error'
        }
      ),
      onSuccess: (data: List) => {
        if (!isNil(data?.Items) && data.Items.length > 0) {
          const deviceId = data.Items[0][IotTagMappingKeys.IotDeviceId]
          setDeviceId(!isNil(deviceId) ? String(deviceId) : undefined)
          const objectName = data.Items[0][IotTagMappingKeys.ObjectName]
          setObjectName(!isNil(objectName) ? String(objectName) : undefined)
        }
      }
    }
  )
  const mappingDescQuery = useQuery(
    Paths.IotTagMapping + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.IotTagMapping
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'useIotMapping.failedIotTagMappingSchema',
          description: 'Fetch IoT tag mapping schema error notification text',
          defaultMessage: 'Failed to get IoT Tag Mapping Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const tagsQuery = useQuery(
    [Paths.IotTagDistinct, deviceId],
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: IotTagDistinctKeys.IotDeviceId,
        Op: Operator.Equal,
        Val: deviceId
      }],
      path: Paths.IotTagDistinct
    }), {
      enabled: !!deviceId,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'useIotMapping.failedIotTags',
          description: 'Fetch IoT tags error notification text',
          defaultMessage: 'Failed to get IoT Tags!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const createMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.IotTagMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.failedCreateIotTagMapping',
            description: 'Create IoT tag mapping error notification text',
            defaultMessage: 'Failed to create IoT Tag Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.successfulCreateIotTagMapping',
            description: 'Create IoT tag mapping success notification text',
            defaultMessage: 'Successfully created IoT Tag Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.IotTagMapping)
      },
      onSettled: () => {
        configurator.clear()
      }
    }
  )
  const deleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.IotTagMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.failedDeleteIotTagMapping',
            description: 'Delete IoT tag mapping error notification text',
            defaultMessage: 'Failed to delete IoT Tag Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.successfulDeleteIotTagMapping',
            description: 'Delete IoT tag mapping success notification text',
            defaultMessage: 'Successfully deleted IoT Tag Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.IotTagMapping)
      },
      onSettled: () => {
        configurator.clear()
      }
    }
  )
  const updateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.IotTagMapping
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.failedUpdateIotTagMapping',
            description: 'Update IoT tag mapping error notification text',
            defaultMessage: 'Failed to update IoT Tag Mapping!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'useIotMapping.successfulUpdateIotTagMapping',
            description: 'Update IoT tag mapping success notification text',
            defaultMessage: 'Successfully updated IoT Tag Mapping!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.IotTagMapping)
      },
      onSettled: () => {
        configurator.clear()
      }
    }
  )

  const lookupProperties = useMemo(() => {
    if (!devicesQuery.data?.Items) {
      return
    }
    return [{
      data: devicesQuery.data?.Items,
      localProperty: IotTagMappingKeys.IotDeviceId,
      remoteProperty: IotDevicesKeys.Id
    }, {
      data: tagsQuery.data?.Items
        ? uniqBy(tagsQuery.data.Items, IotTagMappingKeys.ObjectName)
        : undefined,
      loading: tagsQuery.isLoading,
      localProperty: IotTagMappingKeys.ObjectName,
      remoteProperty: IotTagMappingKeys.ObjectName
    }, {
      data: tagsQuery.data?.Items && objectName
        ? tagsQuery.data.Items.filter(
          tag => tag[IotTagMappingKeys.ObjectName] === objectName)
        : undefined,
      localProperty: IotTagMappingKeys.IotTagId,
      nameProperty: IotTagDistinctKeys.Name,
      remoteProperty: IotTagDistinctKeys.Id
    }]
  }, [devicesQuery.data?.Items, objectName, tagsQuery])

  const disabledProperties = useMemo(() => {
    const disabled = []
    if (isNil(deviceId)) {
      disabled.push(IotTagMappingKeys.ObjectName)
    }
    if (isNil(objectName)) {
      disabled.push(IotTagMappingKeys.IotTagId)
    }
    return disabled
  }, [deviceId, objectName])

  const mappingSchema = useMemo(() => {
    if (!mappingDescQuery.data?.CrudDescription) {
      return
    }
    return {
      ClassName: mappingDescQuery.data.CrudDescription.ClassName,
      Properties: [
        {
          JsPropertyType: 'string',
          PropertyName: IotTagMappingKeys.IotDeviceId
        },
        {
          JsPropertyType: 'string',
          PropertyName: IotTagMappingKeys.ObjectName
        },
        ...mappingDescQuery.data.CrudDescription.Properties
      ]
    }
  }, [mappingDescQuery.data?.CrudDescription])

  const handlePropertyChange = useCallback((property: string, value: unknown) => {
    if (property === IotTagMappingKeys.IotDeviceId) {
      setDeviceId(String(value))
    } else if (property === IotTagMappingKeys.ObjectName) {
      setObjectName(isNil(value) ? undefined : String(value))
    }

    configurator.update({ [property]: value })
  }, [configurator])

  const formSection = useMemo(() => {
    if (isNil(configurator.data) ||
      isNil(lookupProperties) ||
      isNil(mappingSchema)) {
      return
    }
    return {
      data: configurator.data,
      disabledProperties: disabledProperties,
      ignoredProperties: [
        IotTagMappingKeys.Id,
        IotTagMappingKeys.RcfId
      ],
      lookupProperties: lookupProperties,
      onPropertyChange: handlePropertyChange,
      schema: mappingSchema,
      title: configurator.mode === ConfigurationMode.Create
        ? intl.formatMessage({
          id: 'useIotMapping.createIotTagMapping',
          description: 'Create IoT Tag Mapping form section title',
          defaultMessage: 'Create IoT Tag Mapping'
        })
        : intl.formatMessage({
          id: 'useIotMapping.editIotTagMapping',
          description: 'Edit IoT Tag Mapping form section title',
          defaultMessage: 'Edit IoT Tag Mapping'
        })
    }
  }, [
    configurator.data,
    configurator.mode,
    disabledProperties,
    handlePropertyChange,
    intl,
    lookupProperties,
    mappingSchema
  ])

  const add = () => {
    if (!mappingDescQuery.data?.CrudDescription) {
      throw Error('Cannot createNewMapping if mapping CrudDescription is null!')
    }
    configurator.create([{
      PropertyName: IotTagMappingKeys.IotDeviceId,
      JsPropertyType: 'string'
    }, {
      PropertyName: IotTagMappingKeys.ObjectName,
      JsPropertyType: 'string'
    },
    ...mappingDescQuery.data.CrudDescription.Properties
    ])
    setDeviceId(undefined)
    setObjectName(undefined)
  }

  const create = (rcfId: string) => {
    if (isNil(configurator.data)) {
      throw Error('Cannot saveNewMapping if mappingConfigurator data is nil!')
    }
    if (isNil(mappingDescQuery.data)) {
      throw Error('Cannot saveNewMapping if mapping description is nil!')
    }
    createMutation.mutate([{
      ...FunctionApp.filterProps(
        configurator.data,
        mappingDescQuery.data.CrudDescription.Properties
      ),
      [IotTagMappingKeys.RcfId]: rcfId
    }])
  }

  const addSubmit = (rcfId: string) => {
    if (isNil(configurator.data)) {
      throw Error('Cannot addSubmit if configurator data is nil!')
    }
    if (!isNil(configurator.data[IotTagMappingKeys.IotTagId])) {
      create(rcfId)
    } else {
      configurator.clear()
    }
  }

  const edit = () => {
    if (!isNil(mappingQuery.data) && mappingQuery.data.Items.length > 0) {
      configurator.edit(mappingQuery.data.Items[0])
    } else {
      add()
    }
  }

  const editSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot editSubmit if configurator data is nil!')
    }
    if (isNil(rcfId)) {
      throw Error('Cannot editSubmit if rcfId is nil!')
    }

    switch (configurator.mode) {
      case ConfigurationMode.Create:
        if (!isNil(configurator.data[IotTagMappingKeys.IotTagId])) {
          create(rcfId)
        } else {
          configurator.clear()
        }
        break

      case ConfigurationMode.Edit:
        if (!isNil(configurator.data[IotTagMappingKeys.IotTagId])) {
          updateMutation.mutate([configurator.data])
        } else {
          deleteMutation.mutate([Number(configurator.data[IotTagMappingKeys.Id])])
        }
        break

      default:
        throw Error('Cannot handleEditSubmit if mappingConfigurator mode is undefined!')
    }
  }

  const canEdit = mappingQuery.isSuccess &&
    (isNil(deviceId) || tagsQuery.isSuccess)

  const isReady = devicesQuery.isSuccess && mappingDescQuery.isSuccess &&
    !createMutation.isLoading && !deleteMutation.isLoading &&
    !updateMutation.isLoading

  const isLoading = devicesQuery.isLoading || mappingDescQuery.isLoading ||
  createMutation.isLoading || deleteMutation.isLoading ||
  updateMutation.isLoading

  return {
    add,
    addSubmit,
    canEdit,
    clear: configurator.clear,
    edit,
    editSubmit,
    formSection,
    isLoading,
    isReady,
    mode: configurator.mode
  }
}
