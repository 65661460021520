import React, { FunctionComponent } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import hero from './../images/home_hero.jpg'
import viewer from './../images/factory_viewer_card.jpg'
import manager from './../images/factory_manager_card.jpg'
import Contents from '../components/Contents'
import FactoryManager from './FactoryManager/FactoryManager'
import FactoryViewer from './FactoryViewer/FactoryViewer'
import Me from './Me'
import { Routes as AppRoutes } from '../App'

export enum Routes {
  Home = '/',
  FactoryManager = '/factory-manager',
  FactoryViewer = '/factory-viewer',
  Me = 'Me'
}

const HomeContent = () => {
  const intl = useIntl()

  const items = [{
    image: manager,
    route: Routes.FactoryManager,
    text: intl.formatMessage({
      id: 'home.factoryManagerDescription',
      description: 'Home page Factory Manager description',
      defaultMessage: 'Configure factory nodes, devices and alerts.'
    }),
    title: intl.formatMessage({
      id: 'home.factoryManager',
      description: 'Home page Factory Manager item',
      defaultMessage: 'Factory Manager'
    })
  }, {
    image: viewer,
    route: Routes.FactoryViewer,
    text: intl.formatMessage({
      id: 'home.factoryViewerDescription',
      description: 'Home page Factory Viewer description',
      defaultMessage: 'Monitor devices and analyse factory data.'
    }),
    title: intl.formatMessage({
      id: 'home.factoryViewer',
      description: 'Home page Factory Viewer item',
      defaultMessage: 'Factory Viewer'
    })
  }]

  return (
    <Box paddingTop={2}>
      <Contents
        hero={hero}
        items={items}
        title={intl.formatMessage({
          id: 'home.welcome',
          description: 'Home page welcome message',
          defaultMessage: 'Welcome'
        })}
      />
    </Box>
  )
}

const Home: FunctionComponent = () => {
  return (
    <Switch>
      <Route path={Routes.FactoryManager}>
        <FactoryManager />
      </Route>
      <Route path={Routes.FactoryViewer}>
        <FactoryViewer />
      </Route>
      { process.env.REACT_APP_DIAGNOSTICS === 'true' &&
        <Route exact path={'/Me'}>
          <Me />
        </Route>
      }
      <Route exact path={Routes.Home}>
        <HomeContent />
      </Route>
      <Redirect to={AppRoutes.PageNotFound}/>
    </Switch>
  )
}

export default Home
