import { useState } from 'react'
import FunctionApp, { Property } from '../api/FunctionApp'

export enum ConfigurationMode {
  Create,
  Edit
}

interface Configurator {
  clear: () => void,
  create: (properties: Property[], initial?: Record<string, unknown>) => void,
  readonly data?: Record<string, unknown>,
  edit: (initial: Record<string, unknown>) => void,
  mode?: ConfigurationMode,
  update: (updates: Record<string, unknown>) => void
}

export default function useConfigure (): Configurator {
  const [data, setData] = useState<Record<string, unknown>>()
  const [mode, setMode] = useState<ConfigurationMode>()

  const clear = () => {
    setData(undefined)
    setMode(undefined)
  }

  const create = (properties: Property[], initial?: Record<string, unknown>) => {
    setData(FunctionApp.createObjectFromProps(properties, initial))
    setMode(ConfigurationMode.Create)
  }

  const edit = (initial: Record<string, unknown>) => {
    setData(initial)
    setMode(ConfigurationMode.Edit)
  }

  const update = (updates: Record<string, unknown>) => {
    setData({ ...data, ...updates })
  }

  return {
    clear,
    create,
    data,
    edit,
    mode,
    update
  }
}
