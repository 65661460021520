import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMsal } from '@azure/msal-react'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'
import balloon from './../images/balloon_w.png'
import background from './../images/landing_background.jpg'

const useStyles = makeStyles((theme) => ({
  background: {
    display: 'flex',
    flex: 'auto',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  overlay: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  logo: {
    marginBottom: theme.spacing(2)
  },
  title: {
    color: 'white',
    fontSize: '3.75rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem'
    }
  },
  button: {
    marginTop: theme.spacing(6)
  }
}))

const Landing: FunctionComponent = () => {
  const classes = useStyles()
  const { instance } = useMsal()

  return (
    <div className={classes.background}>
      <div className={classes.overlay}>
        <Box m="auto">
          <Paper className={classes.paper}>
            <Box
              display="flex"
              className={classes.logo}
            >
              <img src={balloon} alt="balloon"/>
            </Box>
            <Typography
              variant="h2"
              paragraph
              className={classes.title}
            >
              <FormattedMessage
                id="landing.ricohConnectedFactory"
                description="Landing page app title, Ricoh Connected Factory"
                defaultMessage="Ricoh Connected Factory"
              />
            </Typography>
            <Grid
              container
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => instance.loginRedirect({
                  prompt: 'select_account',
                  scopes: []
                })}
                className={classes.button}
              >
                <FormattedMessage
                  id="landing.signIn"
                  description="Landing page sign in button text"
                  defaultMessage="Sign In"
                />
              </Button>
            </Grid>
          </Paper>
        </Box>
      </div>
    </div>
  )
}

export default Landing
