import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'
import Balloon from './../images/balloon_w.png'

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  logo: {
    marginBottom: theme.spacing(3)
  },
  media: {
    height: 180
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}))

interface ItemProps {
  image: string,
  route: string,
  text: string,
  title: string
}

function Item (props: ItemProps) {
  const {
    image,
    route,
    text,
    title
  } = props
  const classes = useStyles()

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Link
        to={route}
        className={classes.link}
      >
        <Box clone height="100%">
          <Card>
            <CardActionArea>
              <CardMedia
                image={image}
                className={classes.media}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {text}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Link>
    </Grid>
  )
}

interface ContentsProps {
  hero: string,
  items: ItemProps[],
  title: string
}

const Contents: FunctionComponent<ContentsProps> = (props: ContentsProps) => {
  const {
    hero,
    items,
    title
  } = props
  const classes = useStyles()

  return (
    <Box
      paddingTop={2}
      paddingBottom={3}
    >
      <Container>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <Paper
              className={classes.mainFeaturedPost}
              style={{ backgroundImage: `url(${hero})` }}>
              <div className={classes.overlay}>
                <Grid item>
                  <Box padding={6}>
                    <Box className={classes.logo}>
                      <img src={Balloon} alt="balloon"/>
                    </Box>
                    <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                      {title}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                      <FormattedMessage
                        id="contents.ricohConnectedFactory"
                        description="Contents Ricoh Connected Factory subtitle"
                        defaultMessage="Ricoh Connected Factory"
                      />
                    </Typography>
                  </Box>
                </Grid>
              </div>
            </Paper>
          </Grid>
          {items.map((item, index) => (
            <Item
              image={item.image}
              key={index}
              route={item.route}
              text={item.text}
              title={item.title}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Contents
