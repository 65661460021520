import React, { FunctionComponent } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

const PageLoading: FunctionComponent = () => {
  return (
    <Backdrop open={true} data-testid="page-loading">
      <CircularProgress/>
    </Backdrop>
  )
}

export default PageLoading
