import React, { FunctionComponent, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Tabs, useTheme } from '@material-ui/core'
import EventInstances from './EventInstances'
import EventVariables from './EventVariables'
import LinkTab from '../../../../components/Tabs/LinkTab'
import TabPanel from '../../../../components/Tabs/TabPanel'
import UpdateMappedIotTags from '../UpdateMappedIotTags'

export enum Routes {
  Events = '/events',
  EventVariables = '/event-variables'
}

enum TabsEnum {
  Events,
  Variables
}

const EventsCfg: FunctionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState<number>(TabsEnum.Events)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Box position="relative" paddingTop={1}>
        <Tabs
          onChange={handleTabChange}
          value={tabValue}
        >
          <LinkTab
            label={intl.formatMessage({
              id: 'events.events',
              description: 'Events configuration page, events tab label',
              defaultMessage: 'Events'
            })}
            to={`.${Routes.Events}`}
          />
          <LinkTab
            label={intl.formatMessage({
              id: 'events.variables',
              description: 'Events configuration page, variables tab label',
              defaultMessage: 'Variables'
            })}
            to={`.${Routes.EventVariables}`}
          />
        </Tabs>
        <TabPanel value={tabValue} index={TabsEnum.Events}>
          <EventInstances/>
        </TabPanel>
        <TabPanel value={tabValue} index={TabsEnum.Variables}>
          <EventVariables/>
        </TabPanel>
        <Box
          position="absolute"
          top={theme.spacing(0)}
          right={theme.spacing(4)}
        >
          <UpdateMappedIotTags/>
        </Box>
      </Box>
    </>
  )
}

export default EventsCfg
