import React, { FunctionComponent, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Switch, Route, useHistory, useParams, useRouteMatch } from 'react-router'
import { defineMessages, useIntl } from 'react-intl'
import {
  clone,
  cloneDeep,
  differenceBy,
  differenceWith,
  find,
  findIndex,
  includes,
  isEqual,
  isNil
} from 'lodash'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import AlertDialogue from '../../../components/AlertDialogue'
import ContentLoading from '../../../components/Loading/ContentLoading'
import DataForm from '../../../components/DataForm'
import EnhancedTable from '../../../components/Table/EnhancedTable'
import FilterDialogue from '../../../components/FilterDialogue'
import Hierarchy from '../../../components/Hierarchy/Hierarchy'
import PageLoading from '../../../components/Loading/PageLoading'
import Placeholder from '../../../components/Placeholder'
import UnsavedDialogue from '../../../components/UnsavedDialogue'
import useConfigure, { ConfigurationMode } from '../../../hooks/useConfigure'
import useFilter from '../../../hooks/useFilter'
import usePagination from '../../../hooks/usePagination'
import useSort from '../../../hooks/useSort'
import FunctionApp, {
  GetListProps,
  List,
  NodeKeys,
  NodePropertyKeys,
  NodeTemplateKeys,
  NodeTemplatePropertyMappingKeys,
  Operator,
  Order,
  Paths,
  Property,
  PropertyLookup,
  Schema,
  TemplatePropertyGroupKeys
} from '../../../api/FunctionApp'
import { Routes as HomeRoutes } from '../../Home'
import { Routes as FactoryManagerRoutes } from '../FactoryManager'
import { Routes as FactoryConfigurationRoutes } from './FactoryConfiguration'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  sticky: {
    position: 'sticky',
    top: theme.spacing(3)
  }
}))

const filterPropertyEditCreations = (edits: Record<string, unknown>[])
: Record<string, unknown>[] => {
  return edits.filter(edit =>
    edit[NodePropertyKeys.Id] === null &&
    edit[NodePropertyKeys.NodePropertyValue] !== ''
  )
}

const filterPropertyEditDeletions = (edits: Record<string, unknown>[])
: Record<string, unknown>[] => {
  return edits.filter(edit =>
    edit[NodePropertyKeys.Id] !== null &&
    edit[NodePropertyKeys.NodePropertyValue] === ''
  )
}

const filterPropertyEditUpdates = (edits: Record<string, unknown>[])
: Record<string, unknown>[] => {
  return edits.filter(edit =>
    edit[NodePropertyKeys.Id] !== null &&
    edit[NodePropertyKeys.NodePropertyValue] !== ''
  )
}

const getEditCreations = (edits: Record<string, unknown>[])
: Record<string, unknown>[] => {
  const creations: Record<string, unknown>[] = []
  const filtered = filterPropertyEditCreations(edits)
  for (const create of filtered) {
    creations.push({
      [NodePropertyKeys.NodeId]: create[NodePropertyKeys.NodeId],
      [NodePropertyKeys.NodePropertyValue]: create[NodePropertyKeys.NodePropertyValue],
      [NodePropertyKeys.NodeTempPropId]: create[NodePropertyKeys.NodeTempPropId]
    })
  }
  return creations
}

const getEditDeletions = (edits: Record<string, unknown>[]): number[] => {
  const deletions: number[] = []
  const filtered = filterPropertyEditDeletions(edits)
  for (const deletion of filtered) {
    deletions.push(Number(deletion[NodePropertyKeys.Id]))
  }
  return deletions
}

const getEditUpdates = (edits: Record<string, unknown>[])
: Record<string, unknown>[] => {
  const updates: Record<string, unknown>[] = []
  const filtered = filterPropertyEditUpdates(edits)
  for (const update of filtered) {
    updates.push({
      [NodePropertyKeys.Id]: update[NodePropertyKeys.Id],
      [NodePropertyKeys.NodeId]: update[NodePropertyKeys.NodeId],
      [NodePropertyKeys.NodePropertyValue]: update[NodePropertyKeys.NodePropertyValue],
      [NodePropertyKeys.NodeTempPropId]: update[NodePropertyKeys.NodeTempPropId]
    })
  }
  return updates
}

const messages = defineMessages({
  alertMessage: {
    id: 'nodes.alertMessage',
    description: 'Delete alert dialogue message content',
    defaultMessage: 'Are you sure you want to delete the selected ' +
      '{count, plural, one{Node} other{Nodes}}?'
  },
  alertTitle: {
    id: 'nodes.alertTitle',
    description: 'Delete alert dialogue title',
    defaultMessage: 'Delete {count, plural, one{Node} other{Nodes}}'
  }
})

const omitEditProperties = [
  NodeKeys.Id,
  NodeKeys.OrdinalPosition,
  NodeKeys.ParentId
]

const omitFilterProperties = [
  NodePropertyKeys.GroupName,
  NodePropertyKeys.GroupOrdinal,
  NodePropertyKeys.Id,
  NodePropertyKeys.NodeId,
  NodePropertyKeys.NodeTempPropId
]

interface Params {
  nodeId: string | undefined
}

const Node: FunctionComponent = () => {
  const classes = useStyles()
  const intl = useIntl()
  const configurator = useConfigure()
  const filterer = useFilter()
  const pagination = usePagination()
  const sort1 = useSort(NodePropertyKeys.GroupName)
  const sort2 = useSort(NodePropertyKeys.GroupOrdinal)
  const { nodeId } = useParams<Params>()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [nodeEdits, setNodeEdits] = useState<Record<string, unknown>[]>([])
  const [nodes, setNodes] = useState<Record<string, unknown>[] | null>(null)
  const [properties, setProperties] = useState<Record<string, unknown>[] | null>(null)
  const [propertyEdits, setPropertyEdits] = useState<Record<string, unknown>[]>([])
  const [selected, setSelected] = useState<number[]>([])
  const [templateId, setTemplateId] = useState<number>()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const groupsQuery = useQuery(
    Paths.TemplatePropertyGroups,
    () => FunctionApp.getList({
      path: Paths.TemplatePropertyGroups
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedGroups',
          description: 'Fetch node template property groups error message',
          defaultMessage: 'Failed to get Node Template Property Groups!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const mappingQuery = useQuery(
    [Paths.NodeTemplatePropertyMapping, templateId],
    () => FunctionApp.getList({
      modelExpressions: [{
        Prop: NodeTemplatePropertyMappingKeys.TemplateId,
        Op: Operator.Equal,
        Val: templateId
      }],
      path: Paths.NodeTemplatePropertyMapping
    }), {
      enabled: !!templateId,
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedMappings',
          description: 'Fetch node template property mappings error message',
          defaultMessage: 'Failed to get Node Template Property Mappings!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesDescQuery = useQuery(
    Paths.Nodes + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedNodeSchema',
          description: 'Fetch node schema error message',
          defaultMessage: 'Failed to get Node Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const nodesQuery = useQuery(
    Paths.Nodes,
    () => FunctionApp.getList({
      path: Paths.Nodes
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedNodes',
          description: 'Fetch nodes error message',
          defaultMessage: 'Failed to get Nodes!'
        }), {
          variant: 'error'
        }
      ),
      onSuccess: (data: List) => {
        const newNodes = cloneDeep(data.Items)
        const newNodeEdits: Record<string, unknown>[] = []
        nodeEdits.forEach(edit => {
          const index = findIndex(newNodes, [NodeKeys.Id, edit[NodeKeys.Id]])
          if (index >= 0) {
            if (!isEqual(newNodes[index], edit)) {
              newNodes[index] = edit
              newNodeEdits.push(edit)
            }
          }
        })
        setNodes(newNodes)
        setNodeEdits(newNodeEdits)
      }
    }
  )
  const nodesCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.Nodes
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedNodeCreate',
            description: 'Create node error message',
            defaultMessage: 'Failed to create Node!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulNodeCreate',
            description: 'Create node success message',
            defaultMessage: 'Successfully created Node!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        queryClient.invalidateQueries(Paths.Nodes)
      }
    }
  )
  const nodesDeleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.Nodes
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedNodeDelete',
            description: 'Delete nodes error message',
            defaultMessage: 'Failed to delete Nodes!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulNodesDelete',
            description: 'Delete nodes success message',
            defaultMessage: 'Successfully deleted Nodes!'
          }), {
            variant: 'success'
          }
        )
        setSelected([])
        queryClient.invalidateQueries(Paths.Nodes)
      }
    }
  )
  const nodesUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.Nodes
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedNodeUpdate',
            description: 'Update node error message',
            defaultMessage: 'Failed to update Nodes!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulNodeUpdate',
            description: 'Update node success message',
            defaultMessage: 'Successfully updated Nodes!'
          }), {
            variant: 'success'
          }
        )
        configurator.clear()
        setNodeEdits([])
        queryClient.invalidateQueries([Paths.Nodes])
        queryClient.resetQueries([Paths.NodeProperties])
      }
    }
  )
  const propertiesDescQuery = useQuery(
    Paths.NodeProperties + Paths.UtilsGetDesc,
    () => FunctionApp.getDesc({
      path: Paths.NodeProperties
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedPropertySchema',
          description: 'Fetch node property schema error message',
          defaultMessage: 'Failed to get Node Property Schema!'
        }), {
          variant: 'error'
        }
      )
    }
  )
  const propertiesQuery = useQuery([
    Paths.NodeProperties,
    filterer.active,
    nodeId,
    sort1.order,
    sort1.orderBy,
    sort2.order,
    sort2.orderBy,
    pagination.page,
    pagination.rowsPerPage
  ],
  () => {
    const props: GetListProps = {
      modelExpressions: [
        ...filterer.active, {
          Prop: NodePropertyKeys.NodeId,
          Op: Operator.Equal,
          Val: nodeId
        }
      ],
      order1: sort1.order,
      orderBy1: sort1.orderBy,
      pageNumber: pagination.page,
      pageSize: pagination.rowsPerPage,
      path: Paths.NodeProperties
    }
    if (sort2.orderBy) {
      props.order2 = sort2.order
      props.orderBy2 = sort2.orderBy
    }
    return FunctionApp.getList(props)
  }, {
    enabled: !!nodeId,
    onError: () => enqueueSnackbar(
      intl.formatMessage({
        id: 'nodes.failedProperties',
        description: 'Fetch node properties error message',
        defaultMessage: 'Failed to get Node Properties!'
      }), {
        variant: 'error'
      }
    ),
    onSuccess: (data: List) => {
      const newProperties = cloneDeep(data.Items)
      const newPropertyEdits: Record<string, unknown>[] = []
      propertyEdits.forEach(edit => {
        const index = findIndex(newProperties, {
          [NodePropertyKeys.NodeId]: edit[NodePropertyKeys.NodeId],
          [NodePropertyKeys.NodeTempPropId]: edit[NodePropertyKeys.NodeTempPropId]
        })
        if (index >= 0) {
          if (!isEqual(newPropertyEdits[index], edit)) {
            newProperties[index] = edit
            newPropertyEdits.push(edit)
          }
        }
      })
      setProperties(newProperties)
      setPropertyEdits(newPropertyEdits)
    }
  })
  const propertiesCreateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.create({
      items: items,
      path: Paths.NodeProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedPropertyCreate',
            description: 'Create node properties error message',
            defaultMessage: 'Failed to create Node Properties!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulPropertyCreate',
            description: 'Create node properties success message',
            defaultMessage: 'Successfully created Node Properties!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeProperties)
      }
    }
  )
  const propertiesDeleteMutation = useMutation(
    (ids: number[]) => FunctionApp.delete({
      ids: ids,
      path: Paths.NodeProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedPropertyDelete',
            description: 'Delete node properties error message',
            defaultMessage: 'Failed to delete Node Properties!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulPropertyDelete',
            description: 'Delete node properties success message',
            defaultMessage: 'Successfully deleted Node Properties!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeProperties)
      }
    }
  )
  const propertiesUpdateMutation = useMutation(
    (items: Record<string, unknown>[]) => FunctionApp.update({
      items: items,
      path: Paths.NodeProperties
    }), {
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.failedPropertyUpdate',
            description: 'Update node properties error message',
            defaultMessage: 'Failed to update Node Properties!'
          }), {
            variant: 'error'
          }
        )
      },
      onSuccess: () => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'nodes.successfulPropertyUpdate',
            description: 'Update node properties success message',
            defaultMessage: 'Successfully updated Node Properties!'
          }), {
            variant: 'success'
          }
        )
        queryClient.invalidateQueries(Paths.NodeProperties)
      }
    }
  )
  const templatesQuery = useQuery(
    Paths.NodeTemplates,
    () => FunctionApp.getList({
      path: Paths.NodeTemplates
    }), {
      onError: () => enqueueSnackbar(
        intl.formatMessage({
          id: 'nodes.failedTemplates',
          description: 'Fetch node templates error message',
          defaultMessage: 'Failed to get Node Templates!'
        }), {
          variant: 'error'
        }
      )
    }
  )

  const nodeLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!templatesQuery.data?.Items || !nodesQuery.data?.Items) {
      return null
    }
    return [{
      data: templatesQuery.data.Items,
      localProperty: NodeKeys.NodeTemplateId,
      nameProperty: NodeTemplateKeys.Name,
      remoteProperty: NodeTemplateKeys.Id
    }]
  }, [nodesQuery.data?.Items, templatesQuery.data?.Items])

  const nodeTempPropProperties: Property[] | null = useMemo(() => {
    if (!mappingQuery.data?.Items) {
      return null
    }
    const tempProperties = []
    for (const property of mappingQuery.data.Items) {
      tempProperties.push({
        JsPropertyType: 'string',
        PropertyName: String(property[NodeTemplatePropertyMappingKeys.NodePropertyName])
      })
    }
    return tempProperties
  }, [mappingQuery.data?.Items])

  const propertyLookupProperties: PropertyLookup[] | null = useMemo(() => {
    if (!groupsQuery.data?.Items) {
      return null
    }
    return [{
      data: [...groupsQuery.data.Items, {
        [TemplatePropertyGroupKeys.Id]: null,
        [TemplatePropertyGroupKeys.Name]: '-'
      }],
      localProperty: NodePropertyKeys.TemplatePropertyGroupId,
      nameProperty: TemplatePropertyGroupKeys.Name,
      remoteProperty: TemplatePropertyGroupKeys.Id
    }]
  }, [groupsQuery.data?.Items])

  const schemaCrud: Schema | undefined = useMemo(() => {
    if (!nodeTempPropProperties) {
      return nodesDescQuery.data?.CrudDescription
    }
    if (nodesDescQuery.data?.CrudDescription) {
      return {
        ClassName: nodesDescQuery.data.CrudDescription.ClassName,
        Properties: [
          ...nodesDescQuery.data.CrudDescription.Properties,
          ...nodeTempPropProperties
        ]
      }
    }
  }, [nodeTempPropProperties, nodesDescQuery.data?.CrudDescription])

  const activeNode = useMemo(() => {
    if (!nodesQuery.data?.Items || selected.length !== 1) {
      return null
    }
    return nodesQuery.data.Items.find(
      node => node[NodeKeys.Id] === selected[0]
    )
  }, [nodesQuery.data?.Items, selected])

  const handleAdd = (initial: Record<string, unknown>) => {
    if (!schemaCrud) {
      throw Error('Cannot handleAdd if schemaCrud is null!')
    }
    configurator.create(schemaCrud.Properties, initial)
    setTemplateId(
      !isNil(configurator.data) && !isNil(configurator.data[NodeKeys.NodeTemplateId])
        ? Number(configurator.data[NodeKeys.NodeTemplateId])
        : undefined
    )
  }

  const updateProperties = async (edits: Record<string, unknown>[]) => {
    const creations = getEditCreations(edits)
    const deletions = getEditDeletions(edits)
    const updates = getEditUpdates(edits)
    let newPropertyEdits = [...propertyEdits]
    if (creations.length > 0) {
      const response = await propertiesCreateMutation.mutateAsync(creations)
      if (response) {
        const differences = differenceWith(
          newPropertyEdits,
          creations,
          (arrVal, othVal) => {
            return (
              arrVal[NodePropertyKeys.NodeId] ===
                othVal[NodePropertyKeys.NodeId] &&
              arrVal[NodePropertyKeys.NodeTempPropId] ===
                othVal[NodePropertyKeys.NodeTempPropId]
            )
          }
        )
        newPropertyEdits = differences
      }
    }
    if (deletions.length > 0) {
      const response = await propertiesDeleteMutation.mutateAsync(deletions)
      if (response) {
        const differences = differenceWith(
          newPropertyEdits,
          deletions,
          (arrVal, othVal) => { return arrVal[NodePropertyKeys.Id] === othVal }
        )
        newPropertyEdits = differences
      }
    }
    if (updates.length > 0) {
      const response = await propertiesUpdateMutation.mutateAsync(updates)
      if (response) {
        const differences = differenceBy(newPropertyEdits, updates, NodePropertyKeys.Id)
        newPropertyEdits = differences
      }
    }
    setPropertyEdits(newPropertyEdits)
  }

  const handleAddSubmit = async () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleAddSubmit if configurator data is nil!')
    }
    if (isNil(nodesDescQuery.data)) {
      throw Error('Cannot handleAddSubmit if node description is nil!')
    }
    const response = await nodesCreateMutation.mutateAsync(
      [FunctionApp.filterProps(
        configurator.data,
        nodesDescQuery.data.CrudDescription.Properties
      )]
    )
    const createdNodeId = Number(response.data[0])
    updateProperties(propertyEdits.map(edit => {
      edit[NodePropertyKeys.NodeId] = createdNodeId
      return edit
    }))
  }

  const handleAlertCancel = () => {
    setAlertOpen(false)
  }

  const handleAlertDelete = () => {
    nodesDeleteMutation.mutate(selected)
    setAlertOpen(false)
  }

  const handleDelete = () => {
    setAlertOpen(true)
  }

  const handleEdit = () => {
    if (isNil(activeNode)) {
      throw Error('Cannot handleEdit if activeNode is nil!')
    }
    if (isNil(propertiesQuery.data)) {
      throw Error('Cannot handleEdit if properties are nil!')
    }
    setTemplateId(activeNode[NodeKeys.NodeTemplateId]
      ? Number(activeNode[NodeKeys.NodeTemplateId])
      : undefined
    )
    const properties: Record<string, unknown> = {}
    for (const property of propertiesQuery.data.Items) {
      properties[String(property[NodePropertyKeys.NodePropertyName])] =
        property[NodePropertyKeys.NodePropertyValue]
    }
    configurator.edit({ ...activeNode, ...properties })
  }

  const handleEditSubmit = () => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleEditSubmit if configurator data is nil!')
    }
    if (isNil(nodesDescQuery.data)) {
      throw Error('Cannot handleEditSubmit if node description is null!')
    }
    nodesUpdateMutation.mutate(
      [FunctionApp.filterProps(
        configurator.data,
        nodesDescQuery.data.CrudDescription.Properties
      )]
    )
    updateProperties(propertyEdits)
  }

  const handleFilterSubmit = () => {
    filterer.submit()
    pagination.setPage(0)
    filterer.clearData()
  }

  const handleFormCancel = () => {
    configurator.clear()
    filterer.clearData()
  }

  const handleNodesDiscard = () => {
    setNodeEdits([])
    queryClient.resetQueries(Paths.Nodes)
  }

  const handleNodesSave = () => {
    nodesUpdateMutation.mutateAsync(nodeEdits)
  }

  const handleNodesUpdate = (items: Record<string, unknown>[]) => {
    if (!nodes) {
      throw Error('Cannot handleNodesUpdate if nodes is null!')
    }
    if (!nodesQuery.data?.Items) {
      throw Error('Cannot handleNodesUpdate if nodesQuery data is null!')
    }
    const newNodes = cloneDeep(nodes)
    const newNodeEdits = [...nodeEdits]
    items.forEach(item => {
      const nodeIndex = findIndex(newNodes, [NodeKeys.Id, item[NodeKeys.Id]])
      if (nodeIndex < 0) {
        throw Error('Updated node id is missing from newNodes!')
      }
      newNodes[nodeIndex] = clone(item)
      let editIndex = findIndex(newNodeEdits, [NodeKeys.Id, item[NodeKeys.Id]])
      if (editIndex < 0) {
        editIndex = newNodeEdits.push(item) - 1
      }
      newNodeEdits[editIndex] = clone(item)
      const original = find(nodesQuery.data.Items, [NodeKeys.Id, item[NodeKeys.Id]])
      if (isEqual(original, newNodeEdits[editIndex])) {
        newNodeEdits.splice(editIndex, 1)
      }
    })
    setNodes(newNodes)
    setNodeEdits(newNodeEdits)
  }

  const handlePropertyChange = (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => {
    if (!propertiesQuery.data?.Items) {
      throw Error('Cannot handlePropertyChange if propertiesQuery data is null!')
    }
    const newPropertyEdits = [...propertyEdits]

    changes.forEach(c => {
      let index = findIndex(newPropertyEdits, {
        [NodePropertyKeys.NodeId]: c.data[NodePropertyKeys.NodeId],
        [NodePropertyKeys.NodeTempPropId]: c.data[NodePropertyKeys.NodeTempPropId]
      })
      if (index < 0) {
        index = newPropertyEdits.push(c.data) - 1
      }
      newPropertyEdits[index][c.property] = c.value
      const backup = find(propertiesQuery.data.Items, {
        [NodePropertyKeys.NodeId]: c.data[NodePropertyKeys.NodeId],
        [NodePropertyKeys.NodeTempPropId]: c.data[NodePropertyKeys.NodeTempPropId]
      })
      if (isEqual(backup, newPropertyEdits[index])) {
        newPropertyEdits.splice(index, 1)
      }
    })
    setPropertyEdits(newPropertyEdits)
  }

  const handlePropertiesDiscard = () => {
    setPropertyEdits([])
    queryClient.resetQueries(Paths.NodeProperties)
  }

  const handlePropertiesSave = () => {
    updateProperties(propertyEdits)
  }

  const handleFormPropertyChange = (
    property: string,
    value: unknown
  ) => {
    if (isNil(configurator.data)) {
      throw Error('Cannot handleFormPropertyChange if configurator data is nil!')
    }
    configurator.update({ [property]: value })
    if (property === NodeKeys.NodeTemplateId) {
      setTemplateId(value ? Number(value) : undefined)
    }
    const tempProp = nodeTempPropProperties?.find(p => p.PropertyName === property)
    if (tempProp) {
      const mapping = mappingQuery.data?.Items.find(
        (mapping: Record<string, unknown>) =>
          mapping[NodeTemplatePropertyMappingKeys.NodePropertyName] === property
      )
      const nodeProp = propertiesQuery.data?.Items.find(
        (property: Record<string, unknown>) =>
          property[NodePropertyKeys.NodePropertyName] === property
      )
      let id
      if (configurator.mode === ConfigurationMode.Edit) {
        id = configurator.data[NodeKeys.Id]
      }
      let edit
      if (nodeProp) {
        edit = {
          [NodePropertyKeys.Id]: nodeProp[NodePropertyKeys.Id],
          [NodePropertyKeys.NodeId]: id,
          [NodePropertyKeys.NodePropertyValue]: value,
          [NodePropertyKeys.NodeTempPropId]: nodeProp[NodePropertyKeys.NodeTempPropId]
        }
      } else if (mapping) {
        edit = {
          [NodePropertyKeys.Id]: null,
          [NodePropertyKeys.NodeId]: id,
          [NodePropertyKeys.NodePropertyValue]: value,
          [NodePropertyKeys.NodeTempPropId]:
            mapping[NodeTemplatePropertyMappingKeys.NodeTempPropId]
        }
      }
      if (edit) {
        handlePropertyChange([{
          data: edit,
          property: NodePropertyKeys.NodePropertyValue,
          value: value
        }])
      }
    }
  }

  const handleRequestSort = (property: string) => {
    if (property === NodePropertyKeys.GroupName) {
      sort1.requestSort(property)
      sort2.requestSort(NodePropertyKeys.GroupOrdinal, Order.asc)
    } else {
      sort1.requestSort(property)
      sort2.requestSort(undefined)
    }
  }

  const handleSelect = (ids: number[]) => {
    queryClient.resetQueries(Paths.NodeProperties)
    if (ids.length === 1) {
      history.push(path.replace('/:nodeId', `/${ids[0]}`))
    } else {
      history.push(path.replace('/:nodeId', '/'))
    }
    setSelected(ids)
  }

  const handleUnsavedDialogCancel = () => {
    setSelected([Number(nodeId)])
  }

  const pageReady = groupsQuery.isSuccess && nodesDescQuery.isSuccess &&
    nodesQuery.isSuccess && propertiesDescQuery.isSuccess &&
    templatesQuery.isSuccess && !nodesCreateMutation.isLoading &&
    !nodesDeleteMutation.isLoading && !nodesUpdateMutation.isLoading

  const pageLoading = groupsQuery.isLoading || nodesDescQuery.isLoading ||
    nodesQuery.isLoading || propertiesDescQuery.isLoading ||
    templatesQuery.isLoading || nodesCreateMutation.isLoading ||
    nodesDeleteMutation.isLoading || nodesUpdateMutation.isLoading

  const propertiesReady = propertiesQuery.isSuccess &&
    !propertiesCreateMutation.isLoading && !propertiesDeleteMutation.isLoading &&
    !propertiesUpdateMutation.isLoading

  const propertiesLoading = propertiesQuery.isLoading ||
    propertiesCreateMutation.isLoading || propertiesDeleteMutation.isLoading ||
    propertiesUpdateMutation.isLoading

  let placeHolderActive = false
  let placeHolderMessage
  if (isNil(activeNode)) {
    placeHolderActive = true
    placeHolderMessage = intl.formatMessage({
      id: 'nodes.noNodeplaceholderMessage',
      description: 'No node selected placeholder text',
      defaultMessage: 'Select a Node to View and Edit its Properties'
    })
  } else if (isNil(activeNode[NodeKeys.NodeTemplateId])) {
    placeHolderActive = true
    placeHolderMessage = intl.formatMessage({
      id: 'nodes.noTemplatePlaceholderMessage',
      description: 'No node template placeholder text',
      defaultMessage: 'Selected Node has not been assigned a Template'
    })
  } else if (propertiesReady && properties?.length === 0) {
    placeHolderActive = true
    placeHolderMessage = intl.formatMessage({
      id: 'nodes.noPropsPlaceholderMessage',
      description: 'No node properties placeholder text',
      defaultMessage: 'Selected Node\'s Template has no Properties'
    })
  }

  return (
    <>
      { pageReady &&
        <>
          { configurator.data &&
            nodeLookupProperties &&
            schemaCrud &&
            <DataForm
              formSections={[{
                data: configurator.data,
                ignoredProperties: omitEditProperties,
                lookupProperties: nodeLookupProperties,
                onPropertyChange: handleFormPropertyChange,
                schema: schemaCrud
              }]}
              onCancel={handleFormCancel}
              onSubmit={configurator.mode === ConfigurationMode.Create
                ? handleAddSubmit
                : handleEditSubmit
              }
              title={configurator.mode === ConfigurationMode.Create
                ? intl.formatMessage({
                  id: 'nodes.createNode',
                  description: 'Create node dialogue title',
                  defaultMessage: 'Create Node'
                })
                : intl.formatMessage({
                  id: 'nodes.editNode',
                  description: 'Edit node dialogue title',
                  defaultMessage: 'Edit Node'
                })
              }
            />
          }
          {
            filterer.data &&
            propertyLookupProperties &&
            propertiesDescQuery.data?.ViewDescription &&
            <FilterDialogue
              filter={filterer.data}
              lookupProperties={propertyLookupProperties}
              onCancel={handleFormCancel}
              onExpressionChange={filterer.update}
              onReset={filterer.reset}
              onSubmit={handleFilterSubmit}
              schema={propertiesDescQuery.data.ViewDescription}
              title={intl.formatMessage({
                id: 'nodes.filterProperties',
                description: 'Filter node properties dialogue title',
                defaultMessage: 'Filter Node Properties'
              })}
            />
          }
          { pageReady &&
            <Container className={classes.container}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={6}>
                  {
                    nodes &&
                    <Box alignItems="flex-start">
                      <Hierarchy
                        activeProperty={NodeKeys.Active}
                        data={nodes}
                        idProperty={NodeKeys.Id}
                        label={intl.formatMessage({
                          id: 'nodes.node',
                          description: 'Node hierarchy, node label',
                          defaultMessage: 'Node'
                        })}
                        multiSelect
                        nameProperty={NodeKeys.Name}
                        onAdd={handleAdd}
                        onDelete={handleDelete}
                        onDiscard={handleNodesDiscard}
                        onEdit={propertiesQuery.data?.Items ? handleEdit : undefined}
                        onSave={handleNodesSave}
                        onSelect={handleSelect}
                        onUpdate={handleNodesUpdate}
                        ordinalProperty={NodeKeys.OrdinalPosition}
                        parentIdProperty={NodeKeys.ParentId}
                        selected={selected}
                        title={intl.formatMessage({
                          id: 'nodes.nodes',
                          description: 'Node hierarchy title',
                          defaultMessage: 'Nodes'
                        })}
                        unsavedChanges={nodeEdits.length > 0}
                      />
                    </Box>
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  { !placeHolderActive &&
                    <Box
                      height="100%"
                      position="sticky"
                      top={3}
                    >
                      { propertiesReady &&
                        properties &&
                        propertiesQuery.data?.Pagination &&
                        propertiesDescQuery.data?.ViewDescription &&
                        <EnhancedTable
                          data={properties}
                          editableProperties={[NodePropertyKeys.NodePropertyValue]}
                          ignoredProperties={[
                            NodePropertyKeys.GroupOrdinal,
                            NodePropertyKeys.Id,
                            NodePropertyKeys.NodeId,
                            NodePropertyKeys.NodeTempPropId,
                            NodePropertyKeys.TemplatePropertyGroupId
                          ]}
                          isFiltered={filterer.isActive}
                          onDiscard={handlePropertiesDiscard}
                          onFilter={() => filterer.initialise(
                            propertiesDescQuery.data.ViewDescription.Properties.filter(
                              p => !includes(omitFilterProperties, p.PropertyName)
                            )
                          )}
                          onPageChange={pagination.setPage}
                          onPropertyChange={handlePropertyChange}
                          onRequestSort={handleRequestSort}
                          onRowsPerPageChange={pagination.setRowsPerPage}
                          onSave={handlePropertiesSave}
                          order={sort1.order}
                          orderBy={sort1.orderBy}
                          page={pagination.page}
                          rowsPerPage={pagination.rowsPerPage}
                          schema={propertiesDescQuery.data.ViewDescription}
                          title={intl.formatMessage({
                            id: 'nodes.nodeProperties',
                            description: 'Node properties table title',
                            defaultMessage: 'Node Properties'
                          })}
                          totalRows={propertiesQuery.data.Pagination.TotalCount}
                          unsavedChanges={propertyEdits.length > 0}
                        />
                      }
                      { propertiesLoading &&
                        <ContentLoading />
                      }
                    </Box>
                  }
                  { placeHolderActive &&
                    <Placeholder message={placeHolderMessage} />
                  }
                </Grid>
              </Grid>
            </Container>
          }
        </>
      }
      {
        pageLoading &&
        <PageLoading />
      }
      <AlertDialogue
        actions={[{
          handler: handleAlertDelete,
          text: intl.formatMessage({
            id: 'nodes.alertDelete',
            description: 'Delete alert dialogue, delete button text',
            defaultMessage: 'Delete'
          })
        }, {
          handler: handleAlertCancel,
          text: intl.formatMessage({
            id: 'nodes.alertCancel',
            description: 'Delete alert dialogue, cancel button text',
            defaultMessage: 'Cancel'
          })
        }]}
        message={intl.formatMessage(messages.alertMessage, { count: selected.length })}
        open={alertOpen}
        title={intl.formatMessage(messages.alertTitle, { count: selected.length })}
      />
      <UnsavedDialogue
        options={[{
          onCancel: handleUnsavedDialogCancel,
          onDiscard: handlePropertiesDiscard,
          onSave: handlePropertiesSave,
          text: intl.formatMessage({
            id: 'nodes.unsavedProperties',
            description: 'Unsaved node properties warning message',
            defaultMessage: 'There are unsaved changes to Node Properties!'
          }),
          unsavedChanges: propertyEdits.length > 0
        }, {
          onDiscard: handleNodesDiscard,
          onSave: handleNodesSave,
          text: intl.formatMessage({
            id: 'nodes.unsavedNodes',
            description: 'Unsaved nodes warning message',
            defaultMessage: 'There are unsaved changes to factory Nodes!'
          }),
          unsavedChanges: nodeEdits.length > 0,
          validPath: HomeRoutes.FactoryManager +
            FactoryManagerRoutes.FactoryConfiguration +
            FactoryConfigurationRoutes.Nodes
        }]}
      />
    </>
  )
}

const Nodes: FunctionComponent = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:nodeId?`}>
        <Node />
      </Route>
    </Switch>
  )
}

export default Nodes
