import React, { FunctionComponent } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Box,
  Breadcrumbs,
  Link,
  LinkProps,
  Typography
} from '@material-ui/core'
import { routeNameToTitle } from '../api/Utils'

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) =>
  <Link {...props} component={RouterLink} />

const RouteCrumbs: FunctionComponent = () => {
  const location = useLocation()

  const pathnames = location.pathname.split('/').filter(x => isNaN(Number(x)))

  return (
    <>
      { pathnames.length > 0 &&
        <Box
          marginLeft={2}
          marginTop={1}
        >
          <Breadcrumbs>
            <LinkRouter color="inherit" to="/">
              Home
            </LinkRouter>
            { pathnames.map((value, index) => {
              const last = index === pathnames.length - 1
              const to = `/${pathnames.slice(0, index + 1).join('/')}`

              return last
                ? (
                  <Typography color="textPrimary" key={index}>
                    {routeNameToTitle(value)}
                  </Typography>
                )
                : (
                  <LinkRouter color="inherit" to={to} key={index}>
                    {routeNameToTitle(value)}
                  </LinkRouter>
                )
            })}
          </Breadcrumbs>
        </Box>
      }
    </>
  )
}

export default RouteCrumbs
