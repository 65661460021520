import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { DragSourceMonitor, useDrop } from 'react-dnd'
import clsx from 'clsx'
import {
  Box,
  Divider,
  lighten,
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TablePagination
} from '@material-ui/core'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Clear,
  Delete,
  Edit,
  FilterList,
  Save
} from '@material-ui/icons'
import FunctionApp, { Order, Schema } from '../../api/FunctionApp'
import EnhancedTableBody from './EnhancedTableBody'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedToolbar from '../EnhancedToolbar'
import { isNil, sortBy } from 'lodash'

const useStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      }
}))

export interface CustomRender {
  propertyName: string,
  renderFunction: (
    row: Record<string, unknown>,
    key: string,
    onPropertyChange?: (changes: {
      data: Record<string, unknown>,
      property: string,
      value: unknown
    }[]) => void
  ) => JSX.Element
}

export interface EnhancedTableProps {
  acceptDropType?: string,
  customRenders?: CustomRender[],
  data: Record<string, unknown>[],
  dense?: boolean,
  editableProperties?: string[],
  idProperty?: string,
  ignoredProperties?: string[],
  isFiltered?: boolean,
  multiSelect?: boolean,
  onAdd?: () => void,
  onPageChange?: (page: number) => void,
  onRowsPerPageChange?: (rows: number) => void,
  onDelete?: () => void,
  onDiscard?: () => void,
  onEdit?: () => void,
  onFilter?: () => void,
  onPropertyChange?: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  onRequestSort?: (property: string) => void,
  onRowDrag?: (
    item: { id: number } | undefined,
    monitor: DragSourceMonitor,
    data: Record<string, unknown>
  ) => void,
  onSave?: () => void,
  onSelect?: (id: (number | string)[]) => void,
  order?: Order,
  orderBy?: string,
  ordinalProperty?: string,
  page?: number,
  rowDragType?: string,
  rowsPerPage?: number,
  rowsPerPageOptions?: Array<number>,
  schema?: Schema,
  selected?: (number | string)[],
  title?: string,
  totalRows?: number,
  unsavedChanges?: boolean
}

const EnhancedTable: FunctionComponent<EnhancedTableProps> = (props: EnhancedTableProps) => {
  const {
    acceptDropType,
    customRenders,
    data,
    dense,
    editableProperties,
    idProperty,
    ignoredProperties,
    isFiltered,
    multiSelect,
    onAdd,
    onDelete,
    onDiscard,
    onEdit,
    onFilter,
    onPageChange,
    onPropertyChange,
    onRequestSort,
    onRowDrag,
    onRowsPerPageChange,
    onSave,
    onSelect,
    order,
    orderBy,
    ordinalProperty,
    page,
    rowDragType,
    rowsPerPage,
    rowsPerPageOptions,
    schema,
    selected,
    title,
    totalRows,
    unsavedChanges
  } = props
  const classes = useStyles()
  const [{ isOverCurrent }, drop] = useDrop({
    accept: acceptDropType || 'undefined',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  })

  useEffect(() => {
    if (isNil(totalRows) || isNil(rowsPerPage) || isNil(page) || isNil(onPageChange)) {
      return
    }
    const pages = Math.ceil(totalRows / rowsPerPage)
    if (page > pages - 1) {
      const newPage = Math.max(0, pages - 1)
      if (page !== newPage) {
        onPageChange(newPage)
      }
    }
  }, [onPageChange, page, rowsPerPage, totalRows])

  const sorted = useMemo(() => {
    if (!ordinalProperty) {
      return data
    }
    return sortBy(data, d => d[ordinalProperty])
  }, [data, ordinalProperty])

  const canMoveDown = useMemo(() => {
    if (isNil(idProperty)) {
      return false
    }
    if (isNil(selected)) {
      return false
    }
    if (isNil(ordinalProperty)) {
      return false
    }
    if (selected.length === 0) {
      return false
    }
    return !selected.some(s => s === sorted[sorted.length - 1][idProperty])
  }, [idProperty, ordinalProperty, selected, sorted])

  const canMoveUp = useMemo(() => {
    if (isNil(idProperty)) {
      return false
    }
    if (isNil(selected)) {
      return false
    }
    if (isNil(ordinalProperty)) {
      return false
    }
    if (selected.length === 0) {
      return false
    }
    return !selected.some(s => s === sorted[0][idProperty])
  }, [idProperty, ordinalProperty, selected, sorted])

  const moveSelectedItems = (delta: number) => {
    if (isNil(selected)) {
      throw Error('Cannot moveSelectedItems if selected is nil!')
    }
    if (isNil(idProperty)) {
      throw Error('Cannot moveSelectedItems if idProperty is nil!')
    }
    if (isNil(ordinalProperty)) {
      throw Error('Cannot moveSelectedItems if ordinalProperty is nil!')
    }
    if (isNil(onPropertyChange)) {
      throw Error('Cannot moveSelectedItems if onPropertyChange is nil!')
    }
    const reordered = [...sorted]
    for (const id of selected) {
      const item = reordered.find(n => n[idProperty] === id)
      if (isNil(item)) {
        throw Error('Item was not found in sorted data!')
      }
      const index = reordered.indexOf(item)
      reordered.splice(index, 1)
      reordered.splice(index + delta, 0, item)
    }
    const changes = []
    for (let i = 0; i < reordered.length; i++) {
      if (reordered[i][ordinalProperty] !== i) {
        changes.push({
          data: reordered[i],
          property: ordinalProperty,
          value: i
        })
      }
    }
    if (changes.length > 0) {
      onPropertyChange(changes)
    }
  }

  const handleMoveDown = () => {
    moveSelectedItems(1)
  }

  const handleMoveUp = () => {
    moveSelectedItems(-1)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value, 10)
    if (onRowsPerPageChange) {
      onRowsPerPageChange(rows)
    }
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onSelect) {
      throw Error('Cannot handleSelectAllClick is onSelect is undefined!')
    }
    if (!multiSelect) {
      throw Error('Cannot handleSelectAllClick if multiSelect is not set true!')
    }
    if (!idProperty) {
      throw Error('Cannot handleSelectAllClick if idProperty is undefined!')
    }
    if (event.target.checked) {
      const newSelecteds = data.map(n => Number(n[idProperty]))
      onSelect(newSelecteds)
    } else {
      onSelect([])
    }
  }

  let keys: string[] = []
  if (schema) {
    keys = FunctionApp.getSchemaKeys(schema)
  } else if (data[0]) {
    keys = Object.keys(data[0])
  }

  const filteredKeys = keys.filter(
    key => {
      if (key === idProperty) {
        return false
      }
      if (ignoredProperties) {
        if (ignoredProperties.includes(key)) {
          return false
        }
      }
      return true
    }
  )

  return (
    <Box
      clone
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Paper
        className={clsx({ [classes.highlight]: isOverCurrent })}
        ref={acceptDropType ? drop : undefined}
      >
        { (title || onAdd || onDiscard || onSave || selected) &&
          <EnhancedToolbar
            buttons={[
              {
                icon: Add,
                onClick: onAdd,
                tooltip: 'Add',
                visible: true
              }, {
                icon: Delete,
                onClick: onDelete,
                tooltip: 'Delete',
                visible: selected && selected.length > 0
              }, {
                icon: Edit,
                onClick: onEdit,
                tooltip: 'Edit',
                visible: selected && selected.length === 1
              }, {
                highlight: isFiltered,
                icon: FilterList,
                onClick: onFilter,
                tooltip: 'Filter',
                visible: true
              }, {
                icon: Clear,
                onClick: onDiscard,
                tooltip: 'Discard',
                visible: unsavedChanges
              }, {
                icon: Save,
                onClick: onSave,
                tooltip: 'Save',
                visible: unsavedChanges
              }, {
                icon: ArrowDownward,
                onClick: handleMoveDown,
                tooltip: 'Move down',
                visible: canMoveDown
              }, {
                icon: ArrowUpward,
                onClick: handleMoveUp,
                tooltip: 'Move up',
                visible: canMoveUp
              }
            ]}
            multiSelect={!!multiSelect}
            selectedLength={selected ? selected.length : undefined}
            title={title}
          />
        }
        <Box flex={1}>
          <TableContainer >
            <Table
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                canSelect={!!onSelect}
                keys={filteredKeys}
                numSelected={selected ? selected.length : 0}
                onRequestSort={onRequestSort}
                onSelectAllClick={multiSelect
                  ? handleSelectAllClick
                  : undefined
                }
                order={order}
                orderBy={orderBy}
                rowCount={onSelect ? data.length : undefined}
              />
              <EnhancedTableBody
                customRenders={customRenders}
                data={sorted}
                editableProperties={editableProperties}
                idProperty={idProperty}
                keys={filteredKeys}
                multiSelect={multiSelect}
                onPropertyChange={onPropertyChange}
                onRowDrag={onRowDrag}
                onSelect={onSelect}
                rowDragType={rowDragType}
                schema={schema}
                selected={selected}
              />
            </Table>
          </TableContainer>
        </Box>
        { onPageChange &&
          rowsPerPage !== undefined &&
          page !== undefined &&
          totalRows !== undefined &&
          <>
            <Divider/>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={Math.min(page, Math.ceil(totalRows / rowsPerPage))}
              onChangePage={(_event, page) => onPageChange(page)}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </>
        }
      </Paper>
    </Box>
  )
}

export default EnhancedTable
