import React, { FunctionComponent, useRef } from 'react'
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { isNil } from 'lodash'
import clsx from 'clsx'
import { isValid, parseISO } from 'date-fns'
import {
  Checkbox,
  IconButton,
  lighten,
  makeStyles,
  Radio,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { CustomRender } from './EnhancedTable'
import { Property, Schema } from '../../api/FunctionApp'

const useStyles = makeStyles((theme) => ({
  expandable: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      }
}))

const renderCellUseValue = (value: unknown) => {
  switch (typeof value) {
    case 'boolean':
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={Boolean(value)}
            disabled
          />
        </TableCell>
      )
    case 'string': {
      const datetime = parseISO(String(value))
      if (isValid(datetime) && datetime.toISOString() === value) {
        return (
          <TableCell>
            <FormattedDate
              dateStyle="short"
              timeStyle="medium"
              value={datetime}
            />
          </TableCell>
        )
      }
      break
    }
    case 'number':
      return (
        <TableCell>
          <FormattedNumber value={Number(value)}/>
        </TableCell>
      )
    default: break
  }
  return (
    <TableCell>
      {!isNil(value) ? String(value) : '-'}
    </TableCell>
  )
}

const renderInputCellUseValue = (
  row: Record<string, unknown>,
  key: string,
  onPropertyChange: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void) => {
  switch (typeof row[key]) {
    case 'boolean':
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={Boolean(row[key])}
            onChange={(event) =>
              onPropertyChange([{
                data: row,
                property: key,
                value: event.target.checked
              }])}
          />
        </TableCell>
      )
    case 'string': {
      const datetime = parseISO(String(row[key]))
      if (isValid(datetime) && datetime.toISOString() === row[key]) {
        return (
          <TableCell>
            <KeyboardDateTimePicker
              ampm={false}
              clearable
              format="Ppp"
              label="Start Time"
              onChange={(date) =>
                onPropertyChange([{
                  data: row,
                  property: key,
                  value: date
                }])}
              showTodayButton
              value={datetime}
            />
          </TableCell>
        )
      }
      break
    }
    default: break
  }
  return (
    <TableCell>
      <TextField
        autoComplete="off"
        onChange={(event) => onPropertyChange([{
          data: row,
          property: key,
          value: event.target.value
        }])}
        value={row[key] || ''}
        variant="standard"
      />
    </TableCell>
  )
}

const renderCellUseProperty = (
  value: unknown,
  property: Property
) => {
  switch (property.JsPropertyType) {
    case 'boolean':
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={Boolean(value)}
            disabled
          />
        </TableCell>
      )
    case 'datetime': {
      const datetime = parseISO(String(value))
      if (isValid(datetime) && datetime.toISOString() === value) {
        return (
          <TableCell>
            <FormattedDate
              dateStyle="short"
              timeStyle="medium"
              value={datetime}
            />
          </TableCell>
        )
      }
      break
    }
    case 'number':
      return (
        <TableCell>
          <FormattedNumber value={Number(value)}/>
        </TableCell>
      )
    default: break
  }
  return (
    <TableCell>
      {!isNil(value) ? String(value) : '-'}
    </TableCell>
  )
}

const renderInputCellUseProperty = (
  row: Record<string, unknown>,
  key: string,
  onPropertyChange: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  property: Property) => {
  switch (property.JsPropertyType) {
    case 'boolean':
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={Boolean(row[key])}
            onChange={(event) =>
              onPropertyChange([{
                data: row,
                property: key,
                value: event.target.checked
              }])}
          />
        </TableCell>
      )
    case 'datetime': {
      const datetime = parseISO(String(row[key]))
      if (isValid(datetime) && datetime.toISOString() === row[key]) {
        return (
          <TableCell>
            <KeyboardDateTimePicker
              ampm={false}
              clearable
              format="Ppp"
              label="Start Time"
              onChange={(date) =>
                onPropertyChange([{
                  data: row,
                  property: key,
                  value: date
                }])}
              showTodayButton
              value={datetime}
            />
          </TableCell>
        )
      }
      break
    }
    default: break
  }
  return (
    <TableCell>
      <TextField
        autoComplete="off"
        onChange={(event) => onPropertyChange([{
          data: row,
          property: key,
          value: event.target.value
        }])}
        value={row[key] || ''}
        variant="standard"
      />
    </TableCell>
  )
}

const renderCell = (value: unknown, property?: Property) => {
  if (property) {
    return renderCellUseProperty(value, property)
  } else {
    return renderCellUseValue(value)
  }
}

const renderInputCell = (
  row: Record<string, unknown>,
  key: string,
  onPropertyChange: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  property?: Property) => {
  if (property) {
    return renderInputCellUseProperty(row, key, onPropertyChange, property)
  } else {
    return renderInputCellUseValue(row, key, onPropertyChange)
  }
}

interface Props {
  customRenders?: CustomRender[],
  data: Record<string, unknown>,
  dragType?: string,
  dropType?: string,
  editableProperties?: string[],
  index?: number,
  idProperty?: string,
  isSelected?: boolean,
  keys: string[],
  multiSelect?: boolean,
  onDrag?: (
    item: { id: number } | undefined,
    monitor: DragSourceMonitor,
    data: Record<string, unknown>
  ) => void,
  onPropertyChange?: (changes: {
    data: Record<string, unknown>,
    property: string,
    value: unknown
  }[]) => void,
  onSelectClick?: (id: number | string) => void,
  onToggleOpen?: (id: number | string | null) => void,
  open?: boolean,
  schema?: Schema
}

const EnhancedTableRow: FunctionComponent<Props> = (props: Props) => {
  const {
    customRenders,
    data,
    dragType,
    dropType,
    editableProperties,
    index,
    idProperty,
    isSelected,
    keys,
    multiSelect,
    onDrag,
    onPropertyChange,
    onSelectClick,
    onToggleOpen,
    open,
    schema
  } = props
  const classes = useStyles()
  const ref = useRef<HTMLTableRowElement>(null)
  const [, drag] = useDrag({
    type: dragType || 'row',
    item: { id: idProperty ? Number(data[idProperty]) : -1 },
    end: (item, monitor) => onDrag ? onDrag(item, monitor, data) : undefined
  })
  const [{ isOverCurrent }, drop] = useDrop({
    accept: dropType || 'row',
    drop (_item, monitor) {
      const didDrop = monitor.didDrop()
      if (isNil(idProperty)) {
        return
      }
      if (didDrop) {
        return
      }
      return { id: data[idProperty] }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true })
    })
  })

  const labelId = `enhanced-table-checkbox-${index}`

  const canOpen = onToggleOpen && !isNil(open)

  if (!isNil(dragType)) {
    drag(ref)
  }

  if (!isNil(dropType)) {
    drop(ref)
  }

  return (
    <TableRow
      className={clsx(
        canOpen && classes.expandable,
        isOverCurrent && classes.highlight
      )}
      id={'row' + index}
      hover
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={index}
      ref={ref}
      selected={isSelected}
    >
      {
        onSelectClick && idProperty &&
        <TableCell padding="checkbox">
          { multiSelect && data[idProperty] &&
            <Checkbox
              onChange={() => onSelectClick(data[idProperty] as string | number)}
              checked={isSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          }
          { !multiSelect && data[idProperty] &&
            <Radio
              onChange={() => onSelectClick(data[idProperty] as string | number)}
              checked={isSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          }
        </TableCell>
      }
      {
        canOpen && onToggleOpen && idProperty &&
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            onClick={() => onToggleOpen(data[idProperty] as string | number | null)}
            size="small"
          >
            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
          </IconButton>
        </TableCell>
      }
      {keys.map((key) => {
        const canEdit = editableProperties && editableProperties.some(p => p === key)
        const property = schema && schema.Properties.find(p => p.PropertyName === key)
        const customRender = customRenders?.find(x => x.propertyName === key)
        return (
          <React.Fragment key={key}>
            { canEdit && onPropertyChange && !customRender &&
              renderInputCell(data, key, onPropertyChange, property)
            }
            { !canEdit && !customRender &&
              renderCell(data[key], property)
            }
            { canEdit && onPropertyChange && customRender &&
              customRender.renderFunction(data, key, onPropertyChange)
            }
            { !canEdit && customRender &&
              customRender.renderFunction(data, key)
            }
          </React.Fragment>
        )
      })}
    </TableRow>
  )
}

export default EnhancedTableRow
