import React, { FunctionComponent, useState } from 'react'
import { DragSourceMonitor } from 'react-dnd'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Button,
  Grid
} from '@material-ui/core'
import EnhancedTable from './Table/EnhancedTable'
import { Schema } from '../api/FunctionApp'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5, 0)
    }
  })
)

enum DragTypes {
  Left = 'Left',
  Right = 'Right'
}

interface ListProps {
  data: Record<string, unknown>[],
  idProperty: string,
  ignoredProperties?: string[],
  onChange: (ids: number[]) => void,
  schema: Schema,
  title: string
}

interface Props {
  leftList: ListProps,
  rightList: ListProps
}

const TransferList: FunctionComponent<Props> = (props: Props) => {
  const {
    leftList,
    rightList
  } = props
  const classes = useStyles()
  const [leftSelected, setLeftSelected] = useState<number[]>([])
  const [rightSelected, setRightSelected] = useState<number[]>([])

  const handleAllLeft = () => {
    rightList.onChange(rightList.data.map((value) => Number(value[rightList.idProperty])))
    rightList.onChange([])
  }

  const handleAllRight = () => {
    leftList.onChange(leftList.data.map((value) => Number(value[leftList.idProperty])))
    leftList.onChange([])
  }

  const handleLeftRowDrag = (
    item: { id: number } | undefined,
    monitor: DragSourceMonitor) => {
    const dropResult = monitor.getDropResult()
    if (item && dropResult) {
      rightList.onChange([item.id])
    }
  }

  const handleRightRowDrag = (
    item: { id: number } | undefined,
    monitor: DragSourceMonitor) => {
    const dropResult = monitor.getDropResult()
    if (item && dropResult) {
      leftList.onChange([item.id])
    }
  }

  const handleSelectedRight = () => {
    leftList.onChange(leftSelected)
    setLeftSelected([])
  }

  const handleSelectedLeft = () => {
    rightList.onChange(rightSelected)
    setRightSelected([])
  }

  const handleLeftSelect = (ids: (string | number)[]) => {
    setLeftSelected(ids.map(Number))
  }

  const handleRightSelect = (ids: (string | number)[]) => {
    setRightSelected(ids.map(Number))
  }

  return (
    <Grid
      container
      spacing={2}
      justify="center"
    >
      <Grid item xs>
        <EnhancedTable
          acceptDropType={DragTypes.Right}
          data={leftList.data}
          idProperty={leftList.idProperty}
          ignoredProperties={leftList.ignoredProperties}
          multiSelect
          onRowDrag={handleRightRowDrag}
          onSelect={handleLeftSelect}
          rowDragType={DragTypes.Left}
          schema={leftList.schema}
          selected={leftSelected}
          title={leftList.title}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={leftList.data.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleSelectedRight}
            disabled={leftSelected.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleSelectedLeft}
            disabled={rightSelected.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={rightList.data.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item xs>
        <EnhancedTable
          acceptDropType={DragTypes.Left}
          data={rightList.data}
          idProperty={rightList.idProperty}
          ignoredProperties={rightList.ignoredProperties}
          multiSelect
          onRowDrag={handleLeftRowDrag}
          onSelect={handleRightSelect}
          rowDragType={DragTypes.Right}
          schema={rightList.schema}
          selected={rightSelected}
          title={rightList.title}
        />
      </Grid>
    </Grid>
  )
}

export default TransferList
