import React, { FunctionComponent, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import clsx from 'clsx'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import PageLoading from './components/Loading/PageLoading'
import RouteCrumbs from './components/RouteCrumbs'
import SideMenu from './components/SideMenu'
import Home from './pages/Home'
import Landing from './pages/Landing'
import useWindowSize from './hooks/useWindowSize'
import PageNotFound from './pages/PageNotFound'

const drawerWidth = 280

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  main: {
    display: 'flex',
    flex: 1
  },
  mainContent: {
    flex: 1,
    height: '100%',
    maxWidth: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}))

export enum Routes {
  PageNotFound = '/404'
}

const App: FunctionComponent = () => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { width } = useWindowSize()
  const theme = useTheme()
  const { inProgress } = useMsal()

  useEffect(() => {
    if (width && width < theme.breakpoints.width('md')) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [theme.breakpoints, width])

  const handleMenuClick = () => {
    setDrawerOpen(true)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
      {
        inProgress === InteractionStatus.None &&
        <Switch>
          <Route exact path={Routes.PageNotFound}>
            <PageNotFound/>
          </Route>
          <Route path="*">
            <AuthenticatedTemplate>
              <NavBar
                drawerOpen={drawerOpen}
                drawerWidth={drawerWidth}
                onMenuClick={handleMenuClick}
              />
              <div className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen
              })}
              >
                <Grid
                  className={classes.main}
                  container
                  direction="column"
                >
                  <Grid item>
                    <RouteCrumbs/>
                  </Grid>
                  <Grid item className={classes.mainContent}>
                    <Home/>
                  </Grid>
                </Grid>
              </div>
              <Footer/>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={() => setDrawerOpen(false)}>
                    <ChevronLeft/>
                  </IconButton>
                </div>
                <Divider/>
                <SideMenu/>
              </Drawer>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Landing/>
            </UnauthenticatedTemplate>
          </Route>
        </Switch>
      }
      {
        inProgress !== InteractionStatus.None &&
        <PageLoading/>
      }
    </Box>
  )
}

export default App
